import { useTranslation } from 'react-i18next'
import { ISelectOption, Input, InputStyle, Select } from '../../UI/Inputs'
import { Button, ButtonStyle } from '../../UI/Button'
import resetImage from '../../../assets/LandingPage/Home/reset.svg'
import { ISurvey, Survey } from '../../../models'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Link, LinkStyle } from '../../UI/Link'

interface ISelectSurvey {
  surveys?: Survey[]
  selectedSurvey?: Survey
  setSurvey: (survey: Survey) => void
  goNextStep?: () => void
}

interface IManualInputRespondents {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any
  isDisabled?: boolean
  onReset: () => void
}
const ManualInputRespondents = ({
  register,
  onReset,
  isDisabled = false,
}: IManualInputRespondents) => {
  const { t } = useTranslation('app')

  return (
    <>
      <div className='flex flex-col md:flex-row md:items-center justify-between'>
        <p className='mr-4 font-medium'>
          1. {t('HOW_LONG_WILL_SURVEY_TAKE_PAID')}
        </p>
        <div className='relative w-24 md:w-32'>
          <Input
            inputStyles={InputStyle.INPUT + ' md:text-right pr-12 md:max-w-xs'}
            id='duration'
            placeholder='0'
            typeInput='number'
            min={0}
            max={60}
            required={true}
            {...register('duration', { valueAsNumber: true })}
            disabled={isDisabled}
          />
          <span className='absolute text-xs md:text-base right-2 md:right-3 top-7 md:top-6 border-l border-blue-700 pl-1'>
            {t('MIN')}
          </span>
        </div>
      </div>
      <div className='flex flex-col md:flex-row md:items-center justify-between'>
        <p className='font-medium mt-4'>
          2. {t('SELECT_NUMBER_OF_RESPONDENTS')}
        </p>
        <div className='relative w-24 md:w-32'>
          <Input
            inputStyles={InputStyle.INPUT + ' md:text-right pr-12 md:max-w-xs'}
            id='respondents'
            placeholder='0'
            typeInput='number'
            min={0}
            required={true}
            {...register('respondents', { valueAsNumber: true })}
            disabled={isDisabled}
          />
        </div>
      </div>

      <div className='flex md:flex-row md:items-center justify-end mt-3'>
        <Button onClick={onReset} style='ml-4 text-blue-500 flex items-center'>
          <span>{t('RESET')}</span>
          <img src={resetImage} alt='credits' />
        </Button>

        <Button type='submit' style={ButtonStyle.BLUE}>
          {t('CALCULATE')}
        </Button>
      </div>
    </>
  )
}

const SelectSurvey = ({
  surveys = [],
  selectedSurvey,
  setSurvey,
  goNextStep,
}: ISelectSurvey): JSX.Element => {
  const { handleSubmit, reset: resetInputForm, register } = useForm<ISurvey>()

  const emptySurvey = new Survey({})
  const [surveyFromSelection, setSurveyFromSelection] = useState<Survey>(
    selectedSurvey,
  )
  const shouldShowSurveySelection = surveys.length > 0
  const isValid = survey => survey.getRequiredCredits() > 0

  const updateSurvey = data => {
    const newSurvey = new Survey(data)

    if (isValid(newSurvey)) {
      setSurvey(newSurvey)
      goNextStep()
    }
  }

  const surveyOptions = surveys.map(survey => ({
    label: survey.title,
    value: survey.id,
  }))

  const onSurveySelect = (selected: ISelectOption) => {
    const survey = surveys.find(s => s.id === selected.value) || emptySurvey

    // If respondents of selected survey is less than the second package (50)
    //   set it to 120
    // TODO: refactor magic number
    if (survey.respondents <= 50) {
      survey.respondents = 120
    }

    setSurveyFromSelection(survey)

    if (isValid(survey)) {
      setSurvey(survey)
      goNextStep()
    }
  }

  return (
    <div
      id='calculate-price'
      className='flex items-center justify-center my-10'
    >
      <div className='max-w-screen-lg mx-auto px-8 lg:px-0'>
        <div className='flex flex-col justify-center max-w-xl mx-auto text-lg'>
          <form
            onSubmit={handleSubmit(updateSurvey)}
            onReset={() => setSurvey(emptySurvey)}
          >
            <p className='text-3xl text-center mb-4 ml-3 font-bold'>
              Credits & respondents
            </p>
            <p className='mb-2'>
              On SurveySwap credits are used to get respondents. A credit means
              a SurveySwap respondent spends 1 minute on your survey. If your
              survey takes 5 minutes, it will cost 5 credits.{' '}
              <Link
                href='https://knowledge.surveyswap.io/article/100-how-do-i-get-respondents-on-surveyswap'
                target='_blank'
                asPrimitiveLink={true}
                style={LinkStyle.PRIMARY}
              >
                Read more
              </Link>
            </p>
            {shouldShowSurveySelection && (
              <p>
                Please select your survey below, so we can help you calculate
                how many credits you need.
              </p>
            )}

            {!shouldShowSurveySelection && (
              <p>
                Please fill out the fields below, so we can help you calculate
                how many credits you need.
              </p>
            )}
            {shouldShowSurveySelection ? (
              <Select
                id='select-survey'
                label='Select your survey'
                value={{
                  label: surveyFromSelection.title,
                  value: surveyFromSelection.id,
                }}
                required={true}
                isSearchable={true}
                onChange={onSurveySelect}
                options={surveyOptions}
              />
            ) : (
              <>
                <p className='mt-4'>
                  {/* We couldn&rsquo;t find an active survey on your account,
                  please answer the following information. */}
                </p>
                <ManualInputRespondents
                  register={register}
                  onReset={() => resetInputForm()}
                />
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default SelectSurvey
