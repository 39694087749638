/* eslint-disable react/prop-types */
/**
 * @typedef ITextareaProps
 * @props {string} id - the ID's of textarea element
 * @props {string} [label] - the label text
 * @props {string} [className] - the CSS classes prefix -label and -textarea
 * @props {(e: React.ChangeEvent<HTMLTextareaElement>) => void} onChange - onChange handler
 * @props {string} [placeholder] - the placeholder text for textarea element
 * @props {string} [value] - value for textarea
 * @props {string} typeTextarea - the HTML textarea type
 * @props {string} [labelStyles] - the CSS classes for label
 * @props {string} [textareaStyles] - the CSS classes for textarea
 * @props {boolean} [required] - if field is required
 * @props {number} [max] - max value of number input
 * @props {number} [min] - min value of number input
 */

import { forwardRef } from 'react'

export interface ITextareaProps {
  id: string
  label?: string
  className?: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  value?: string
  labelStyles?: string
  textareaStyles?: string
  pattern?: string
  required?: boolean
  min?: number
  max?: number
  invalid?: boolean
  error?: string
}

export enum TextareaStyle {
  INPUT = 'outline-none text-sm md:text-base font-medium w-full border border-blue-600 text-black rounded-md mt-3 mb-3 placeholder-gray-50 py-5 px-4 hover:border-blue-500 focus:ring-2 focus:ring-blue-500',
  LABEL = 'text-xs font-bold text-black hover:text-blue-500 outline-none',
}

/**
 * Renders the Label with Textarea component.
 *
 * @param {string} id - the ID's of textarea element
 * @param {string} [label] - the label text
 * @param {string} [labelStyles] - the CSS classes for label
 * @param {string} [textareaStyles] - the CSS classes for textarea
 * @param {e => handleChange(e.target.value)} onChange - onChange handler
 * @param {string} [placeholder] - the placeholder text for textarea element
 * @param {string} [value] - value for textarea
 * @param {boolean} [required] - if field is required
 * @param {number} [max] - max value of number input
 * @param {number} [min] - min value of number input
 *
 * @example
 * <Textarea
 *  id='username'
 *  onChange={e => handleChange(e.target.value)}
 *  placeholder='user'
 *  label='username'
 *  value={value}
 *  typeTextarea='text'
 * />
 */

export const Textarea = forwardRef<HTMLTextAreaElement, ITextareaProps>(
  (
    {
      id,
      label = '',
      labelStyles = 'text-black hover:text-blue-500 outline-none',
      textareaStyles = 'outline-none text-sm md:text-base font-medium w-full border text-black rounded-md mt-3 placeholder-gray-50 p-5 px-4 hover:border-blue-500 focus:ring-2 focus:ring-blue-500',
      onBlur,
      onChange,
      placeholder = id,
      value,
      required = false,
      invalid,
      error,
      min,
      max,
    },
    ref,
  ) => {
    const classNames = require('classnames')
    const LabelClass = classNames(labelStyles)
    const TextareaClass = classNames(textareaStyles, 'resize-none', {
      'border-red-500': invalid,
      'border-blue-600': !invalid,
    })
    const ErrorStyle = 'font-normal text-red-500 mt-2'

    return (
      <label htmlFor={id} className={LabelClass}>
        {label && (
          <p className='pt-5'>
            {label}
            {required && <span className='text-blue-500'>*</span>}
          </p>
        )}
        <textarea
          id={id}
          ref={ref}
          name={id}
          rows={5}
          minLength={min}
          maxLength={max}
          onBlur={onBlur}
          onChange={onChange}
          className={TextareaClass}
          placeholder={placeholder}
          value={value}
        />
        {invalid && error && <p className={ErrorStyle}>{error}</p>}
      </label>
    )
  },
)
