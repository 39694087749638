import { useTranslation } from 'react-i18next'

import SelectPlans from './components/SelectPlans'
import { Container } from '../UI/ContainerComponent'
import BuyRespondentsSteps from './components/BuyRespondentsSteps'
import TrustedBy from './components/trustedBy'
import {
  APIError,
  ISurveyWithStats,
  Plan,
  Survey,
  TCurrencies,
} from '../../models'
import { useEffect, useRef, useState } from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import SelectSurvey from './components/SelectSurvey'
import PurchaseOverview from './components/PurchaseOverview'
import { Button, ButtonStyle } from '../UI/Button'
import WhySurveySwap from './components/WhySurveySwap'
import OrdersService from '../../services/OrdersService'
import { Loader } from '../UI/Loader'
import { round } from 'lodash'
import { emptyPlan } from '../../containers/BuyCreditsContainer/BuyCreditsContainer'

export interface IPackage {
  duration: number
  respondents: number
  name: string
}

export interface IBuyCredits {
  mySurveys: null | ISurveyWithStats[]
  selected: Plan | null
  setSelected: (selected: Plan) => void
  currency: TCurrencies
  setCurrency: (currency: TCurrencies) => void
  discountCode: string | null
  setDiscountCode: (discountCode: string) => void
  buyCredits: (plan: Plan) => void
  error?: string | null
}

const BuyCreditsComponent = ({
  mySurveys,
  selected,
  setSelected,
  currency,
  setCurrency,
  setDiscountCode,
  discountCode,
  buyCredits,
  error,
}: IBuyCredits): JSX.Element => {
  const { t } = useTranslation('app')

  const buyRef = useRef(null)
  const scrollToBuy = () => {
    buyRef.current.scrollIntoView()
  }

  const [survey, setSurvey] = useState<Survey>(new Survey({}))
  const [step, setStep] = useState<number>(1) // select survey
  const [plans, setPlans] = useState<Plan[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const goNextStep = () => {
    scrollToBuy()

    setStep(step + 1)
  }

  const goPreviousStep = () => {
    scrollToBuy()

    step > 1 && setStep(step - 1)
  }

  const buildPackages = (): IPackage[] => {
    if (survey.getRequiredCredits() === 0) {
      const emptyPackages = [{}, {}, {}] as IPackage[]

      return emptyPackages
    }

    return [
      {
        name: 'Starter kit',
        duration: survey.duration,
        respondents: Math.round(survey.respondents * 0.1),
      },
      {
        name: 'Accelerator kit',
        duration: survey.duration,
        respondents: Math.round(survey.respondents * 0.5),
      },
      {
        name: 'Complete kit',
        duration: survey.duration,
        respondents: survey.respondents, // This is already 100% of survey.respondents
      },
    ]
  }

  useEffect(() => {
    fetchPlans()
    setSelected(emptyPlan)
  }, [survey.getRequiredCredits()])

  const fetchPlans = async (discountCode?: string) => {
    const requiredCredits = planPackage =>
      planPackage.duration * planPackage.respondents

    const validPackages = buildPackages().filter(planPackage =>
      requiredCredits(planPackage),
    )

    setIsLoading(true)

    const APICalls = validPackages.map(planPackage => {
      return OrdersService.calculatePlan({
        credits: requiredCredits(planPackage),
        currency: currency,
        price: null,
        discountCode, // Pass the discount code, if provided
      })
    })

    const handleResponse = response => {
      if (response instanceof APIError) {
        console.error('ERROR: cannot calculate other plan with API', response)
        return null
      }

      return response
    }

    const responses = await Promise.all(APICalls)
    const filteredPlans = responses.map(handleResponse).filter(plan => plan)

    filteredPlans.forEach((plan: Plan, idx) => {
      const planPackage = validPackages[idx]
      const percentage =
        round(planPackage.respondents / survey.respondents, 1) * 100

      plan.plan_name = planPackage.name
      plan.respondents = planPackage.respondents
      plan.respondentsPercentage = percentage >= 100 ? 100 : percentage

      if (idx === filteredPlans.length - 1) {
        plan.isPopular = true
      }
    })

    setIsLoading(false)
    setPlans(filteredPlans)
  }

  const filteredSurveys = mySurveys?.filter(survey =>
    survey.getRequiredCredits(),
  )

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <SelectSurvey
            goNextStep={goNextStep}
            selectedSurvey={survey}
            surveys={filteredSurveys || []}
            setSurvey={survey => setSurvey(survey)}
          />
        )
      case 2:
        return (
          <SelectPlans
            mySurveys={mySurveys}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            discountCode={discountCode}
            setDiscountCode={val => setDiscountCode(val)}
            buyCredits={() => goNextStep()}
            setSelected={val => setSelected(val)}
            currency={currency}
            setCurrency={setCurrency}
            fetchPlans={fetchPlans} // Pass fetchPlans as a prop
            error={error}
            survey={survey}
            plans={plans}
          />
        )
      case 3:
        return (
          <PurchaseOverview
            currency={currency}
            plan={selected}
            onBuyCredits={buyCredits}
            discountCode={discountCode}
          />
        )
    }
  }

  return (
    <div className='w-full'>
      <div ref={buyRef} className='bg-gray-800'>
        <Container className='bg-white pt-24 pb-8'>
          <h1 className='text-3xl text-center mb-4 ml-3 font-bold'>
            {t('buy_credits.buy_guide.title')}
          </h1>
          <div>
            <BuyRespondentsSteps
              surveys={filteredSurveys}
              currentStep={step}
              setStep={currentStep => setStep(currentStep)}
            />
          </div>
        </Container>

        {isLoading ? (
          <div className='pt-10 sm:h-80 flex items-center justify-center'>
            <Loader />
          </div>
        ) : (
          <div className='py-10'>{renderStep()}</div>
        )}
      </div>

      <div className='shadow bottom-0 left-0 bg-white fixed w-full z-10'>
        <div className='max-w-840px flex items-center justify-center md:justify-between mx-auto h-20 px-5'>
          {/* Do not use disabled props as it stops the scrolling bevavior */}
          <Button
            className='text-sm p-3 sm:px-4 sm:py-2'
            type='button'
            style={step <= 1 ? ButtonStyle.DISABLED : ButtonStyle.NOBORDERS}
            onClick={() => goPreviousStep()}
          >
            <span className='mr-1'>{'<'}</span>
            {t('BACK')}
          </Button>
        </div>
      </div>

      <div className='py-10'>
        <WhySurveySwap />
      </div>

      <div className='bg-gray-800 mx-auto flex flex-col md:flex-row content-center justify-center py-20'>
        <div className='max-w-screen-lg mx-auto px-8 lg:px-0'>
          <div className='mb-8'>
            <p className='text-3xl text-center mb-4 ml-3 font-bold'>
              Buy survey respondents and contribute to our global research
              community
            </p>
            <div className='sm:px-20 md:px-32'>
              <p className='text-center'>
                Whether you are conducting a thesis, a professional academic
                study, or a market research project, we understand the
                challenges of finding survey respondents. Your purchase empowers
                students and researchers worldwide to engage in the open
                exchange of survey data. We believe that knowledge is power and
                should be accessible to all.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='py-20'>
        <TrustedBy></TrustedBy>
      </div>

      <div className='grid justify-items-center w-full mb-24'>
        <BsFillArrowUpCircleFill
          onClick={scrollToBuy}
          size={50}
          color={'blue'}
          style={{ cursor: 'pointer' }}
        ></BsFillArrowUpCircleFill>
      </div>
    </div>
  )
}

export default BuyCreditsComponent
