// TODO: dry up surveyswap-web/src/components/Surveys/components/spanCircle.tsx
interface INotificationYellowIcon {
  width?: string
  height?: string
  className?: string
}

export const NotificationYellowIcon = ({
  width = '14',
  height = '16',
  className = '',
}: INotificationYellowIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M11.5472 0.425781H2.45632C1.19875 0.425781 0.183594 1.43336 0.183594 2.67578V10.9409C0.183594 12.1834 1.19875 13.1985 2.45632 13.1985H3.03208C3.63056 13.1985 4.2139 13.4334 4.63814 13.8576L5.93359 15.1379C6.5245 15.7212 7.47905 15.7212 8.06996 15.1379L9.36541 13.8576C9.78965 13.4334 10.373 13.1985 10.9715 13.1985H11.5472C12.8048 13.1985 13.82 12.1834 13.82 10.9409V2.67578C13.82 1.43336 12.8048 0.425781 11.5472 0.425781ZM7.00178 3.11518C7.81996 3.11518 8.47905 3.78184 8.47905 4.59245C8.47905 5.39548 7.84268 6.03942 7.05481 6.06972C7.0245 6.06972 6.97905 6.06972 6.94117 6.06972C6.14572 6.03942 5.51693 5.39548 5.51693 4.59245C5.5245 3.78184 6.18359 3.11518 7.00178 3.11518ZM9.08511 10.0394C7.94117 10.8046 6.06238 10.8046 4.91844 10.0394C3.91087 9.37275 3.91087 8.26669 4.91844 7.59245C6.06996 6.8273 7.94875 6.8273 9.08511 7.59245C10.0927 8.26669 10.0927 9.36518 9.08511 10.0394Z'
        fill='#FFC012'
      />
    </svg>
  )
}
