import {
  getCountriesOptions,
  getLanguagesOptions,
} from '../../../helpers/languagesHelpers'

import { getEducationOptions } from '../../../config/educations'
import { getOccupationOptions } from '../../../config/occupations'

import i18next from '../../../I18n'
import { Survey } from '../../../models'

export const WELCOME_SURVEY_CODE = 'WELCOME_SURVEY'

const surveyJSOptionAdapter = option => ({
  value: option.value,
  text: option.label,
})
const translation = i18nKey => i18next.t(i18nKey, { ns: 'app' })

const countriesOptions = getCountriesOptions(translation).map(
  surveyJSOptionAdapter,
)
const languagesOptions = getLanguagesOptions(translation).map(
  surveyJSOptionAdapter,
)

const gendersOptions = [
  { value: 'female', text: translation('FEMALE') },
  { value: 'male', text: translation('MALE') },
]
const educationOptions = getEducationOptions(translation).map(
  surveyJSOptionAdapter,
)
const occupationOptions = getOccupationOptions(translation).map(
  surveyJSOptionAdapter,
)

// hard code the code
const surveyswapSurvey = new Survey({
  id: 'my-profile',
  code: WELCOME_SURVEY_CODE,
})
const completedHtml = `
  <h1 style="font-weight: bold; font-size: 1.5em;" class='mb-3'>
    Congratulations, you've finished your first survey!! 🎉
  </h1>
  <p style="font-weight: normal; font-size: 1em;" class='mb-3'>
    To get your credits, go to 
    <a href='${surveyswapSurvey.getSurveyCodeLink()}' class='underline text-blue-500'>
      ${surveyswapSurvey.getSurveyCodeLink()}
    </a>
  </p>
  <p style="font-weight: normal; font-size: 1em;" class='mb-1'>
    Alternatively, you can enter this code manually: WELCOME_SURVEY
  </p>`

export default {
  completedHtml: completedHtml,
  showQuestionNumbers: 'off',
  elements: [
    {
      type: 'html',
      name: 'intro',
      html: `
            <div class="sd-element--with-frame sd-element sd-question sd-row__question sd-row__question--small" style='max-width: 95%; margin: 0 auto; white-space: normal;'>
              <div style='background-color: white; padding: 20px; border-radius: 10px;'>
                <h3 style='font-weight: bold; margin-bottom: 5px'>Your first survey 🎉</h3><br>
                <p>Welcome to SurveySwap, a global community of survey makers and takers. <br> <br> Complete this survey to earn your first 2 credits. 1 credit = 1 minute of other users filling in YOUR survey.
                <br><br>
                Got any questions? Reach out to our customer support team, and we’ll sort you out.
                <br><br>
                Happy swapping!
                </p>
              </div>
            </div>
          `,
    },

    {
      name: 'first_name',
      type: 'text',
      title: 'First name',
      isRequired: false,
    },
    {
      name: 'last_name',
      type: 'text',
      title: 'Last name',
      isRequired: false,
    },
    {
      name: 'languages',
      type: 'tagbox',
      title: 'Languages',
      choices: languagesOptions,
      isRequired: true,
    },
    {
      name: 'country_of_residence',
      type: 'dropdown',
      title: 'Current Country of residence',
      choices: countriesOptions,
      isRequired: true,
    },
    {
      name: 'date_of_birth',
      type: 'text',
      inputType: 'date',
      title: 'Your date of birth',
      isRequired: true,
      placeholder: 'dd/mm/yyyy',
    },
    {
      name: 'gender',
      type: 'dropdown',
      title: 'Gender',
      choices: gendersOptions,
      isRequired: true,
    },
    {
      name: 'occupation',
      type: 'dropdown',
      title: 'Finished education',
      choices: educationOptions,
      isRequired: false,
    },
    {
      name: 'finished_education',
      type: 'dropdown',
      title: 'Occupation',
      choices: occupationOptions,
      isRequired: false,
    },
  ],
}
