import React, { Suspense } from 'react'
import { InputStyle, Input } from './Input'
const DatePicker = React.lazy(() => import('react-datepicker'))
import { useEffect, useState } from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import './Datepicker.css'
import { Loader } from '../Loader'

/**
 * @typedef IDatepickerProps
 * @props {string} id - the ID's of input element
 * @props {string} [label] - the label text
 * @props {string} [className] - the CSS classes prefix -label and -input
 * @props {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange - onChange handler
 * @props {string} [placeholder] - the placeholder text for input element
 * @props {string} value - value for input
 * @props {string} typeInput - the HTML input type
 * @props {string} [labelStyles] - the CSS classes for label
 * @props {string} [inputStyles] - the CSS classes for input
 * @props {boolean} [required] - if field is required
 */

export interface IDatepickerProps {
  id: string
  label?: string
  className?: string
  onChange: (date: Date | null | undefined) => void
  value: Date | null | undefined
  labelStyles?: string
  inputStyles?: string
  required?: boolean
  invalid?: boolean
  error?: string
}

/**
 * Renders the Label with Datepicker component.
 *
 * @param {string} id - the ID's of input element
 * @param {string} [label] - the label text
 * @param {string} [labelStyles] - the CSS classes for label
 * @param {string} [inputStyles] - the CSS classes for input
 * @param {e => handleChange(e.target.value)} onChange - onChange handler
 * @param {string} [placeholder] - the placeholder text for input element
 * @param {string} value - value for input
 * @param {string} typeInput - the HTML input type
 * @param {string} [pattern] - input pattern
 * @param {boolean} [required] - if field is required
 *
 * @example
 * <Datepicker
 *  id='username'
 *  onChange={e => handleChange(e.target.value)}
 *  label='username'
 *  value={value}
 * />
 */
export const Datepicker = ({
  id,
  label = '',
  labelStyles,
  onChange,
  required = false,
  value = null,
  invalid,
  error,
}: IDatepickerProps): JSX.Element => {
  const classNames = require('classnames')
  const LabelClass = classNames(labelStyles, {
    [InputStyle.LABEL]: !labelStyles,
  })
  const errorStyle = 'font-normal text-red-500 mt-2'

  const onDateSelect = (date: Date | [Date, Date] | null) => {
    if (date && !Array.isArray(date)) {
      const dateAtUTC = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
      )

      onChange(dateAtUTC)
    }
  }

  const range = (size: number, startAt = 0) => {
    return [...Array(size).keys()].map(i => i + startAt)
  }

  const years = range(100, new Date().getFullYear() - 100 + 1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <Suspense fallback={<Loader />}>
      <label htmlFor={id} className={LabelClass}>
        <p className='pt-5 mt-3'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <button
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                {'<'}
              </button>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) =>
                  changeYear(parseInt(value))
                }
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                {'>'}
              </button>
            </div>
          )}
          className={invalid ? InputStyle.INVALID_INPUT : InputStyle.INPUT}
          selected={value}
          dateFormat='dd/MM/yyyy'
          onChange={date => onDateSelect(date)}
          placeholderText={`${new Date().toLocaleDateString()}`}
        />
        {invalid && error && <p className={errorStyle}>{error}</p>}
      </label>
    </Suspense>
  )
}

export const Birthdaypicker = ({
  id,
  label = '',
  labelStyles,
  onChange,
  required = false,
  value = null,
  invalid,
  error,
}: IDatepickerProps): JSX.Element => {
  const classNames = require('classnames')
  const LabelClass = classNames(labelStyles, {
    [InputStyle.LABEL]: !labelStyles,
  })
  const errorStyle = 'font-normal text-red-500 mt-2'

  const defaultDay = value ? value.getDate() : null
  const defaultMonth = value ? value.getMonth() + 1 : null
  const defaultYear = value ? value.getFullYear() : null

  const maxYear = new Date().getFullYear() - 14 // 14 years old and beyond
  const minYear = new Date().getFullYear() - 100

  const [day, setDay] = useState<number>()
  const [month, setMonth] = useState<number>()
  const [year, setYear] = useState<number>()
  const [dateInvalid, setDateInvalid] = useState(invalid)
  const [dateError, setDateError] = useState(error)

  useEffect(() => {
    if (isDateInvalid()) {
      setDateInvalid(true)
      setDateError('Please fill in a valid date')
    } else {
      handleDateChange(day, month, year)

      setDateInvalid(false)
      setDateError(null)
    }
  }, [day, month, year])

  // useEffect(() => {
  //   if (value) {
  //     setDay(value.getDate())
  //     setMonth(value.getMonth() + 1)
  //     setYear(value.getFullYear())
  //   }
  // }, [value])

  const handleDateChange = (dd, mm, yyyy) => {
    if (day && month && year && !isDateInvalid()) {
      const date = new Date(Date.UTC(yyyy, mm - 1, dd))

      onChange(date)
    }
  }

  const isDateInvalid = () => {
    return isDayInvalid() || isMonthInvalid() || isYearInvalid()
  }

  const isDayInvalid = () => {
    return day && (day > 31 || day < 1)
  }

  const isMonthInvalid = () => {
    return month && (month > 12 || month < 1)
  }

  const isYearInvalid = () => {
    return year && (year > maxYear || year < minYear)
  }

  const handleDayChange = val => {
    val ? setDay(val) : setDay(null)
  }

  const handleMonthChange = val => {
    val ? setMonth(val) : setMonth(null)
  }

  const handleYearChange = val => {
    val ? setYear(val) : setYear(null)
  }

  return (
    <Suspense fallback={<Loader />}>
      <label htmlFor={id} className={LabelClass}>
        <p className='pt-5 mt-3'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
        {value && (
          <p className='mt-3'>
            {day || defaultDay}-{month || defaultMonth}-{year || defaultYear}
          </p>
        )}
        <div id='birthday-picker' className='flex items-start'>
          <div className='w-1/3'>
            <p className='mt-3 font-light'>Day</p>
            <Input
              id='birthday-picker-day'
              placeholder='DD'
              onChange={e => handleDayChange(e.target.value)}
              value={day?.toString() || ''}
              typeInput='string'
              max={2}
              required={true}
              invalid={isDayInvalid()}
            />
          </div>
          <div className='w-1/3 mx-2'>
            <p className='mt-3 font-light'>Month</p>
            <Input
              id='birthday-picker-month'
              placeholder='MM'
              onChange={e => handleMonthChange(e.target.value)}
              value={month?.toString() || ''}
              typeInput='string'
              max={2}
              required={true}
              invalid={isMonthInvalid()}
            />
          </div>
          <div className='w-1/3'>
            <p className='mt-3 font-light'>Year</p>
            <Input
              id='birthday-picker-year'
              placeholder='YYYY'
              onChange={e => handleYearChange(e.target.value)}
              value={year?.toString() || ''}
              typeInput='string'
              max={4}
              required={true}
              invalid={isYearInvalid()}
            />
          </div>
        </div>
        {dateInvalid && dateError && <p className={errorStyle}>{dateError}</p>}
      </label>
    </Suspense>
  )
}
