import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { Button } from '../Button'
import closeIcon from '../../../assets/Dashboard/close.svg'

/**
 * @typedef IModalProps
 * @props {React.ReactNode} children - the modal's children
 * @props {string} [navComponent] - the component for modal's header
 * @props {string} [footerComponent] - the component for modal's footer
 * @props {() => void} [modalHandler] - onClick open/close modal handler.
 * @props {boolean} [modalOpen] - the modal open/close state
 * @props {boolean} [btnText] - the modal button text
 * @props {boolean} [show] - the modal initial open/close state
 * @props {boolean} [modalOpen] - the modal open/close state
 * @props {string} [className] - the CSS classes.
 * @props {boolean} [footer] - the modal footer show state
 * @props {boolean} [modalWithButton] - the modal with button to open it
 * @props {boolean} [nav] - the modal nav show state
 * @props {number | string} [modalHeight] - the modal height in vh (default: 70)
 * @props {number | string} [modalWidth] - the modal max-width in px (default: 615)
 * @props {boolean} [shouldCloseOnOverlayClick] - close when user clicks outsied the modal.
 * @props {boolean} [shouldCloseOnEsc] - close when user hit ESC key
 */

export interface IModalProps {
  children: React.ReactNode
  navComponent?: React.ReactNode
  nav?: boolean
  footerComponent?: React.ReactNode
  footer?: boolean
  show?: boolean
  className?: string
  btnText?: string
  modalOpen?: boolean
  modalHandler?: () => void
  modalWithButton?: boolean
  modalHeight?: number | string
  modalWidth?: number | string
  childrenOverflow?: boolean
  shouldCloseOnOverlayClick?: boolean
  shouldContainCloseButton?: boolean
  shouldCloseOnEsc?: boolean
}

/**
 * Renders the Modal component.
 *
 * @param {React.ReactNode} children - the modal's children
 * @param {string} [navComponent] - the component for modal's header
 * @param {string} [footerComponent] - the component for modal's footer
 * @param {() => void} [modalHandler] - onClick open/close modal handler.
 * @param {boolean} [modalOpen] - the modal open/close state
 * @param {boolean} [btnText] - the modal button text
 * @param {boolean} [show] - the modal initial open/close state
 * @param {boolean} [modalOpen] - the modal open/close state
 * @param {string} [className] - the CSS classes.
 * @param {boolean} [footer] - the modal footer show state
 * @param {boolean} [modalWithButton] - the modal with button to open it
 * @param {boolean} [nav] - the modal nav show state
 * @param {number | string} [modalWidth] - the modal max-width in px (default: 615)
 * @param {number | string} [modalHeight] - the modal min-height in vh (default: 70)
 * @param {boolean} [childrenOverflow] - set children overflow
 * @param {boolean} [shouldCloseOnOverlayClick] - close when user clicks outsied the modal.
 * @param {boolean} [shouldCloseOnEsc] - close when user hit ESC key
 *
 * @example
 * <Modal
 *  className=''
 * >
 *  Click me
 * </Modal
 */

export const Modal = ({
  children,
  show = false,
  className,
  btnText,
  nav = false,
  navComponent = '',
  footer = false,
  footerComponent,
  modalOpen = false,
  modalHandler,
  modalWithButton = true,
  modalWidth = 615,
  modalHeight = 70,
  childrenOverflow = true,
  shouldCloseOnOverlayClick = true,
  shouldContainCloseButton = true,
  shouldCloseOnEsc = true,
}: IModalProps): JSX.Element => {
  const [modalIsOpen, setIsOpen] = useState(show)
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: window.innerWidth <= 640 ? '0 16px' : '0',
      boxShadow: '0px 4px 18px rgba(153, 156, 180, 0.23)',
      borderRadius: '12px',
      border: 'none',
      maxWidth: window.innerWidth > 640 ? `${modalWidth}px` : '80%',
      width: '100%',
    },
  }

  useEffect(() => {
    setIsOpen(show)
  }, [show])

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className={className}>
      {modalWithButton && !modalHandler && (
        <Button type='button' onClick={() => setIsOpen(!modalIsOpen)}>
          {btnText}
        </Button>
      )}
      {modalWithButton && modalHandler && (
        <Button type='button' onClick={modalHandler}>
          {btnText}
        </Button>
      )}
      <ReactModal
        isOpen={modalWithButton ? modalIsOpen : modalOpen}
        onRequestClose={modalWithButton ? closeModal : modalHandler}
        style={customStyles}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        shouldCloseOnEsc={shouldCloseOnEsc}
      >
        <div style={{ maxHeight: '95vh' }}>
          {nav && (
            <>
              <div className='flex items-center justify-between w-full max-w-full pt-5 px-5'>
                {navComponent && navComponent}
                {shouldContainCloseButton ? (
                  <Button
                    type='button'
                    onClick={modalWithButton ? closeModal : modalHandler}
                    className='p-0'
                    style='text-black'
                  >
                    <img src={closeIcon} alt='close-modal' />
                  </Button>
                ) : (
                  <div></div>
                )}
              </div>
              <hr className='mt-5 border-gray-600' />
            </>
          )}
          {childrenOverflow ? (
            <div
              className='overflow-auto'
              style={{ maxHeight: `${modalHeight}vh` }}
            >
              {children}
            </div>
          ) : (
            <>{children}</>
          )}
          {footer && (
            <div className='shadow flex items-center justify-center w-full max-w-full py-4 border-t border-gray-600 bg-white'>
              {footerComponent && footerComponent}
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  )
}
