/* eslint-disable react/prop-types */
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Card } from '../../UI/Card'
import { Badge } from '../../UI/Badge'
import { Button, ButtonStyle } from '../../UI/Button'
import { Plan, TCurrencies } from '../../../models'
import {
  humanizePrice,
  priceWithCurrency,
} from '../../../helpers/formattingHelpers'

interface CreditPlanCardProps {
  plan: Plan
  currency: TCurrencies
  respondents: number
  className?: string
  suggestionText?: string
  variant: 'normal' | 'highlighted'
  setSelected?: (selected: Plan) => void
  buyCredits: (plan: Plan) => void
}
const CreditPlanCard: React.FC<CreditPlanCardProps> = ({
  currency = 'EUR',
  plan,
  respondents,
  buyCredits,
  variant = 'normal',
  setSelected,
}) => {
  const { t } = useTranslation('app')

  const onSelectPlan = () => {
    setSelected(plan)
    buyCredits(plan)
  }

  return (
    <div className='relative overflow-hidden'>
      {plan.isPopular && (
        <div className='absolute left-0 top-0 h-16 w-16'>
          <div className='absolute text-sm text-center text-white font-semibold transform -rotate-45 bg-green-400 py-1 w-36 leading-4 -left-10 top-4'>
            <div>MOST</div>
            <div>POPULAR</div>
          </div>
        </div>
      )}
      <Card
        className={classNames('rounded-lg p-5 text-center w-80', {
          ['border-2 border-blue-500 bg-blue-gradient text-white']:
            variant == 'highlighted',
          ['border-2 border-blue-500 bg-white text-black']: variant == 'normal',
        })}
        styles=''
      >
        <div className='flex justify-between items-center'>
          <Badge
            className='flex items-center max-w-max py-2 sm:py-2 rounded-md text-sm whitespace-nowrap uppercase'
            style={variant == 'highlighted' ? 'text-white' : 'text-blue-500'}
          >
            <span className={plan.isPopular ? 'ml-10' : ''}>
              {t(plan.plan_name.toUpperCase())}
            </span>
          </Badge>
          {plan.discount_rate > 0 && (
            <Badge
              className='max-w-max rounded-md whitespace-nowrap bg-green-400 border border-green-600 text-white text-xs leading-5'
              style='bg-green-400 px-2 py-0.5'
            >
              Save{' '}
              {priceWithCurrency(
                humanizePrice(plan.getDiscountInCurrency(currency), currency),
                currency,
              )}
            </Badge>
          )}
        </div>

        <div className='text-center py-4 mt-10'>
          <div className='text-xl font-bold leading-10 '>
            {respondents} respondents
          </div>
          <div className='text-sm font-bold text-gray-100'>
            {plan.credits} credits
          </div>

          <div className='relative mt-10'>
            <span
              className={classNames('font-semibold pr-1 text-lg', {
                ['text-white']: variant == 'highlighted',
                ['text-blue-500']: variant !== 'highlighted',
              })}
            >
              {priceWithCurrency(
                humanizePrice(plan.getTotalInCurrency(currency), currency),
                currency,
              )}
            </span>
            {plan.discount_rate > 0 && (
              <s
                className={classNames('text-xs ', {
                  ['text-white text-opacity-70']: variant == 'highlighted',
                  ['text-gray-100']: variant !== 'highlighted',
                })}
              >
                {' '}
                {priceWithCurrency(
                  humanizePrice(plan.getPriceInCurrency(currency), currency),
                  currency,
                )}
              </s>
            )}
          </div>
        </div>

        <div className='flex justify-center items-center shadow w-full bg-blue-300 rounded-lg mb-3 relative text-center leading-5'>
          <span
            className='text-white font-semibold z-10 relative'
            style={{ fontSize: '0.7rem' }}
          >
            {plan.respondentsPercentage}% of your respondent goal
          </span>
          <div
            className={`bg-blue-500 absolute top-0 left-0 z-0 h-full ${
              plan.respondentsPercentage == 100 ? 'rounded-lg' : 'rounded-l-lg'
            }`}
            style={{
              width: `${plan.respondentsPercentage}%`,
            }}
          ></div>
        </div>

        {variant !== 'highlighted' && (
          <Button
            className={classNames(
              ButtonStyle.BLUE,
              'border-blue-500 hover:border-blue-800 hover:bg-blue-800 focus:border-blue-800 focus:bg-blue-800 w-full py-2',
            )}
            onClick={onSelectPlan}
          >
            Buy now
          </Button>
        )}
        {variant == 'highlighted' && (
          <Button
            className={classNames(
              'bg-white text-blue-500 border-white hover:text-white w-full py-2',
            )}
            onClick={onSelectPlan}
          >
            Buy now
          </Button>
        )}
      </Card>
    </div>
  )
}

export default CreditPlanCard
