type SubNavbarProps = {
  bgColor: string
  text: string
  onClose: () => void // Adjust this based on the actual function signature of onClose
}

const SubNavbar = ({ bgColor, text, onClose }: SubNavbarProps): JSX.Element => {
  return (
    <div className={`w-full py-3 text-center relative ${bgColor}`}>
      <p className='text-xs font-bold'>{text}</p>
      <button
        className='absolute right-3 top-1/2 transform -translate-y-1/2'
        onClick={onClose}
      >
        x
      </button>
    </div>
  )
}

export default SubNavbar
