// DiscountCodeService.ts

import { APIError } from '../models'
import APIService from './APIService'

class DiscountCodeService {
  static async validate(
    discountCode: string,
    // eslint-disable-next-line
  ): Promise<any | APIError<unknown>> {
    try {
      const response = await APIService.get(
        `/discount_codes/validate?code=${discountCode}`,
      )
      if (response instanceof APIError) {
        return response
      }
      return response.data // Return the data part of the response directly
    } catch (error) {
      return new APIError<unknown>({ message: 'Network error occurred' })
    }
  }
}

export default DiscountCodeService
