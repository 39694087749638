import { APIError, Notification } from '../models'
import APIService from './APIService'

class NotificationService {
  static async listNotifications(): Promise<
    Notification[] | APIError<unknown> | null
  > {
    const response = await APIService.get('/notifications', '', {})
    if (response instanceof APIError) {
      return response
    }

    return response.data.map(r => new Notification(r.attributes))
  }

  static async markAllRead(): Promise<
    Notification[] | APIError<unknown> | null
  > {
    const response = await APIService.post(
      `/notifications/mark_all_read`,
      '',
      {},
    )

    if (response instanceof APIError) {
      return response
    }

    return response.data.map(r => new Notification(r.attributes))
  }
}

export default NotificationService
