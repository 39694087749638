import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import VariantPages from '../config/variants'

type Variant = {
  key: string
  vt: (translation: string) => string
  inUse: boolean
}

const useVariant = (): Variant => {
  const { t } = useTranslation('variants')
  const location = useLocation()
  const key = `${location.pathname
    .replace('/de', '')
    .replace('/nl', '')
    .replace(/\//g, '')}`

  const vt = (translation: string): string => {
    return t(`${key}.${translation}`)
  }

  // This is horrible but I fought against typescript for far too long.
  const inUse = Object.entries(VariantPages)
    .map(a => a[1].map(b => b.path))
    .flat()
    .includes(location.pathname)

  return {
    inUse: inUse,
    key: key,
    vt: vt,
  }
}

export default useVariant
