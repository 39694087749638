import classNames from 'classnames'

/**
 * @typedef IToogleProps
 * @props {string} id - the ID's of the element
 * @props {string} name - the name of the element
 * @props {string} [leftLabel] - the left label
 * @props {string} [rightLabel] - the right label
 * @props {string} [className] - the CSS classes prefix -label and -textarea
 * @props {string} [leftLabelClassName] - the className of the right label
 * @props {string} [rightLabelClassName] - the className of the right label
 * @props {boolean} value - active or inactive
 * @props {boolean} [disabled] - is disabled
 * @props {(status: boolean) => void} onChange - the on click action
 */
export interface IToggleProps {
  id: string
  name: string
  leftLabel?: string
  rightLabel?: string
  className?: string
  leftLabelClassName?: string
  rightLabelClassName?: string
  onChange: (status: boolean) => void
  value?: boolean
  disabled?: boolean
}

/**
 * Renders the Toogle component.
 *
 * @param {string} id - the ID's of the element
 * @param {string} name - the name of the element
 * @param {string} [leftLabel] - the left label
 * @param {string} [rightLabel] - the right label
 * @param {string} [className] - the CSS classes prefix -label and -textarea
 * @param {string} [leftLabelClassName] - the className of the right label
 * @param {string} [rightLabelClassName] - the className of the right label
 * @param {boolean} value - active or inactive
 * @param {boolean} [disabled] - is disabled
 * @param {(status: boolean) => void} onChange - the on click action
 */
export const Toggle = ({
  id,
  name,
  leftLabel,
  rightLabel,
  className = 'cursor-pointer flex items-center',
  leftLabelClassName = 'mr-3 font-medium whitespace-nowrap',
  rightLabelClassName = 'ml-3 font-medium whitespace-nowrap',
  onChange,
  value,
  disabled,
}: IToggleProps): JSX.Element => {
  const lineStyle = classNames('block w-10 h-6 rounded-full', {
    'opacity-70': disabled,
    'bg-gray-200': !value,
    'bg-green-400': value,
  })
  const dotStyle = classNames(
    'absolute bg-white w-5 h-5 rounded-full transition top-0.5',
    {
      'left-0.5': !value,
      'right-0.5': value,
      'opacity-90': disabled,
    },
  )

  const onClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    /* We need to proxy onChange through this method and use e.preventDefault,
       because click on label calls onClick action twice: for the label and for the inner input */
    e.preventDefault()
    onChange(!value)
  }
  return (
    <label htmlFor={id} className={className} onClick={onClick}>
      {leftLabel && <span className={leftLabelClassName}>{leftLabel}</span>}
      <div className='relative'>
        <input
          name={name}
          id={id}
          type='checkbox'
          checked={value}
          className='sr-only'
          readOnly
        />
        <div className={lineStyle} />
        <div className={dotStyle} />
      </div>
      {rightLabel && <span className={rightLabelClassName}>{rightLabel}</span>}
    </label>
  )
}
