import env from './env'

const prod = {
  GOOGLE_TAG_MANAGER_KEY: 'GTM-54VWJCX',
  GOOGLE_ANALYTICS: 'UA-111029722-1',
  ACTIVE_GOOGLE_TAG_MANAGER: true,
}

const stg = {
  GOOGLE_TAG_MANAGER_KEY: 'GTM-54VWJCX',
  GOOGLE_ANALYTICS: 'UA-111029722-1',
  ACTIVE_GOOGLE_TAG_MANAGER: false,
}

const dev = {
  GOOGLE_TAG_MANAGER_KEY: 'GTM-54VWJCX',
  GOOGLE_ANALYTICS: 'UA-111029722-1',
  ACTIVE_GOOGLE_TAG_MANAGER: false,
}

let config = dev
if (env === 'production') {
  config = prod
} else if (env === 'staging') {
  config = stg
}

export default {
  ...config,
}
