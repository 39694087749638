import { SurveyQuestion, ISurveyQuestion } from './SurveyQuestion'
import SurveyService from '../services/SurveyService'
import { APIError } from './APIError'
import { User } from './User'
import appConfig from '../config/app'
import { ISurveyFlag, SurveyFlag } from './SurveyFlag'
import { Plan } from './Plan'
import { SurveySchema } from './SurveySchema'

export const MAX_DAYS_TO_PAUSE = 50

export type TSurveyFlagReasons =
  | 'offline'
  | 'missing_code'
  | 'wrong_language'
  | 'other'
  | 'took_longer'
  | 'low_quality'
  | ''

export type TSurveyActions =
  | 'publish'
  | 'earn_and_publish'
  | 'resume'
  | 'earn_and_resume'
  | 'pause'
  | 'edit'
  | 'not_valid'

/**
 * The Survey interface
 */
export interface ISurvey {
  id?: string
  title?: string
  description?: string
  survey_type?: 'free_user' | 'paid_user'
  platform?: string
  respondents?: number
  responses?: number
  languages?: string[]
  country?: string
  gender?: string[]
  age?: {
    min?: number
    max?: number
  }
  min_age?: number // Received from API and transformed then into "age"
  max_age?: number // Received from API and transformed then into "age"
  occupation?: string[]
  education?: string[]
  duration?: number // Minutes
  survey_link?: string
  code?: string
  status?: string
  published_at?: Date
  cost?: number
  cost_estimated?: boolean
  total_cost?: number
  cost_breakdown?: string // TODO: change to the interface
  used_credits?: number
  survey_questions?: SurveyQuestion[]
  survey_flags?: SurveyFlag[]
  boosted?: boolean
  boosted_at?: Date
  created_at?: Date
  updated_at?: Date
  check_valid_step_data?: string[]
  is_confirmed_add_intro?: boolean
  is_confirmed_add_survey_code?: boolean
  is_system_survey?: boolean
  end_of_survey_text?: string
  tracking_method?: string
  branching_logic?: string
  user?: User
  days_to_pause?: number
  survey_schema_id?: string
  survey_schema?: SurveySchema
  custom_requirements?: string
  custom_requirements_type?: string
  keyword_list?: string[]
}

export interface ISurveyWithStats extends Survey {
  credits_used?: number
  credits_needed?: number
  amountOfCreditsNeeded?: number
  priceForCredits?: number
  plan?: Plan
}

export type TSurveyDifficulty =
  | 'unknown'
  | 'easy'
  | 'normal'
  | 'difficult'
  | 'very_difficult'

/**
 * The Survey model.
 */
export class Survey {
  id?: string
  title?: string
  description?: string
  survey_type?: 'free_user' | 'paid_user'
  platform?: string
  respondents?: number
  responses?: number
  languages?: string[]
  country?: string
  gender?: string[]
  age?: {
    min?: number
    max?: number
  }
  occupation?: string[]
  education?: string[]
  duration?: number
  survey_link?: string
  code?: string
  status?: string
  published_at?: Date
  cost?: number
  cost_estimated?: boolean
  total_cost?: number
  cost_breakdown?: string
  used_credits?: number
  survey_questions?: SurveyQuestion[]
  survey_flags?: SurveyFlag[]
  boosted?: boolean
  boosted_at?: Date
  created_at?: Date
  updated_at?: Date
  check_valid_step_data?: string[]
  is_confirmed_add_intro?: boolean
  is_confirmed_add_survey_code?: boolean
  is_system_survey?: boolean
  end_of_survey_text?: string
  tracking_method?: string
  branching_logic?: string
  user?: User
  days_to_pause?: number
  survey_schema_id?: string
  survey_schema?: SurveySchema
  custom_requirements?: string
  custom_requirements_type?: string
  keyword_list?: string[]

  constructor(props: ISurvey) {
    this.assignProps(props)
  }

  assignProps(props: ISurvey): void {
    this.id = props.id
    this.user = props.user
    this.title = props.title
    this.description = props.description
    this.survey_type = props.survey_type
    this.platform = props.platform
    this.respondents = props.respondents
    this.responses = props.responses
    this.languages = props.languages
    this.country = props.country
    this.gender = props.gender
    this.age = props.age
      ? props.age
      : { min: props.min_age, max: props.max_age }
    this.occupation = props.occupation
    this.education = props.education
    this.duration = props.duration
    this.survey_link = props.survey_link
    this.code = props.code
    this.status = props.status
    this.published_at = props.published_at
    this.cost_breakdown = props.cost_breakdown
    this.used_credits = props.used_credits
    this.survey_questions = props.survey_questions?.map(
      s => new SurveyQuestion(s as ISurveyQuestion),
    )
    this.survey_flags =
      props.survey_flags?.map(s => new SurveyFlag(s as ISurveyFlag)) || []
    this.boosted = props.boosted
    this.boosted_at = props.boosted_at
    this.created_at = props.created_at
    this.updated_at = props.updated_at

    this.cost = props.cost
    this.cost_estimated = props.cost_estimated
    this.total_cost = this.calculateTotalCost()
    this.check_valid_step_data = props.check_valid_step_data || []
    this.is_confirmed_add_intro = props.is_confirmed_add_intro
    this.is_confirmed_add_survey_code = props.is_confirmed_add_survey_code
    this.is_system_survey = props.is_system_survey
    this.end_of_survey_text = props.end_of_survey_text
    this.tracking_method = props.tracking_method
    this.branching_logic = props.branching_logic
    this.days_to_pause = props.days_to_pause
    this.survey_schema_id = props.survey_schema_id
    this.survey_schema = props.survey_schema
      ? new SurveySchema(props.survey_schema)
      : null
    this.custom_requirements = props.custom_requirements
    this.custom_requirements_type = props.custom_requirements_type
    this.keyword_list = props.keyword_list || []
  }

  async save(): Promise<ISurvey | APIError<unknown> | null> {
    this.calculateCost()
    const response = await SurveyService.saveSurvey(this)
    if (response instanceof APIError) {
      return response
    } else if (response) {
      this.assignProps(response)
    }
    return response
  }

  isPublic = (): boolean => {
    return this.status === 'active'
  }

  getSurveyCodeLink = (): string => {
    if (this.id) {
      return `${appConfig.WEB_URL}/sr/${this.code}`
    }
    return ''
  }

  canBuyCreditsBasedOnStatus = (): boolean => {
    return !['draft', 'deactive', 'closed'].includes(this.status)
  }

  get isPersisted() {
    return this.status !== 'unlisted_survey_schema'
  }

  getSurveySharingLink = (): string => {
    if (this.id && this.isPersisted && this.status === 'active') {
      return `${appConfig.WEB_URL}/surveys/${this.id}/take-a-survey`
    }
    return this.survey_link
  }

  getSocialSurveySharingLink = (): string => {
    if (this.id && this.isPersisted) {
      return `${appConfig.WEB_URL}/surveys/${this.id}/take-a-survey`
    }
    return this.survey_link
  }

  getCost = (): number => {
    return this.cost || 0
  }

  getCostEstimated = (): boolean => {
    return this.cost_estimated || false
  }

  getTotalCost = (): number => {
    return this.total_cost || 0
  }

  getSurveyQuestionsCost = (): number => {
    if (!this.survey_questions || this.survey_questions.length === 0) {
      return 0
    }
    let sqCost = 0
    this.survey_questions.map((q: SurveyQuestion) => {
      sqCost = sqCost + (q.cost ? q.cost : 1)
    })
    return sqCost
  }

  getDuration = (): number => {
    return this.duration || 0
  }

  calculateCost = (): number => {
    const total = this.cost
    return total + this.getSurveyQuestionsCost()
  }

  calculateTotalCost = (): number => {
    const cost = this.cost ? this.cost : 0
    if (this.boosted) {
      return cost * 2
    }
    return cost
  }

  countRequirements = (): number => {
    let total = 0
    if (this.languages?.length) total = total + 1
    if (this.country) total = total + 1
    if (this.gender?.length) total = total + 1
    if (this.age && (this.age.min || this.age.max)) total = total + 1
    if (this.occupation?.length) total = total + 1
    if (this.education?.length) total = total + 1
    return total
  }

  countAllRequirements = (): number => {
    return this.countRequirements() + this.countSurveyQuestions()
  }

  countResponses = (): number => {
    return this.responses || 0
  }

  countRespondents = (): number => {
    return this.respondents || 0
  }

  countNeededResponses = (): number => {
    return this.countRespondents() - this.countResponses()
  }

  countCreditsToComplete = (): number => {
    return (
      (this.countRespondents() - this.countResponses()) * this.getTotalCost()
    )
  }

  countSurveyQuestions = (): number => {
    return this.survey_questions ? this.survey_questions.length : 0
  }

  countResponsesForCreditsAmount = (credits: number): number => {
    if (!this.getTotalCost() || this.getTotalCost() === 0) {
      return 0
    }
    const maxAmount = Math.floor(credits / this.getTotalCost())
    if (maxAmount > this.countNeededResponses()) {
      return this.countNeededResponses()
    }
    return maxAmount
  }

  /**
   * Calculates the survey difficulty.
   */
  calculateDifficulty = (): TSurveyDifficulty => {
    let difficulty_level = 0

    const requirementsCount = this.countRequirements()
    const respondentsCount = this.countRespondents()
    const surveyQuestionsCount = this.countSurveyQuestions()

    if (respondentsCount === 0) {
      return 'unknown'
    }

    if (respondentsCount > 500) {
      return 'very_difficult'
    }

    if (respondentsCount > 50) {
      difficulty_level = difficulty_level + 1
    }

    if (respondentsCount > 100) {
      difficulty_level = difficulty_level + 1
    }

    if (requirementsCount > 1) {
      difficulty_level = difficulty_level + 1
    }

    if (requirementsCount > 2) {
      difficulty_level = difficulty_level + 1
    }

    if (requirementsCount > 3) {
      difficulty_level = difficulty_level + 1
    }

    if (requirementsCount > 4) {
      difficulty_level = difficulty_level + 1
    }

    if (requirementsCount > 5) {
      difficulty_level = difficulty_level + 1
    }

    if (
      this.age &&
      ((this.age.min && this.age.min > 45) ||
        (this.age.max && this.age.max < 15))
    ) {
      difficulty_level = difficulty_level + 8
    } else {
      if (
        this.age &&
        this.age.min &&
        this.age.max &&
        this.age.min > 14 &&
        this.age.max < 18
      ) {
        difficulty_level = difficulty_level + 4
      }
      if (this.age && this.age.min && this.age.min > 35) {
        difficulty_level = difficulty_level + 5
      } else if (this.age && this.age.min && this.age.min > 27) {
        difficulty_level = difficulty_level + 2
      }
    }

    if (this.occupation && this.occupation.length > 0) {
      if (this.occupation.includes('student')) {
        difficulty_level = difficulty_level + 0
      } else if (
        this.occupation.includes('freelancer') ||
        this.occupation.includes('unemployed')
      ) {
        difficulty_level = difficulty_level + 1
      } else if (this.occupation.includes('part_time')) {
        difficulty_level = difficulty_level + 2
      } else {
        difficulty_level = difficulty_level + 7
      }
    }

    if (surveyQuestionsCount > 0) {
      difficulty_level = difficulty_level + 7
    }

    if (difficulty_level >= 2 && difficulty_level <= 3) {
      return 'normal'
    } else if (difficulty_level >= 4 && difficulty_level <= 5) {
      return 'difficult'
    } else if (difficulty_level > 5) {
      return 'very_difficult'
    } else {
      return 'easy'
    }
  }

  pauseSurvey = async (): Promise<Survey | APIError<unknown> | null> => {
    if (this.id) {
      const response = await SurveyService.pauseSurvey(this.id)
      if (response instanceof APIError) {
        return response
      }
      this.assignProps(response as ISurvey)
    }
    return this
  }

  resumeSurvey = async (): Promise<Survey | APIError<unknown> | null> => {
    if (this.id) {
      const response = await SurveyService.resumeSurvey(this.id)
      if (response instanceof APIError) {
        return response
      }
      this.assignProps(response as ISurvey)
    }
    return this
  }

  publishSurvey = async (): Promise<Survey | APIError<unknown> | null> => {
    if (this.id) {
      const response = await SurveyService.publishSurvey(this.id)
      if (response instanceof APIError) {
        return response
      }
      this.assignProps(response as ISurvey)
    }
    return this
  }

  isValidToPublish = (): boolean => {
    return !!(
      this.platform &&
      this.title &&
      this.title.length > 0 &&
      this.duration &&
      this.duration > 0 &&
      this.languages &&
      this.languages.length > 0 &&
      this.respondents &&
      this.respondents > 0 &&
      this.survey_link &&
      this.survey_link.length > 0 &&
      this.is_confirmed_add_intro &&
      this.is_confirmed_add_survey_code
    )
  }

  /**
   * Tells what action is possible on the survey due to its state
   * and user's credits balance.
   */
  getSurveyAction(currentUser: User): TSurveyActions | null {
    const availableCredits =
      currentUser &&
      currentUser.credits_balance &&
      currentUser.credits_balance.amount
        ? currentUser.credits_balance.amount
        : 0

    if (!this.isValidToPublish() || !this.cost) {
      return 'not_valid'
    }

    switch (this.status) {
      case 'draft':
        return 'edit'
      case 'insufficient_credits':
        return 'earn_and_publish'
      case 'closed':
      case 'paused':
        if (availableCredits >= this.cost) {
          return 'resume'
        }
        return 'earn_and_resume'
      case 'active':
        return 'pause'
      case 'blocked':
        return 'edit'
      default:
        return null
    }
  }

  /**
   * Toggles the boost.
   */
  boostSurvey = async (): Promise<Survey | APIError<unknown> | null> => {
    if (this.id) {
      const response = await SurveyService.boostSurvey(this.id, !this.boosted)
      if (response instanceof APIError) {
        return response
      }
      this.assignProps(response as ISurvey)
    }
    return this
  }

  deactive = async (): Promise<Survey | APIError<unknown> | null> => {
    if (this.id) {
      const response = await SurveyService.deactivateSurvey(this.id)
      if (response instanceof APIError) {
        return response
      }
      this.assignProps(response as ISurvey)
    }
    return this
  }

  getRequiredCredits = (): number => {
    if (this.duration && this.respondents) {
      return this.duration * this.respondents
    }

    return 0
  }

  get type() {
    return 'surveys'
  }

  get attributes() {
    return Object.keys(this)
  }

  get isSurveySwapSurvey() {
    return this.platform === 'SurveySwap'
  }
}
