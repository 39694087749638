import { APIError, INewInvitation } from '../models'
import APIService from './APIService'

class InvitationsService {
  /**
   * Send invitations
   */
  static async sendInvitations(
    emails: string[],
    surveyId?: string,
  ): Promise<INewInvitation[] | APIError<unknown> | null> {
    const response = await APIService.post(`/invitations`, '', {
      emails: emails,
      survey_id: surveyId,
    })
    if (response instanceof APIError) {
      return response
    }
    return response.data.map((i: INewInvitation) => {
      return { ...i, status: i.success ? 'sent' : 'failed' }
    })
  }

  /**
   * Check if inviation was used and try to accept it.
   */
  static async checkInvitation(
    invToken: string,
    invId: string,
  ): Promise<boolean | APIError<unknown> | null> {
    const response = await InvitationsService.accept(invId, invToken)

    return response
  }

  /**
   * Accept the invitation
   */
  static async accept(
    invitationId: string,
    token: string,
  ): Promise<boolean | APIError<unknown> | null> {
    return await APIService.post(`/invitations/${invitationId}/accept`, '', {
      token: token,
    })
  }
}

export default InvitationsService
