import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const WillThisReallyGetMeResultsQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('will_this_really_get_me_results.question')}</>
}

export const WillThisReallyGetMeResultsAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>
        {t('will_this_really_get_me_results.para1')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('will_this_really_get_me_results.para2')}
      </p>
    </div>
  )
}
