import { Plan, TCurrencies } from '../../../models'
import {
  humanizePrice,
  priceWithCurrency,
} from '../../../helpers/formattingHelpers'
import classNames from 'classnames'
import { Button, ButtonStyle } from '../../UI/Button'
import { SecurePayments } from './SelectPlans'

interface IPurchaseOverview {
  plan: Plan
  currency: TCurrencies
  onBuyCredits: (plan: Plan) => void
  discountCode: string | null // Add this prop to check if a discount code is applied
}
const PurchaseOverview = ({
  plan,
  currency,
  onBuyCredits,
  discountCode,
}: IPurchaseOverview): JSX.Element => {
  const formatPrice = price => priceWithCurrency(price, currency)

  const subTotal = plan
    ? humanizePrice(plan.getPriceInCurrency(currency), currency)
    : 0
  const totalDiscount = plan
    ? humanizePrice(plan.getDiscountInCurrency(currency), currency)
    : 0
  const total = plan
    ? humanizePrice(plan.getTotalInCurrency(currency), currency)
    : 0

  const discountApplied = plan && plan.discount_rate > 0 && discountCode

  return (
    <div id='purchase-overview'>
      <h2 className='font-bold text-2xl text-center mb-6'>Purchase Overview</h2>
      {plan && (
        <div className='w-96 mx-auto relative'>
          {' '}
          {/* Relative positioning */}
          {discountApplied && (
            <div className='absolute -top-6 -right-6 w-24 h-24 bg-blue-500 rounded-full flex items-center justify-center z-10 text-center p-2'>
              <span className='text-white text-sm leading-none'>
                {discountCode} discount applied
              </span>
            </div>
          )}
          <div className='bg-white border rounded-md p-6'>
            <div className='text-xl text-bold'>{plan.plan_name}</div>
            <div>{plan.respondents} respondents</div>
            <div className='text-sm'>({plan.credits} credits)</div>

            <hr className='my-4' />

            <div className='flex justify-between'>
              <p className='opacity-60'>Subtotal</p>
              <p>{formatPrice(subTotal)}</p>
            </div>

            <div className='flex justify-between'>
              <p className='opacity-60'>Discount</p>
              <p>{formatPrice(totalDiscount)}</p>
            </div>

            <hr className='my-4' />

            <div className='flex justify-between'>
              <p className='font-bold text-lg'>Total</p>
              <p className='font-bold text-lg'>{formatPrice(total)}</p>
            </div>
          </div>
          <div className='mt-4'>
            <Button
              className={classNames(
                ButtonStyle.BLUE,
                'border-blue-500 hover:border-blue-800 hover:bg-blue-800 focus:border-blue-800 focus:bg-blue-800 w-full py-2',
              )}
              onClick={() => onBuyCredits(plan)}
            >
              Proceed
            </Button>
          </div>
        </div>
      )}

      <div className='mt-4'>
        <SecurePayments />
      </div>
    </div>
  )
}

export default PurchaseOverview
