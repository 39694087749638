/* eslint-disable @typescript-eslint/no-empty-function */

export class MockedDestination {
  track(name: string, props = {}): void {
    console.log('MockedDestination Tracking Event:', name, props)
  }

  identify(user: any, props = {}): void {
    console.log('MockedDestination Identify:', user.id, props)
  }

  page(): void {
    console.log('MockedDestination Track Page:', window.location.href)
  }

  reset(): void {
    console.log('MockedDestination Reset')
  }
}
