import React, { useCallback, useMemo } from 'react'

import StepCard, { IStepCardProps } from './StepCard'
import { ReactComponent as Step1Icon } from '../../../assets/BuyCredits/coin-icon.svg'
import { ReactComponent as Step2Icon } from '../../../assets/BuyCredits/people-icon.svg'
import { ReactComponent as Step3Icon } from '../../../assets/BuyCredits/card-tick-icon.svg'
import { ReactComponent as CurvedDashedArrowLeftIcon } from '../../../assets/BuyCredits/curved-dashed-arrow-left-icon.svg'
import { ReactComponent as CurvedDashedArrowRightIcon } from '../../../assets/BuyCredits/curved-dashed-arrow-right-icon.svg'
import { useTranslation } from 'react-i18next'
import { Survey } from '../../../models'

// eslint-disable-next-line react/prop-types
const ArrowWrapper: React.FC = ({ children }) => {
  return (
    <div className='flex flex-col self-end md:self-center h-7 md:h-auto mx-0 md:mx-1 transform translate-y-2 md:translate-y-0 translate-x-5 md:translate-x-0'>
      {children}
    </div>
  )
}

interface IBuyRespondentsSteps {
  surveys?: Survey[]
  currentStep: number
  setStep?: (number) => void
}

const BuyRespondentsSteps = ({
  currentStep,
  setStep,
  surveys = [],
}: IBuyRespondentsSteps): JSX.Element => {
  const { t } = useTranslation('app')
  const STEP_CARD_DETAILS = useMemo<Array<IStepCardProps>>(() => {
    const step1Message = surveys.length
      ? t('buy_credits.buy_guide.select_surveys')
      : t('buy_credits.buy_guide.select_respondents')

    return [
      {
        step: 1,
        message: step1Message,
        Icon: Step1Icon,
        currentStep: currentStep,
      },
      {
        step: 2,
        message: t('buy_credits.buy_guide.step_2.label'),
        Icon: Step2Icon,
        currentStep: currentStep,
      },
      {
        step: 3,
        message: t('buy_credits.buy_guide.step_3.label'),
        Icon: Step3Icon,
        currentStep: currentStep,
      },
    ]
  }, [surveys])

  const renderStepArrow = useCallback((step: number) => {
    switch (step) {
      case 1:
        return (
          <ArrowWrapper>
            <CurvedDashedArrowRightIcon className='transform rotate-90 md:rotate-0' />
          </ArrowWrapper>
        )
      case 2:
        return (
          <ArrowWrapper>
            <CurvedDashedArrowLeftIcon className='transform rotate-90 md:rotate-0 hidden md:block' />
            <CurvedDashedArrowRightIcon className='transform rotate-90 md:rotate-0 md:hidden' />
          </ArrowWrapper>
        )
      default:
        return null
    }
  }, [])

  return (
    <div className='mx-auto flex flex-col md:flex-row content-center justify-center'>
      {STEP_CARD_DETAILS.map(details => {
        return (
          <React.Fragment key={details.step}>
            <StepCard {...details} onClick={() => setStep(details.step)} />
            {renderStepArrow(details.step)}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default BuyRespondentsSteps
