// import { Tooltip } from '@material-ui/core'
import Slider from '@material-ui/core/Slider'
import classNames from 'classnames'
import { debounce } from 'lodash'
import { useCallback, useState } from 'react'
import {
  humanizePrice,
  priceWithCurrency,
} from '../../../helpers/formattingHelpers'
import { APIError, Plan, TCurrencies } from '../../../models'
import OrdersService from '../../../services/OrdersService'

import { Badge } from '../../UI/Badge'
import { Button, ButtonStyle } from '../../UI/Button'
import { Loader } from '../../UI/Loader'
import { emptyPlan } from '../../../containers/BuyCreditsContainer/BuyCreditsContainer'

const RespondentSlider = ({
  currency,
  setSelected,
  buyCredits,
  surveyDuration,
  discountCode,
}: {
  currency: TCurrencies
  setSelected: (selected: Plan) => void
  buyCredits: (plan: Plan) => void
  surveyDuration: number
  discountCode: string | null // Add this
}): JSX.Element => {
  const INITIAL_CREDITS = 0

  const [plan, setPlan] = useState<Plan>(emptyPlan)
  const [showLoader, setShowLoader] = useState(false)

  const calculateOtherPlan = async (
    respondents: number | null = null,
    price: number | null = null,
    currency: TCurrencies,
  ) => {
    const credits = surveyDuration * respondents
    const response = await OrdersService.calculatePlan({
      credits: credits ? credits : null,
      price: price ? price * 100 : null,
      currency,
      discountCode, // Include the discount code in the request
    })
    if (response instanceof APIError) {
      console.error('ERROR: cannot calculate other plan with API', response)
      return
    }
    if (response) {
      response.plan_name = 'Customized package'
      response.respondents = respondents

      setPlan(response)
      setShowLoader(false)
    }
  }

  const handleSlideFinished = (e, respondents) => {
    setShowLoader(true)
    if (respondents == 0) {
      setPlan(emptyPlan)
      setShowLoader(false)
      return
    } else {
      debouncedCalculateOtherPlan(respondents, null, currency)
    }
  }

  const debouncedCalculateOtherPlan = useCallback(
    debounce(
      (respondents, price, currency) =>
        calculateOtherPlan(respondents, price, currency),
      500,
    ),
    [],
  )

  return (
    <div className='w-full bg-white rounded-md'>
      <div className='flex w-full flex-col md:flex-row'>
        <div className='sliderSection p-6 md:w-3/5'>
          <h3 className='mb-16'>Select how many respondents you want to buy</h3>
          <Slider
            defaultValue={INITIAL_CREDITS}
            min={0}
            max={1000}
            valueLabelDisplay='on'
            disabled={false}
            onChangeCommitted={handleSlideFinished}
          />{' '}
        </div>

        <div className='sliderSection m-6 p-3 bg-blue-100 flex content-center align-center flex-col rounded-md md:w-2/5'>
          {showLoader && (
            <div className='w-full flex text-center justify-center items-center'>
              <Loader />
            </div>
          )}
          {!showLoader && (
            <>
              <div className='flex justify-between w-full'>
                <div className='flex mb-5'>
                  {' '}
                  {plan && plan.getTotalInCurrency(currency) > 0 && (
                    <span className='text-lg text-blue-500 font-semibold pr-1 mr-1'>
                      {priceWithCurrency(
                        humanizePrice(
                          plan.getTotalInCurrency(currency),
                          currency,
                        ),
                        currency,
                      )}
                    </span>
                  )}
                  <s className='text-lg text-gray-100'>
                    {plan.discount_rate > 0 && (
                      <p>
                        {' '}
                        {priceWithCurrency(
                          humanizePrice(
                            plan.getPriceInCurrency(currency),
                            currency,
                          ),
                          currency,
                        )}
                      </p>
                    )}
                  </s>
                </div>
                <div>
                  {plan.discount_rate > 0 && (
                    <Badge
                      className='max-w-max rounded-md whitespace-nowrap bg-green-400 border border-green-600 text-white text-xs leading-5'
                      style='bg-green-400 px-2'
                    >
                      Save{' '}
                      {priceWithCurrency(
                        humanizePrice(
                          plan.getDiscountInCurrency(currency),
                          currency,
                        ),
                        currency,
                      )}
                    </Badge>
                  )}
                </div>
              </div>
              <div className='creditSection w-full text-center mb-6'>
                <h3 className='text-xl font-bold'>
                  {plan.respondents} respondents
                </h3>
                <div className='text-sm font-bold text-gray-100'>
                  {plan.credits} credits
                </div>
              </div>

              <Button
                disabled={plan.credits === 0}
                className={classNames(
                  ButtonStyle.BLUE,
                  'border-blue-500 hover:border-blue-800 hover:bg-blue-800 focus:border-blue-800 focus:bg-blue-800 w-full py-2',
                )}
                onClick={() => {
                  setSelected(plan)
                  buyCredits(plan)
                }}
              >
                Buy now
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default RespondentSlider
