import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './styles/sidebar/scss/styles.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './I18n'
import { HelmetProvider } from 'react-helmet-async'

import A6YReactAuth from '@altalogy/a6y-react-auth'
import appConfig from './config/app'
import awsConfig from './config/aws'
import { AuthHeader } from './components/AuthComponent/components/AuthHeader'
import { ForgotPasswordHeader } from './components/AuthComponent/components/ForgotPasswordHeader'
import { SetNewPasswordHeader } from './components/AuthComponent/components/SetNewPasswordHeader'
import { AuthFormLinks } from './components/AuthComponent/components/AuthFormLinks'
// import { Consent1 } from './components/AuthComponent/components/Consent1'
// import { Consent2 } from './components/AuthComponent/components/Consent2'
import { Consent3 } from './components/AuthComponent/components/Consent3'
import { CodeInput } from './components/AuthComponent/components/CodeInput'
const ReactAuth = new A6YReactAuth()
const socialLoginStyle =
  'w-full px-6 py-2.5 my-4 flex flex-row items-center justify-center rounded-md shadow border hover:shadow-sm'
const socialLoginIconStyle = 'w-5 h-5 mr-5'
const socialLoginTextStyle = 'text-sm font-semibold w-36 max-w-full text-left'

ReactAuth.initialize({
  provider: {
    type: 'cognito',
    userPoolId: awsConfig.Auth.userPoolId,
    userPoolWebClientId: awsConfig.Auth.userPoolWebClientId,
    identityPoolId: '',
    region: awsConfig.Auth.region,
    oauthDomain: awsConfig.Auth.oauthDomain,
    oauthRedirectSignIn: `${appConfig.WEB_URL}/get-credits/`,
    oauthRedirectSignOut: `${appConfig.WEB_URL}/sign-in/`,
  },
  components: {
    Inputs: {
      labels: true,
    },
    signUp: {
      headerComponent: <AuthHeader />,
      linksComponent: {
        customLinksComponent: <AuthFormLinks formType='sign-up' />,
      },
      social: {
        facebook: {
          buttonText: 'Sign up Facebook',
        },
        google: {
          buttonText: 'Sign up Google',
        },
      },
      confirmation: true,
    },
    signIn: {
      headerComponent: <AuthHeader />,
      linksComponent: {
        customLinksComponent: <AuthFormLinks formType='sign-in' />,
      },
      social: {
        facebook: {
          buttonText: 'Login Facebook',
        },
        google: {
          buttonText: 'Login Google',
        },
      },
    },
    forgotPassword: {
      headerComponent: <ForgotPasswordHeader title={'FORGOT_PASSWORD'} />,
      linksComponent: {
        customLinksComponent: <AuthFormLinks formType='forgot-password' />,
      },
    },
    forgotPasswordSubmit: {
      headerComponent: (
        <SetNewPasswordHeader title={'FORGOT_PASSWORD_SUBMIT'} />
      ),
    },
    consents: {
      display: 'sign-up',
      position: 'top',
      consents: [
        // {
        //   name: 'obligatory_notifications',
        //   type: 'checkbox',
        //   required: true,
        //   content: <Consent1 />,
        //   isElement: true,
        // },
        // {
        //   name: 'tips_notifications',
        //   type: 'checkbox',
        //   required: false,
        //   content: <Consent2 />,
        //   isElement: true,
        // },
        {
          name: 'code',
          type: 'other',
          required: false,
          content: <CodeInput />,
          isElement: true,
        },
        {
          name: 'terms',
          type: 'other',
          required: false,
          content: <Consent3 />,
          isElement: true,
        },
      ],
    },
  },
  auth: [
    {
      appId: awsConfig.Auth.facebookProviderAppId,
      provider: 'facebook',
      className:
        socialLoginStyle + ' bg-brands-facebook border-brands-facebook',
      iconClassName: socialLoginIconStyle,
      textClassName: socialLoginTextStyle + ' text-white',
    },
    {
      appId: awsConfig.Auth.googleProviderAppId,
      provider: 'google',
      className: socialLoginStyle + ' bg-white border-gray-300',
      iconClassName: socialLoginIconStyle,
      textClassName: socialLoginTextStyle,
    },
  ],
})

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
