import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const HowDoIGetRespondentsStudentsQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('how_do_i_get_respondents_students.question')}</>
}

export const HowDoIGetRespondentsStudentsAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>
        {t('how_do_i_get_respondents_students.para1')}
      </p>
      <ol className='list-decimal pl-5'>
        <li>{t('how_do_i_get_respondents_students.list1')}</li>
        <li>{t('how_do_i_get_respondents_students.list2')}</li>
        <li>{t('how_do_i_get_respondents_students.list3')}</li>
        <li>{t('how_do_i_get_respondents_students.list4')}</li>
      </ol>
    </div>
  )
}
