import React from 'react'
import { Navbar } from '../components/UI/Navbar'

/**
 * @typedef ILayoutProps
 * @props {React.ReactNode} children - the container children.
 */

interface ILayoutProps {
  children: React.ReactNode
}

/**
 * Renders the layout with components.
 *
 * @param {React.ReactNode} children - the container children.
 *
 * @example
 * <MainLayout>
 { children }
 </MainLayout>
 */

const OnlyNavbarLayout = ({ children }: ILayoutProps): JSX.Element => {
  return (
    <div>
      <Navbar />
      <div className=''>{children}</div>
    </div>
  )
}

export default OnlyNavbarLayout
