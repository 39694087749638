import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import vectorImg from '../../assets/LandingPage/Home/Vector.svg'
import classNames from 'classnames'
import {
  CanIUseSurveySwapQuestion,
  CanIUseSurveySwapAnswer,
  EaseOfUseQuestion,
  EaseOfUseAnswer,
  DeliverySpeedQuestion,
  DeliverySpeedAnswer,
  GuarnteeToGetRespondentsQuestion,
  GuarnteeToGetRespondentsAnswer,
  GuaranteedResponsesQuestion,
  GuaranteedResponsesAnswer,
  HighQualityDataQuestion,
  HighQualityDataAnswer,
  HowDoIGetRespondentsQuestion,
  HowDoIGetRespondentsAnswer,
  HowDoIGetRespondentsHomeQuestion,
  HowDoIGetRespondentsHomeAnswer,
  HowDoIGetRespondentsStudentsQuestion,
  HowDoIGetRespondentsStudentsAnswer,
  IsItFreeQuestion,
  IsItFreeAnswer,
  IsTheDataOfHighQualityQuestion,
  IsTheDataOfHighQualityAnswer,
  WhatIsASpecificAudienceQuestion,
  WhatIsASpecificAudienceAnswer,
  WhatIsASpecificAudiencePaidQuestion,
  WhatIsASpecificAudiencePaidAnswer,
  WhenIReceiveRespondentsQuestion,
  WhenIReceiveRespondentsAnswer,
  WhenIReceiveRespondentsPaidQuestion,
  WhenIReceiveRespondentsPaidAnswer,
  WillThisReallyGetMeResultsQuestion,
  WillThisReallyGetMeResultsAnswer,
} from './FAQ'

export const paragraphBaseStyle = 'text-base font-normal mb-4'
export const subtitleStyle = 'font-semibold mb-2 mt-4'

const FAQ_PAID = [
  {
    question: <HowDoIGetRespondentsQuestion />,
    answer: <HowDoIGetRespondentsAnswer />,
  },
  {
    question: <WhenIReceiveRespondentsPaidQuestion />,
    answer: <WhenIReceiveRespondentsPaidAnswer />,
  },
  {
    question: <WhatIsASpecificAudiencePaidQuestion />,
    answer: <WhatIsASpecificAudiencePaidAnswer />,
  },
  {
    question: <GuarnteeToGetRespondentsQuestion />,
    answer: <GuarnteeToGetRespondentsAnswer />,
  },
  {
    question: <IsTheDataOfHighQualityQuestion />,
    answer: <IsTheDataOfHighQualityAnswer />,
  },
]

const FAQ_PAYMENT = [
  {
    question: <EaseOfUseQuestion />,
    answer: <EaseOfUseAnswer />,
  },
  {
    question: <DeliverySpeedQuestion />,
    answer: <DeliverySpeedAnswer />,
  },
  {
    question: <GuaranteedResponsesQuestion />,
    answer: <GuaranteedResponsesAnswer />,
  },
  {
    question: <HighQualityDataQuestion />,
    answer: <HighQualityDataAnswer />,
  },
]

const FAQ_BUY_CREDITS = [
  {
    question: <EaseOfUseQuestion />,
    answer: <EaseOfUseAnswer />,
  },
  {
    question: <DeliverySpeedQuestion />,
    answer: <DeliverySpeedAnswer />,
  },
  {
    question: <GuaranteedResponsesQuestion />,
    answer: <GuaranteedResponsesAnswer />,
  },
  {
    question: <HighQualityDataQuestion />,
    answer: <HighQualityDataAnswer />,
  },
]

const FAQ_HOME = [
  {
    question: <HowDoIGetRespondentsHomeQuestion />,
    answer: <HowDoIGetRespondentsHomeAnswer />,
  },
  {
    question: <WhenIReceiveRespondentsQuestion />,
    answer: <WhenIReceiveRespondentsAnswer />,
  },
  {
    question: <CanIUseSurveySwapQuestion />,
    answer: <CanIUseSurveySwapAnswer />,
  },
  // {
  //   question: <IsItFreeQuestion />,
  //   answer: <IsItFreeAnswer />,
  // },
  {
    question: <WillThisReallyGetMeResultsQuestion />,
    answer: <WillThisReallyGetMeResultsAnswer />,
  },
  {
    question: <WhatIsASpecificAudienceQuestion />,
    answer: <WhatIsASpecificAudienceAnswer />,
  },
]

const FAQ_STUDENTS = [
  {
    question: <HowDoIGetRespondentsStudentsQuestion />,
    answer: <HowDoIGetRespondentsStudentsAnswer />,
  },
  {
    question: <WhenIReceiveRespondentsQuestion />,
    answer: <WhenIReceiveRespondentsAnswer />,
  },
  {
    question: <CanIUseSurveySwapQuestion />,
    answer: <CanIUseSurveySwapAnswer />,
  },
  {
    question: <IsItFreeQuestion />,
    answer: <IsItFreeAnswer />,
  },
  {
    question: <WillThisReallyGetMeResultsQuestion />,
    answer: <WillThisReallyGetMeResultsAnswer />,
  },
  {
    question: <WhatIsASpecificAudienceQuestion />,
    answer: <WhatIsASpecificAudienceAnswer />,
  },
]

export const FrequentlyQuestions = ({
  page,
  fontSizeHeader,
}: {
  page?: 'home' | 'paid' | 'students' | 'payment' | 'buyCredits'
  fontSizeHeader?: 'text-2xl' | 'text-3xl'
}): JSX.Element => {
  const { t } = useTranslation('app')

  let FAQ = FAQ_HOME

  switch (page) {
    case 'paid':
      FAQ = FAQ_PAID
      break
    case 'students':
      FAQ = FAQ_STUDENTS
      break
    case 'payment':
      FAQ = FAQ_PAYMENT
      break
    case 'buyCredits':
      FAQ = FAQ_BUY_CREDITS
      break
    default:
      FAQ = FAQ_HOME
      break
  }

  return (
    <div
      id='faq'
      className='flex flex-col md:flex-row items-start py-12 px-8 lg:px-0'
    >
      <div className='w-full md:w-2/5 pr-5'>
        <p
          className={classNames('font-bold pb-12 text-2xl', {
            'text-2xl': fontSizeHeader == 'text-2xl',
            'text-3xl': fontSizeHeader == 'text-3xl',
          })}
        >
          {t('FAQ')}
        </p>
      </div>
      <div className='flex flex-col w-full md:w-3/5'>
        {FAQ.map((el, idx) => {
          const [openFAQ, setFAQ] = useState(false)
          return (
            <div key={`faq-` + idx} className='transition-all'>
              <div
                onClick={() => setFAQ(!openFAQ)}
                onKeyDown={() => setFAQ(!openFAQ)}
                role='button'
                tabIndex={idx}
                className='flex items-center my-2'
              >
                <div className='flex items-center justify-center w-6 h-6 bg-blue-700 rounded-full'>
                  <img
                    className={classNames('w-3 transition-all', {
                      ['transform rotate-180']: !openFAQ,
                    })}
                    src={vectorImg}
                    alt={`faq-${idx}`}
                  />
                </div>
                <p className='ml-4 font-semibold text-lg flex-1'>
                  {el.question}
                </p>
              </div>
              <div
                className={classNames('transition-all pl-10 my-4', {
                  ['hidden']: !openFAQ,
                })}
              >
                {el.answer}
              </div>
            </div>
          )
        })}
        <p className='border-t mt-6 pt-6 mb-12'>
          {page === 'paid'
            ? t('FAQ_HAVE_MORE_QUESTIONS_PAID')
            : t('FAQ_HAVE_MORE_QUESTIONS')}
        </p>
      </div>
    </div>
  )
}
