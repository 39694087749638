import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Button } from '../UI/Button'
import { Link, LinkStyle } from '../UI/Link'
import regionsImg from '../../assets/LandingPage/Home/regions.svg'
import regionsImgMobile from '../../assets/LandingPage/Home/regions-mobile.svg'
import arrowBlueImg from '../../assets/LandingPage/Home/arrow-blue.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const SupportRegion = ({
  image = regionsImg,
  mobileImage = regionsImgMobile,
  tabletImage = regionsImg,
  page,
  ctaHref = '/agency',
  showWhatIfButton = true,
}: {
  image?: string
  mobileImage?: string
  tabletImage?: string
  page?: 'home' | 'students' | 'paid' | 'quote'
  ctaHref?: string
  showWhatIfButton?: boolean
}): JSX.Element => {
  const [regions, setRegions] = useState('countries')
  const { t } = useTranslation('app')

  const demographicsText = () => {
    switch (page) {
      case 'paid':
        return (
          <>
            <p className='font-normal mt-8 mb-2'>
              {t('DEMOGRAPHICS_PAID_DESC')}
            </p>
            <p className='font-normal mt-2 mb-8'>
              {t('DEMOGRAPHICS_PAID_DESC_2')}
            </p>
          </>
        )
      case 'quote':
        return (
          <>
            <p className='font-normal my-8'>{t('DEMOGRAPHICS_QUOTE_DESC')}</p>
          </>
        )
      default:
        return (
          <>
            <p className='font-normal mt-8 mb-2'>
              {t('DEMOGRAPHICS_HOME_DESC')}
            </p>
            <p className='font-normal mt-2 mb-8'>
              {t('DEMOGRAPHICS_HOME_DESC_2')}
            </p>
          </>
        )
    }
  }

  const countriesText = () => {
    switch (page) {
      case 'paid':
        return (
          <>
            <p className='font-normal my-8'>{t('COUNTRIES_PAID_DESC')}</p>
            <p className='font-normal my-8'>{t('COUNTRIES_PAID_DESC_2')}</p>
          </>
        )
      case 'quote':
        return (
          <>
            <p className='font-normal my-8'>{t('COUNTRIES_QUOTE_DESC')}</p>
          </>
        )
      default:
        return (
          <>
            <p className='font-normal mt-8 mb-2'>{t('COUNTRIES_HOME_DESC')}</p>
            <p className='font-normal mt-2 mb-2'>
              {t('COUNTRIES_HOME_DESC_2')}
            </p>
            <p className='font-normal mt-2 mb-8'>
              {t('COUNTRIES_HOME_DESC_3')}
            </p>
          </>
        )
    }
  }

  return (
    <div className='my-24 px-8 lg:px-0'>
      <h4 className='text-2xl font-bold mb-12'>{t('REGIONS_SUPPORT')}</h4>
      <div className='flex items-start'>
        <div className='flex-1'>
          <div>
            <Button
              style='border-t-none border-x-none bg-transparent mx-1 px-1 pb-2'
              className={classNames('', {
                ['text-blue-500 border-b-2 border-blue-500']:
                  regions === 'countries',
              })}
              onClick={() => setRegions('countries')}
            >
              {t('COUNTRIES')}
            </Button>
            <Button
              style='border-t-none border-x-none bg-transparent mr-1 px-1 pb-2'
              className={classNames('', {
                ['text-blue-500 border-b-2 border-blue-500']:
                  regions === 'demographics',
              })}
              onClick={() => setRegions('demographics')}
            >
              {t('DEMOGRAPHICS')}
            </Button>
          </div>
          {regions === 'demographics' && demographicsText()}
          {regions === 'countries' && countriesText()}
          <LazyLoadImage
            className='w-48 mb-8 sm:hidden'
            src={mobileImage}
            alt='regions-img'
          />
          <LazyLoadImage
            className='w-72 mb-8 hidden sm:block md:hidden'
            src={tabletImage}
            alt='regions-img'
          />
          {/* <Link
            href={ctaHref}
            className='text-blue-500 font-semibold flex mb-8'
            style={LinkStyle.NONE}
          >
            {t('REQUEST_A_QUOTE')}
            <LazyLoadImage className='ml-2' src={arrowBlueImg} alt='specific' />
          </Link> */}
          {showWhatIfButton && (
            <Link
              href='#faq'
              className='text-blue-500 font-semibold flex'
              style={LinkStyle.NONE}
            >
              {t('WHAT_IF_SPECIFIC_REQUIREMENT')}
              <LazyLoadImage
                className='ml-2'
                src={arrowBlueImg}
                alt='specific'
              />
            </Link>
          )}
        </div>
        <div className='hidden flex-1 md:flex items-center justify-center'>
          <LazyLoadImage className='w-9/12' src={image} alt='regions-img' />
        </div>
      </div>
    </div>
  )
}
