import { useTranslation } from 'react-i18next'
import howItImg1 from '../../assets/LandingPage/Home/h-w-1.svg'
import howItImg2 from '../../assets/LandingPage/Home/h-w-2.svg'
import howItImg3 from '../../assets/LandingPage/Home/h-w-3.svg'
import howItImg4 from '../../assets/LandingPage/Home/h-w-4.svg'

/**
 * Renders the how it works section.
 */
export const HowItWorksPaid = (): JSX.Element => {
  const { t } = useTranslation('app')
  const getDivideList = () => (
    <span className='ml-4 border-l-2 border-blue-700' />
  )
  return (
    <ul>
      <li className='flex items-center font-normal'>
        <span className='bg-blue-700 rounded-full p-2 w-9 h-9 mr-4 flex items-center justify-center'>
          <img className='w-4 h-4' src={howItImg1} alt='how-it-works-1' />
        </span>
        {t('HOW_WORKS_1_PAID')}
      </li>
      <li>{getDivideList()}</li>
      <li className='flex items-center font-normal'>
        <span className='bg-blue-700 rounded-full p-2 w-9 h-9 mr-4 flex items-center justify-center'>
          <img className='w-4 h-4' src={howItImg2} alt='how-it-works-2' />
        </span>
        {t('HOW_WORKS_2_PAID')}
      </li>
      <li>{getDivideList()}</li>
      <li className='flex items-center font-normal'>
        <span className='bg-blue-700 rounded-full p-2 w-9 h-9 mr-4 flex items-center justify-center'>
          <img className='w-4 h-4' src={howItImg3} alt='how-it-works-3' />
        </span>
        {t('HOW_WORKS_3_PAID')}
      </li>
      <li>{getDivideList()}</li>
      <li className='flex items-center font-normal'>
        <span className='bg-blue-700 rounded-full p-2 w-9 h-9 mr-4 flex items-center justify-center'>
          <img className='w-4 h-4' src={howItImg4} alt='how-it-works-4' />
        </span>
        {t('HOW_WORKS_4_PAID')}
      </li>
    </ul>
  )
}

export default HowItWorksPaid
