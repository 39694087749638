import { Container } from '../ContainerComponent'
import { Link, LinkStyle } from '../Link'
import { Logo } from '../Logo'

export const NewUserNavbar = (): JSX.Element => {
  return (
    <nav className='fixed flex items-center justify-between w-full bg-white z-50 transition-all p-4'>
      <Container className='pr-0 pl-0 flex items-center sm:max-w-screen-lg justify-between w-full mx-auto'>
        <div className='flex items-center'>
          <Link href='/' style={LinkStyle.NONE}>
            <Logo
              signetType='primary'
              logotypeClassName='hidden sm:block'
              className='flex items-center sm:w-36 justify-between p-1 sm:p-2 m-1'
            />
          </Link>
        </div>
      </Container>
    </nav>
  )
}
