import { AiOutlineFieldTime } from 'react-icons/ai'
import { GiReceiveMoney } from 'react-icons/gi'
import { VscVerified } from 'react-icons/vsc'

const WhySurveySwap = (): JSX.Element => {
  return (
    <div className='py-4'>
      <div className='max-w-screen-lg mx-auto px-8 lg:px-0'>
        <div className='mb-12 mt-4'>
          <p className='text-2xl text-center mb-4 ml-3 font-bold'>
            Why SurveySwap?
          </p>

          <div className='flex sm:justify-center items-start flex-wrap pt-4'>
            <div className='w-full sm:w-96 md:w-1/3 my-6 md:my-0 px-4'>
              <div className='text-center'>
                <AiOutlineFieldTime
                  size={64}
                  className='mx-auto text-blue-500'
                />
              </div>

              <h4 className='text-lg text-center font-semibold my-4'>
                Save Time & Energy
              </h4>
              <p className='sm:text-justify text-center font-normal'>
                Buying respondents allows you to fully focus on your research
                project instead of wasting valuable time and energy.
              </p>
            </div>

            <div className='w-full sm:w-96 md:w-1/3 my-6 md:my-0 px-4'>
              <div className='text-center'>
                <GiReceiveMoney size={64} className='mx-auto text-blue-500' />
              </div>

              <h4 className='text-lg text-center font-semibold my-4'>
                Guarantees
              </h4>
              <p className='sm:text-justify text-center font-normal'>
                We are confident that we can help you find respondents, so we
                offer a full satisfaction guarantee.
              </p>
            </div>

            <div className='w-full sm:w-96 md:w-1/3 my-6 md:my-0 px-4'>
              <div className='text-center'>
                <VscVerified size={64} className='mx-auto text-blue-500' />
              </div>

              <h4 className='text-lg text-center font-semibold my-4'>
                Real People
              </h4>
              <p className='sm:text-justify text-center font-normal'>
                Our innovative platform ensures that only real people
                participate in surveys, providing you with reliable data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhySurveySwap
