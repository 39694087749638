import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { isFunction } from 'lodash'

type TMenuButtonStylesFunction = (props: { dropdownOpen?: boolean }) => string

export interface IDropdown {
  children?: React.ReactNode
  button?: React.ReactNode | React.FunctionComponent<{ dropdownOpen?: boolean }>
  className?: string
  menuStyles?: string
  menuButtonStyles?: string | TMenuButtonStylesFunction
  openMenu?: boolean
}

/**
 * Renders the Dropdown menu component.
 *
 * @param {React.ReactNode} [children] - the Dropdown items.
 * @param {string} [className] - the CSS classes.
 *
 * @example
 * <Dropdown>
 *  { children }
 * </Dropdown>
 */
export const Dropdown: React.FunctionComponent<IDropdown> = ({
  className,
  menuStyles = 'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none',
  menuButtonStyles = 'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500',
  button,
  children,
}: IDropdown) => {
  const dropdownStyle = 'relative inline-block text-left'

  return (
    <Menu
      as='div'
      className={classNames(dropdownStyle, {
        [className as string]: className,
      })}
    >
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={
                isFunction(menuButtonStyles)
                  ? menuButtonStyles({ dropdownOpen: open })
                  : menuButtonStyles
              }
            >
              {isFunction(button) ? button({ dropdownOpen: open }) : button}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items static className={menuStyles}>
              {children}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
