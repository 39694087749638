export type VariantPage = Record<'path', string>
export type VariantSources = 'students' | 'paid'
export type VariantPages = Array<VariantPage>

const Variants: Record<VariantSources, VariantPages> = {
  students: [
    { path: '/free-survey-respondents' },
    { path: '/free-survey-participants' },
    { path: '/free-survey-responses' },
    { path: '/free-survey-exchange' },
    { path: '/free-survey-distribution' },
    { path: '/free-survey-data' },
    { path: '/free-survey-sharing' },
    { path: '/free-participants-for-your-study' },
    { path: '/free-research-participants' },
    { path: '/free-respondents-for-your-research' },
    { path: '/free-people-to-do-your-survey' },
    { path: '/free-people-to-take-your-survey' },
    { path: '/free-online-panel' },
    { path: '/post-a-questionnaire-for-free' },
    { path: '/promote-a-survey-for-free' },
    { path: '/recruit-survey-participants-for-free' },
    { path: '/send-out-a-survey-for-free' },
    { path: '/spread-a-survey-for-free' },
    { path: '/nl/respondenten' },
    { path: '/nl/gratis-enquete-laten-maken' },
    { path: '/nl/gratis-enquete-laten-invullen' },
    { path: '/nl/gratis-enquete-voor-scriptie' },
    { path: '/de/Teilnehmer' },
    { path: '/de/kostenlos-umfrage-erstellen' },
    { path: '/de/teilnehmer-für-umfrage-finden' },
    { path: '/de/teilnehmer-für-deine-masterarbeit' },
    { path: '/de/umfrage-posten' },
  ],
  paid: [
    { path: '/buy-responses' },
    { path: '/cheap-survey-responses' },
    { path: '/buy-survey-respondents' },
    { path: '/buy-survey-responses' },
    { path: '/buy-survey-answers' },
    { path: '/buy-survey-data' },
    { path: '/buy-survey-participants' },
    { path: '/purchase-survey-respondents' },
    { path: '/pay-to-get-survey-responses' },
    { path: '/purchase-survey-responses' },
    { path: '/research-panel' },
    { path: '/nl/koop-respondenten' },
    { path: '/nl/respondenten-kopen' },
    { path: '/nl/respondenten-panel' },
    { path: '/nl/respondenten-voor-onderzoek' },
    { path: '/nl/enquete-deelnemers' },
    { path: '/nl/respondenten-gezocht' },
    { path: '/de/Teilnehmer-kaufen' },
  ],
}

export default Variants
