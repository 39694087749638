import React from 'react'
import classNames from 'classnames'
import SignetLogo from '../../../assets/Logo/Logo.svg'
import SignetLogoAlternative from '../../../assets/Logo/Logo-alt.svg'

/**
 * @typedef ILogoProps
 * @props {string} [className] - the CSS classes.
 * @props {boolean} [signet] - it determines if the signet will display
 * @props {boolean} [logotype] - it determines if the signet will display
 * @props {string} [logotypeClassName] - the text CSS classes
 * @props {string} [logotypeFontWeight] - the text font weight
 * @props {string} [signetClassName] - the image CSS classes
 * @props {LogoSize | str} [size] - the size of logo
 * @props {'primary'|'secondary'} [signetType] - determines style of signet
 */

export interface ILogoProps {
  className?: string
  logotypeClassName?: string
  logotypeFontWeight?: string
  signetClassName?: string
  signet?: boolean
  logotype?: boolean
  size?: LogoSize | string
  signetType?: 'primary' | 'secondary'
}

/** @enum {string} LogoStyle */
export enum LogoSize {
  MEDIUM = 'transform scale-100',
}

/**
 * Renders the logo component.
 *
 * @param {boolean} [signet] - it determines if the signet will display
 * @param {string} [className] - the CSS classes
 * @param {boolean} [logotype] - it determines if the signet will display
 * @param {string} [logotypeClassName] - the text CSS classes
 * @param {string} [logotypeFontWeight] - the text font weight
 * @param {string} [signetClassName] - the image CSS classes
 * @param {LogoSize | str} [size] - the size of logo
 * @param {'primary'|'secondary'} [signetType] - determines style of signet
 *
 * @example
 * <Logo />
 */ export const Logo: React.FunctionComponent<ILogoProps> = ({
  className = 'flex items-center justify-between p-1 sm:p-2 m-1',
  logotypeClassName = '',
  logotypeFontWeight = 'font-semibold',
  signetClassName = '',
  signet = true,
  logotype = true,
  signetType = 'primary',
  size = 'transform scale-100',
}: ILogoProps) => {
  const signetStyle = classNames(size, signetClassName, {
    ['mr-3']: logotype && size === LogoSize.MEDIUM,
  })

  const logotypeStyle = classNames(
    size,
    logotypeClassName,
    logotypeFontWeight,
    {
      ['mx-0']: !signet && size === LogoSize.MEDIUM,
      // Ensure it's never hidden when the signetType is 'secondary'
      ['hidden sm:block']: signetType === 'primary', // Optional visibility for primary
      ['block']: signetType === 'secondary', // Always visible when 'secondary'
    },
  )

  const logotypeText =
    signetType === 'secondary' ? 'SurveySwap Agency' : 'SurveySwap'

  return (
    <div className={className}>
      {signet && (
        <img
          className={signetStyle}
          src={signetType === 'secondary' ? SignetLogoAlternative : SignetLogo}
          alt='logo'
        />
      )}
      {logotype && <p className={logotypeStyle}>{logotypeText}</p>}
    </div>
  )
}
