import React from 'react'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import './NotificationsBox.css'

export interface INotificationsBox {
  containerId?: string
}

/**
 * Renders the NotificationsBox component.
 *
 * @param {string} [containerId]
 *
 * @example
 * <NotificationsBox />
 *
 * // Then, use in the anywhere within the app:
 * import { toast } from 'react-toastify'
 *
 * toast.error(apiError)
 */

export const NotificationsBox: React.FunctionComponent<INotificationsBox> = ({
  containerId,
}: INotificationsBox) => {
  return (
    <ToastContainer
      containerId={containerId}
      position='top-right'
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}
