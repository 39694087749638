import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

export const OCCUPATIONS = [
  { value: 'full_time', label: 'Full-time job (30+ hours)' },
  { value: 'part_time', label: 'Part-time job (< 30 hours)' },
  { value: 'freelancer', label: 'Freelancer' },
  { value: 'student', label: 'Student' },
  { value: 'unemployed', label: 'Unemployed' },
]

export const getOccupationLabel = (
  value: string,
  tI18n?: TFunction,
): string => {
  const t = tI18n || useTranslation('app').t
  const found = OCCUPATIONS.find(x => x.value === value)
  return found ? t(`survey.occupation_options.${found.value}`) : value
}

export const getOccupationOptions = (
  tI18n?: TFunction,
): { value: string; label: string }[] => {
  const t = tI18n || useTranslation('app').t
  return OCCUPATIONS.map(occ => {
    return {
      value: occ.value,
      label: t(`survey.occupation_options.${occ.value}`),
    }
  })
}
