import { Fragment } from 'react'
import { Listbox } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'

const CurrencySelect = ({
  currencyOptions,
  changeCurrency,
  currency,
}: {
  currencyOptions: string[]
  changeCurrency: (currency) => void
  currency: string
}): JSX.Element => {
  //   const [selectedCurrency, setSelectedCurrency] = useState(currencyOptions[0])

  return (
    <Listbox value={currency} onChange={changeCurrency} as={Fragment}>
      <div className='relative'>
        <Listbox.Button as={Fragment}>
          <div className='border rounded-md border-blue-600 hover:border-blue-500 focus:border-blue-500 bg-white px-3 py-1 flex'>
            <div className='flex-1'>
              <label
                htmlFor='currency-select'
                className='block text-xs text-gray-100 font-medium opacity-80'
              >
                Currency
              </label>
              <div className='text-base font-medium'>{currency}</div>
            </div>
            <div className='flex justify-center items-center'>
              <ChevronDownIcon className='h-6 w-6 text-blue-500' />
            </div>
          </div>
        </Listbox.Button>
        <Listbox.Options as={Fragment}>
          <div className='absolute top-50 right-0 min-w-32 rounded-md border focus:border-white bg-white transform translate-y-2 filter drop-shadow-lg'>
            <div className='rounded-md overflow-hidden'>
              {currencyOptions.map(currency => {
                return (
                  <Listbox.Option key={currency} value={currency} as={Fragment}>
                    {({ active, selected, disabled }) => (
                      <li
                        className={`flex items-center p-2 ${
                          active
                            ? 'bg-blue-500 text-white'
                            : 'bg-white text-black'
                        }`}
                        unselectable={disabled ? 'on' : 'off'}
                      >
                        <span className='flex-1'>{currency}</span>
                        {selected && (
                          <CheckIcon className='h-5 w-5 text-green-400' />
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                )
              })}
            </div>
            <div
              data-popper-arrow
              className='absolute top-0 right-0 -z-1 h-3 w-3 bg-white transform rotate-45 -translate-y-1 -translate-x-2'
            />
          </div>
        </Listbox.Options>
      </div>
    </Listbox>
  )
}

export default CurrencySelect
