declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    analytics: any
  }
}

import { User } from '../models'
import {
  MixPanelClient,
  CustomerIOClient,
  MockedDestination,
} from 'src/integrations'
import { isTrackingEnabled } from 'src/config/segment'
// TODO: handle error so that one failing destination doesn't stop the others
class AnalyticServiceController {
  destinations: any[]

  constructor(destinations = []) {
    this.destinations = destinations
  }

  track(name: string, props: any = null): void {
    this.destinations.forEach(destination => {
      try {
        destination.track(name, props)
      } catch (error) {
        console.error(error)
      }
    })
  }

  identify(user: User, props = {}): void {
    this.destinations.forEach(destination => {
      try {
        destination.identify(user, props)
      } catch (error) {
        console.error(error)
      }
    })
  }

  reset(): void {
    this.destinations.forEach(destination => {
      try {
        destination.reset()
      } catch (error) {
        console.error(error)
      }
    })
  }

  page(): void {
    this.destinations.forEach(destination => {
      try {
        destination.page()
      } catch (error) {
        console.error(error)
      }
    })
  }
}

const buildUserProperties = (user: User): any => {
  return {
    user_id: user.id,
    email: user.email,
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    created_at: user.created_at,
    country_of_residence: user.country_of_residence || '',
    city: user.city || '',
    gender: user.gender || '',
    languages: user.languages || [],
    consents_accepted_at: user.consents_accepted_at || '',
    consents_accepted_ver: user.consents_accepted_ver || '',
    date_of_birth: user.date_of_birth || '',
    province: user.province || '',
    university: user.university || '',
    occupation: user.occupation || '',
    finished_education: user.finished_education || '',
    tips_notifications: user.tips_notifications,
    obligatory_notifications: user.obligatory_notifications,
    last_access: user.last_access,
    filled_surveys: user.filled_surveys,
    onboarding_visited: user.onboarding_visited,
    segment_type: user.segment_type,
    credits_balance_amount:
      user.credits_balance && user.credits_balance.amount
        ? user.credits_balance.amount
        : 0,
    credits_balance_amount_needed:
      user.credits_balance && user.credits_balance.amount_needed
        ? user.credits_balance.amount_needed
        : 0,
    credits_balance_amount_reserved:
      user.credits_balance && user.credits_balance.amount_reserved
        ? user.credits_balance.amount_reserved
        : 0,
  }
}

let destinations = [new MockedDestination()]
if (isTrackingEnabled) {
  destinations = [new MixPanelClient(), new CustomerIOClient()]
}

const analyticServiceController = new AnalyticServiceController(destinations)

class AnalyticsSegmentService {
  /**
   * Send Identity action to the Segment API with the current logged in user.
   * @param {User} user - the logged in user
   */

  static identify(user: User, props: any = {}): void {
    try {
      analyticServiceController.identify(user, {
        ...buildUserProperties(user),
        ...props,
      })
    } catch (e) {
      console.error(e)
    }
  }
  /**
   * Reset the Segment's data about the user.
   */
  static reset(): void {
    try {
      analyticServiceController.reset()
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Send the Segment track action.
   * @param {string} name - the action name
   * @param {any} props - the action properties
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  static track(name: string, props: any = null): void {
    try {
      analyticServiceController.track(name, props)
    } catch (e) {
      console.error(e)
    }
  }

  static page() {
    analyticServiceController.page()
  }
}

export default AnalyticsSegmentService
