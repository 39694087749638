import { languagesISO } from '../config/languages'
import { requiredFields } from '../config/surveys'
import { IAddSurveyData } from '../containers/AddSurveyContainer/AddSurveyContainer'
import { TSurveyDifficulty } from '../models'
import config from '../config/app'

export function checkAdditionalQuestion(): boolean {
  const check =
    localStorage.getItem('profile_questions') &&
    JSON.parse(`${localStorage.getItem('profile_questions')}`)
  if (check && check.status === 'ADDITIONAL_QUESTIONS_AVAILABLE') {
    return true
  } else {
    return false
  }
}

export function isSurveyLinkValid(surveyLink: string): boolean {
  const surveyLinkRegex = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,9}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/

  return (
    surveyLink &&
    (surveyLinkRegex.test(surveyLink) || surveyLink.startsWith(config.WEB_URL))
  )
}

export function checkRequiredFieldsInSteps(
  data: IAddSurveyData,
  step: number,
  copy: { copy_code?: boolean } = {},
  difficultyToReach?: TSurveyDifficulty,
): boolean {
  if (difficultyToReach === 'very_difficult' && step === 3) {
    return true
  }

  const dataValues = Object.values(data)
  let check = false

  if (step < 5 || step > 6) {
    const keys = Object.keys(data)
    const requiredCheck: boolean[] = []
    requiredFields[step].map((field: { field: string; default: unknown }) => {
      if (field.field === 'none') {
        return requiredCheck.push(true)
      }
      if (Array.isArray(field.default)) {
        if (
          field.field === 'languages' &&
          data.languages &&
          data.languages.length > 0
        ) {
          field.default.map(el => {
            return languagesISO.map(lang => {
              if (el === lang.code) {
                return requiredCheck.push(true)
              }
              return requiredCheck.push(false)
            })
          })
        } else {
          return requiredCheck.push(false)
        }
      } else {
        if (
          keys.findIndex(el => el === field.field) >= 0 &&
          field.default !==
            dataValues[keys.findIndex(el => el === field.field)] &&
          dataValues[keys.findIndex(el => el === field.field)]
        ) {
          return requiredCheck.push(true)
        } else {
          return requiredCheck.push(false)
        }
      }
      return requiredCheck
    })
    check = requiredCheck.every(el => el === true)
  } else {
    if (step === 5 && copy.copy_code) {
      check = copy.copy_code
    }
  }
  if (check) return false
  return true
}

export const setSurveysCountToLocalStorage = user => {
  localStorage.setItem('surveysCount', (user.surveys_count || 0).toString())
  localStorage.setItem(
    'surveySchemasCount',
    (user.survey_schemas_count || 0).toString(),
  )
}

export const surveysCountFromLocalStorage = () => {
  const surveysCount = parseInt(localStorage.getItem('surveysCount') || '0')
  const surveySchemasCount = parseInt(
    localStorage.getItem('surveySchemasCount') || '0',
  )

  return { surveysCount, surveySchemasCount }
}

export const setFirstExchangeSurveyParam = () => {
  const { surveysCount } = surveysCountFromLocalStorage()

  if (surveysCount === 0) {
    localStorage.setItem('firstExchangeSurvey', 'true')
  }
}

export const setfirstSurveyBuilderSurveyParam = () => {
  const { surveySchemasCount } = surveysCountFromLocalStorage()

  if (surveySchemasCount === 0) {
    localStorage.setItem('firstSurveyBuilderSurvey', 'true')
  }
}
