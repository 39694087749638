/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Notification,
  TNotificationType,
  NotificationTypeEnum,
} from '../../../../models/Notification'
import { Link } from '../../Link'
import { NotificationYellowIcon } from '../../Icons/notification-icon-yellow'
import { getRelativeTime } from '../../../../helpers/datetimeHelpers'

// Notification List Item
interface INotificationListItemProps {
  notification: Notification
}

const NotificationListItem: React.FC<INotificationListItemProps> = ({
  notification,
}) => {
  const { t } = useTranslation('app')
  const responseType: Record<TNotificationType, string> = {
    [NotificationTypeEnum.FirstSurveyResponse]: t(
      'notification.survey_response.first_response.title',
    ),
    [NotificationTypeEnum.SurveyResponse]: t(
      'notification.survey_response.new_response.title',
    ),
  }

  const isUnread = () => {
    return !notification.read_at
  }

  return (
    <div className={`flex px-3 py-3 ${isUnread && 'bg-blue-700'}`}>
      <div className='rounded-full h-10 w-10 bg-white shadow-md flex items-center justify-center'>
        <NotificationYellowIcon />
      </div>
      <div className='flex-1 px-3'>
        <div className='font-semibold text-sm'>
          {responseType[notification.type]}
        </div>
        <div className='py-1 text-xs text-gray-100 font-medium opacity-80'>
          {getRelativeTime(notification.created_at)}
        </div>
        <Link
          href='/dashboard?show=earning_history&view=respondents+%26+credits'
          style=''
          className='text-sm'
        >
          {t('SEE_OVERVIEW')}
        </Link>
      </div>
      <div
        className={`rounded-full h-2 w-2 bg-red-600 self-center ${
          isUnread() ? 'block' : 'hidden'
        }`}
      />
    </div>
  )
}

export default NotificationListItem
