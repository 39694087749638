import { ReactNode, forwardRef } from 'react'

/**
 * @typedef IInputProps
 * @props {string} id - the ID's of input element
 * @props {string} [label] - the label text
 * @props {string} [className] - the CSS classes prefix -label and -input
 * @props {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange - onChange handler
 * @props {string} [placeholder] - the placeholder text for input element
 * @props {string} value - value for input
 * @props {string} typeInput - the HTML input type
 * @props {string} [labelStyles] - the CSS classes for label
 * @props {string} [inputStyles] - the CSS classes for input
 * @props {boolean} [required] - if field is required
 * @props {number} [max] - max value of number input
 * @props {number} [min] - min value of number input
 */

export interface IInputProps {
  id: string
  label?: ReactNode
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  value?: string
  defaultValue?: string
  typeInput: string
  labelStyles?: string
  inputStyles?: string
  pattern?: string
  required?: boolean
  invalid?: boolean
  error?: string
  min?: number
  max?: number
  disabled?: boolean
  validBorderColor?: string
  invalidBorderColor?: string
}

export enum InputStyle {
  INPUT = 'outline-none text-base font-medium h-12 w-full border border-blue-600 text-black rounded-md mt-3 mb-2 placeholder-gray-50 py-5 px-4 hover:border-blue-500 focus:ring-2 focus:ring-blue-500',
  INVALID_INPUT = 'outline-none text-base font-medium h-12 w-full border border-red-500 text-black rounded-md mt-3 placeholder-gray-50 py-5 px-4 hover:border-blue-500 focus:ring-2 focus:ring-blue-500',
  LABEL = 'text-black hover:text-blue-500 outline-none',
}

/**
 * Renders the Label with Input component.
 *
 * @param {string} id - the ID's of input element
 * @param {string} [label] - the label text
 * @param {string} [labelStyles] - the CSS classes for label
 * @param {string} [inputStyles] - the CSS classes for input
 * @param {e => handleChange(e.target.value)} onChange - onChange handler
 * @param {string} [placeholder] - the placeholder text for input element
 * @param {string} [value] - value for input
 * @param {string} [defaultValue] - default value for input
 * @param {string} typeInput - the HTML input type
 * @param {string} [pattern] - input pattern
 * @param {boolean} [required] - if field is required
 * @param {number} [max] - max value of number input
 * @param {number} [min] - min value of number input
 *
 * @example
 * <Input
 *  id='username'
 *  onChange={e => handleChange(e.target.value)}
 *  placeholder='user'
 *  label='username'
 *  value={value}
 *  typeInput='text'
 * />
 */
export const Input = forwardRef<HTMLInputElement, IInputProps>(function input(
  {
    id,
    label = '',
    labelStyles = 'text-black hover:text-blue-500 outline-none',
    inputStyles = 'outline-none text-base font-medium h-12 w-full border text-black rounded-md mt-3 placeholder-gray-50 py-5 px-4 hover:border-blue-500 focus:ring-2 focus:ring-blue-500',
    onChange,
    placeholder = id,
    value,
    typeInput,
    pattern,
    required = false,
    invalid,
    error,
    min,
    max,
    disabled = false,
    validBorderColor = 'border-blue-600',
    invalidBorderColor = 'border-red-500',
  }: IInputProps,
  ref,
) {
  const classNames = require('classnames')
  const LabelClass = classNames(labelStyles)
  const InputClass = classNames(inputStyles, {
    [invalidBorderColor]: invalid,
    [validBorderColor]: !invalid,
  })
  const ErrorStyle = 'font-normal text-red-500 mt-2'

  return (
    <label htmlFor={id} className={LabelClass}>
      {label && (
        <p className='pt-5 mt-3'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
      )}
      <input
        aria-label={id}
        ref={ref}
        id={id}
        name={id}
        min={min}
        max={max}
        minLength={min}
        maxLength={max}
        type={typeInput}
        onChange={onChange}
        className={InputClass}
        placeholder={placeholder}
        value={value || value === '' ? value : undefined}
        pattern={pattern}
        disabled={disabled}
        required={required}
      />
      {invalid && error && <p className={ErrorStyle}>{error}</p>}
    </label>
  )
})
