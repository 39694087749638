import env from './env'

const prod = {
  API_URL: 'https://surveyswap.io/api/v1',
  V2_API_URL: 'https://surveyswap.io/api/v2',
  WEB_URL: 'https://surveyswap.io',
  WEBSOCKET_URL: 'wss://surveyswap.io/cable',
}

const stg = {
  API_URL: 'https://surveyswap-staging.lindenburgconsultancy.nl/api/v1',
  V2_API_URL: 'https://surveyswap-staging.lindenburgconsultancy.nl/api/v2',
  WEB_URL: 'https://surveyswap-staging.lindenburgconsultancy.nl',
  WEBSOCKET_URL: 'wss://surveyswap-staging.lindenburgconsultancy.nl/cable',
}

const dev = {
  API_URL: 'http://127.0.0.1:4001/api/v1',
  V2_API_URL: 'http://127.0.0.1:4001/api/v2',
  WEB_URL: 'http://localhost:3000',
  WEBSOCKET_URL: 'ws://127.0.0.1:4001/cable',
}

let config = dev
if (env === 'production') {
  config = prod
} else if (env === 'staging') {
  config = stg
}

export default {
  ...config,
}
