import React, { useEffect } from 'react'

const RedirectComponent = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)

    let token = ''
    if (urlParams.has('token')) {
      token = urlParams.get('token')
    } else if (urlParams.has('utm_source')) {
      token = urlParams.get('utm_source')
    }

    const utmCampaign = urlParams.get('utm_campaign') || ''

    const redirectUrl = `https://notch.insights.supply/cb?token=${token}&RID=${utmCampaign}`

    window.location.href = redirectUrl
  }, [])

  return (
    <div className='flex items-center justify-center h-screen'>
      <p className='text-xl font-semibold'>
        Redirecting you to finalise the survey entry...
      </p>
    </div>
  )
}

export default RedirectComponent
