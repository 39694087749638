import UserService from '../../services/UserService'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../App'
import { Button, ButtonStyle } from '../UI/Button'

/**
 * Renders the banner informing that user account has been blocked.
 *
 * @example
 * <AccountBlockedBanner />
 */

export const AccountBlockedBanner: React.FunctionComponent = () => {
  const { t } = useTranslation('app')
  const { currentUser } = useContext(CurrentUserContext)

  if (currentUser && currentUser.status === 'blocked') {
    return (
      <div className='fixed top-0 left-0 w-screen bg-red-500 p-3 text-white shadow-md flex items-center justify-center z-50'>
        <p>{t('USER_ACCOUNT_BLOCKED_MESSAGE')}</p>
        <Button
          style={ButtonStyle.WHITE}
          className='text-xs p-2 ml-4 whitespace-nowrap'
          onClick={() => UserService.signOut()}
        >
          {t('LOG_OUT')}
        </Button>
      </div>
    )
  }
  return null
}
