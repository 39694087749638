/**
 * @typedef ICardProps
 *
 * @props {React.ReactNode} [children] - the Card children.
 * @props {React.ReactNode} [primaryComponent] - the Card primary component.
 * @props {React.ReactNode} [secondaryComponent] - the Card secondary component.
 * @props {string} [className] - the CSS classes.
 * @props {'row' | 'column'} [direction] - the Card direction.
 * @props {string} [primaryStyle] - the CSS primary classes.
 * @props {string} [secondaryStyle] - the CSS secondary classes.
 * @props {string} [src] - the image source.
 * @props {string} [styles] - the CSS styles.
 * @props {string} [childrenStyles] - the CSS classes of children.
 */

import classNames from 'classnames'

export interface ICardProps {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  height?: string
  primaryComponent?: React.ReactNode
  primaryStyle?: string
  secondaryComponent?: React.ReactNode
  secondaryStyle?: string
  src?: string
  direction?: 'row' | 'column'
  styles?: string
  childrenStyles?: string
  videoProps?: {
    allow?: string
    allowFullScreen?: boolean
  }
}

export enum CardStyle {
  WHITE = 'bg-white border border-gray-600 text-sm',
  GRAY = 'bg-gray-700 border border-gray-600 text-sm',
  GREEN = 'bg-green-700 border border-green-600 text-sm',
  HOVERED = 'border border-green-600 text-sm transform scale-105',
  BLUE = 'bg-blue-700 border border-blue-600 text-sm',
  ORANGE = 'bg-orange-700 border border-orange-600 text-sm',
}

/**
 * Renders the Card component.
 *
 * @param {React.ReactNode} [children] - the Card children.
 * @param {React.ReactNode} [primaryComponent] - the Card primary component.
 * @param {React.ReactNode} [secondaryComponent] - the Card secondary component.
 * @param {string} [className] - the CSS classes.
 * @param {'row' | 'column'} [direction] - the Card direction.
 * @param {string} [primaryStyle] - the CSS primary classes.
 * @param {string} [secondaryStyle] - the CSS secondary classes.
 * @param {string} [src] - the image source.
 * @param {string} [styles] - the CSS styles.
 * @param {string} [childrenStyles] - the CSS classes of children.
 *
 * @example
 * <Card
 *  className=''
 * >
 *  { children }
 * </Card
 */
export const Card: React.FunctionComponent<ICardProps> = ({
  className = 'rounded-xl',
  styles = 'max-w-sm bg-white border border-gray-600 text-sm',
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  height,
}: ICardProps) => {
  const CardStyle = classNames(className, styles)
  return (
    <div
      className={CardStyle}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ height }}
    >
      {children}
    </div>
  )
}

export const ImageCard: React.FunctionComponent<ICardProps> = ({
  className = 'rounded-xl',
  styles = 'max-w-sm bg-white border border-gray-600 text-sm',
  children,
  childrenStyles = 'p-4',
  src,
}: ICardProps) => {
  const CardStyle = classNames(className, styles)
  const ChildrenStyle = classNames('w-full rounded-b-xl', childrenStyles)
  return (
    <div className={CardStyle}>
      <div className='w-full rounded-t-xl'>
        <img src={src} alt='image-card' />
      </div>
      {children && <div className={ChildrenStyle}>{children}</div>}
    </div>
  )
}

export const VideoCard: React.FunctionComponent<ICardProps> = ({
  className = 'rounded-xl',
  styles = 'max-w-sm bg-white border border-gray-600 text-sm',
  children,
  childrenStyles = 'p-4',
  src,
  videoProps,
}: ICardProps) => {
  const CardStyle = classNames(className, styles)
  const ChildrenStyle = classNames('w-full rounded-b-xl', childrenStyles)
  return (
    <div className={CardStyle}>
      <div className='w-full rounded-t-xl'>
        <iframe src={src} {...videoProps} className='w-full' />
      </div>
      <div className={ChildrenStyle}>{children}</div>
    </div>
  )
}

export const DualCard: React.FunctionComponent<ICardProps> = ({
  className = 'max-w-sm',
  primaryStyle = 'max-w-sm bg-gray-700 border border-gray-600 text-sm',
  primaryComponent,
  secondaryStyle = 'max-w-sm bg-blue-700 border border-blue-600 text-sm',
  secondaryComponent,
  direction = 'column',
}: ICardProps) => {
  const FlexCardStyle = classNames(className, 'rounded-md', {
    ['flex items-center justify-center']: direction === 'row',
    ['flex flex-col items-center justify-center']: direction === 'column',
  })
  const CardPrimaryStyle = classNames(primaryStyle, 'w-full h-2/4 p-3', {
    ['rounded-t-xl']: direction === 'column',
    ['rounded-l-xl']: direction === 'row',
  })
  const CardSecondaryStyle = classNames(secondaryStyle, 'w-full h-2/4 p-3', {
    ['rounded-b-xl']: direction === 'column',
    ['rounded-r-xl']: direction === 'row',
  })
  return (
    <div className={FlexCardStyle}>
      <div className={CardPrimaryStyle}>{primaryComponent}</div>
      <div className={CardSecondaryStyle}>{secondaryComponent}</div>
    </div>
  )
}
