// import { TSurveyFlagReasons } from './Survey'
// import { APIError } from './APIError'
// import { User } from './User'
// import appConfig from '../config/app'

/**
 * The Survey interface
 */
export interface ISurveyFlag {
  id?: string
  reason?: string
  reason_type?: string
  issue_value?: string
  created_at?: Date
  updated_at?: Date
}
/**
 * The Survey model.
 */
export class SurveyFlag {
  id?: string
  reason?: string
  reason_type?: string
  issue_value?: string
  created_at?: Date
  updated_at?: Date

  constructor(props: ISurveyFlag) {
    this.assignProps(props)
  }

  assignProps(props: ISurveyFlag): void {
    this.id = props.id
    this.reason = props.reason
    this.reason_type = props.reason_type
    this.issue_value = props.issue_value
  }

  reasonTypesAsSentence(): string {
    switch (this.reason_type) {
      case 'other': {
        return 'the survey had issues'
      }
      case 'missing_code': {
        return 'the survey did not have a completion code'
      }
      case 'offline': {
        return 'the survey seems to be offline'
      }
      case 'wrong_language': {
        return 'the language was not as speficied'
      }
      case 'took_longer': {
        return 'the survey took longer than was specified'
      }
      case 'low_quality': {
        return 'the survey quality was too low'
      }
      default: {
        return 'the survey had issues'
      }
    }
  }
}
