/**
 * Survey Question interface
 */
export interface ISurveyQuestion {
  id?: string
  name?: string
  value?: string
  cost?: number
  field_type?: string
  as_predefined_question_id?: string
  as_predefined_question_type?: string
  _destroy?: boolean
}

/**
 * Survey Question model.
 * The custom/additional questions added by the user to the survey.
 */
export class SurveyQuestion {
  id?: string
  name?: string
  value?: string
  cost?: number
  field_type?: string
  as_predefined_question_id?: string
  as_predefined_question_type?: string
  _destroy?: boolean

  constructor(props: ISurveyQuestion) {
    this.id = props.id
    this.name = props.name
    this.value = props.value
    this.cost = props.cost
    this.field_type = props.field_type
    this.as_predefined_question_id = props.as_predefined_question_id
    this.as_predefined_question_type = props.as_predefined_question_type
    this._destroy = props._destroy
  }
}
