import classNames from 'classnames'

/**
 * @typedef IGetSection
 * @props {React.ReactNode} [title] - sets title of component
 * @props {'left'|'right'} [titlePosition] - sets component with title on right or left side
 * @props {React.ReactNode} [otherSideComponent] - sets component secondary component on otherside
 * @props {React.ReactNode} [descriptionComponent] - sets description component to component with title
 * @props {string} [className] - the CSS classes
 *
 */
interface IGetSection {
  title?: React.ReactNode
  titlePosition?: 'left' | 'right'
  descriptionComponent?: React.ReactNode
  otherSideComponent?: React.ReactNode
  className?: string
  customLayoutClass?: string
}

/**
 * Renders the section of landing page.
 *
 * @param {React.ReactNode} [title] - sets title of component
 * @param {'left'|'right'} [titlePosition] - sets component with title on right or left side
 * @param {React.ReactNode} [otherSideComponent] - sets component secondary component on otherside
 * @param {React.ReactNode} [descriptionComponent] - sets description component to component with title
 * @param {string} [className] - the CSS classes
 *
 * @example
 *  <GetSection />
 */

export const GetSection = ({
  title,
  titlePosition = 'left',
  descriptionComponent,
  otherSideComponent,
  className = 'flex items-center flex-col',
  customLayoutClass = '',
}: IGetSection): JSX.Element => {
  return (
    <div
      className={classNames(className, {
        ['md:flex-row']: titlePosition === 'left',
        ['md:flex-row-reverse']: titlePosition === 'right',
      })}
    >
      <div
        className={classNames(
          'w-full',
          { ['md:w-2/5']: otherSideComponent },
          customLayoutClass,
        )}
      >
        {title && title}
        {descriptionComponent && descriptionComponent}
      </div>
      {otherSideComponent && otherSideComponent}
    </div>
  )
}

export default GetSection
