/* eslint-disable @typescript-eslint/no-empty-function */

import mixpanel from 'mixpanel-browser'
import env from 'src/config/env'
import { isTrackingEnabled } from 'src/config/segment'

if (isTrackingEnabled) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    debug: env !== 'production',
    track_pageview: true,
    persistence: 'localStorage',
  })
}

export class MixPanelClient {
  mixpanel: any

  constructor() {
    this.mixpanel = mixpanel
  }

  track(name: string, props = {}): void {
    this.mixpanel.track(name, props)
  }

  identify(user: any, props = {}): void {
    this.mixpanel.identify(user.id)
    this.mixpanel.people.set(this.userProperties(props))
  }

  // Page events are called automatically by enabling `track_pageview`
  page(): void {}

  reset(): void {
    this.mixpanel.reset()
  }

  userProperties(props = {}) {
    const { ...newProps } = props

    // https://docs.mixpanel.com/docs/data-structure/user-profiles#reserved-profile-properties
    newProps['$created'] = newProps['created_at']
    newProps['$email'] = newProps['email']
    newProps['$first_name'] = newProps['first_name']
    newProps['$last_name'] = newProps['last_name']

    return newProps
  }
}
