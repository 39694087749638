import {
  APIError,
  ISurvey,
  ISurveyWithStats,
  Survey,
  PredefinedSurveyQuestion,
  IPredefinedSurveyQuestion,
  ISurveyQuestion,
} from '../models'
import { navSteps, surveyErrorType, surveyErrorDetail } from '../config/surveys'
import APIService, { IResourceAPIResponse, IAPIResponse } from './APIService'
import { setUserToActiveIfApplicable } from '../helpers/userHelpers'
import { pick } from 'lodash'

class SurveyService {
  /**
   * Get public version of the survey.
   * @param {string} id
   */
  static async getSurvey(
    id: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.get(`/surveys/${id}`, '', {})
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Creates or updates the survey on API.
   * If 'data.id' is set, it will update the survey.
   * Otherwise, it creates a new survey.
   * @param {Survey} data - the survey data
   */
  static async saveSurvey(
    data: ISurvey,
  ): Promise<Survey | APIError<unknown> | null> {
    const body = {
      survey: { ...data },
    }
    // Remove empty survey questions
    if (body.survey.survey_questions) {
      body.survey.survey_questions = body.survey.survey_questions.filter(
        s => s.name && s.name !== '' && s.value && s.value !== '',
      )
    }

    let response
    if (!data.id) {
      response = await APIService.post('/surveys', '', body)
    } else {
      response = await APIService.put(`/surveys/${data.id}`, '', body)
    }

    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Publishes the survey.
   */
  static async publishSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.post(
      `/surveys/${surveyId}/publish`,
      '',
      {},
    )
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Get surveys suggested to the current user
   * @param {number | null} limit control the number of loaded surveys. Be default, the API returns 5 surveys.
   */
  static async getSuggested(
    limit: number | null = null,
    skipIds?: string[],
    languages?: string[],
  ): Promise<Survey[] | APIError<unknown> | null> {
    // TODO: refactor, this is a bit ugly now
    let path = limit ? `/surveys?limit=${limit}` : '/surveys'
    if (skipIds && skipIds.length > 0) {
      if (limit) {
        skipIds.map(x => {
          path = path + `&skip[]=${x}`
        })
      } else {
        path = path + '?'
        skipIds.map(x => {
          path = path + `&skip[]=${x}`
        })
      }
    }

    const filter = (languages || [])
      .map(language => `filter[languages][]=${language}`)
      .join('&')
    path = path.includes('?')
      ? [path, filter].join('&')
      : [path, filter].join('?')

    const response = await APIService.get(path, '')
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponseArray<Survey>(response.data)
  }

  /**
   * Get current user survyes
   * @param {string} [query]
   */
  static async getMySurveys(
    query?: string,
  ): Promise<ISurveyWithStats[] | APIError<unknown> | null> {
    const response = await APIService.get(`/surveys/my_surveys`, query || '')
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponseArray<ISurveyWithStats>(response.data)
  }

  /**
   * Get started survyes
   * @param {string} [scope]
   */
  static async getStartedSurveys(): Promise<
    ISurveyWithStats[] | APIError<unknown> | null
  > {
    const response = await APIService.get(`/surveys/started_surveys`, '')
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponseArray<ISurveyWithStats>(response.data)
  }

  /**
   * Get suggestions to the survey questions.
   * @param {string} phrase
   */
  static async getSurveyQuestionSuggestions(
    phrase: string,
  ): Promise<PredefinedSurveyQuestion[] | APIError<unknown>> {
    const query = phrase ? `phrase=${phrase}` : ''
    const response = await APIService.get(
      `/surveys/suggest_custom_questions`,
      query,
    )
    if (response instanceof APIError) {
      return response
    }
    if (response.data) {
      return SurveyService.parseAPISurveyQuestionSuggestions(response)
    }
    return []
  }

  /**
   * Get Survey to edit
   * @param {string} surveyId
   */
  static async editSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.get(`/surveys/${surveyId}/edit`, '', {})
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Resume the survey
   * @param {string} surveyId
   */
  static async resumeSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.put(`/surveys/${surveyId}/resume`, '', {})
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Pause the survey
   * @param {string} surveyId
   */
  static async pauseSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.put(`/surveys/${surveyId}/pause`, '', {})
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Track that user takes the survey (when user opens the survey modal).
   * Then he can 'open', 'complete' and 'flag' the survey.
   * @param {string} surveyId
   */
  static async takeSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown>> {
    return APIService.post(`/surveys/${surveyId}/take`, '', {})
  }

  /**
   * Track when user opens the survey (when user opens the survey on the external platform).
   * @param {string} surveyId
   */
  static openSurvey(surveyId: string): Promise<Survey | APIError<unknown>> {
    return APIService.post(`/surveys/${surveyId}/open`, '', {})
  }

  static async completeSurvey(
    code: string,
  ): Promise<Survey | APIError<unknown>> {
    const response = await APIService.post(`/surveys/complete/${code}`, '', {})
    if (response instanceof APIError) {
      return response
    }
    await setUserToActiveIfApplicable()
    return SurveyService.parseAPIResponse(response.data)
  }

  static async completeSurveyOutsidePlatform(
    code: string,
  ): Promise<Survey | APIError<unknown>> {
    const response = await APIService.post(
      `/surveys/complete_outside_platform/${code}`,
      '',
      {},
    )
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  /**
   * Skip the survey and get new one
   * @param {string} surveyId
   */
  static async skipSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.post(`/surveys/${surveyId}/skip`, '', {})
    if (response instanceof APIError) {
      return response
    }
    if (response && response.data) {
      return SurveyService.parseAPIResponse(response.data)
    } else {
      return null
    }
  }

  static async clearFlags(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.put(
      `/surveys/${surveyId}/clear_flags`,
      '',
      {},
    )

    if (response instanceof APIError) {
      return response
    }
    return response.data
  }

  /**
   * Boost the survey.
   * @param {string} surveyId
   * @param {boolean} newStatus
   */
  static async boostSurvey(
    surveyId: string,
    newStatus: boolean,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.post(`/surveys/${surveyId}/boost`, '', {
      boost: newStatus,
    })
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  static parseAPIResponse(response: IResourceAPIResponse): Survey {
    return new Survey(response.attributes)
  }

  static parseAPIResponseArray<T>(response: IResourceAPIResponse[]): T[] {
    return (response.map(r => new Survey(r.attributes)) as unknown) as T[]
  }

  static parseAPISurveyQuestionSuggestions(
    response: IAPIResponse<PredefinedSurveyQuestion[]>,
  ): PredefinedSurveyQuestion[] {
    return response.data.map(r => new PredefinedSurveyQuestion(r))
  }

  /**
   * Get additional questions to the user.
   */
  static async getAdditionalQuestions(): Promise<
    IPredefinedSurveyQuestion[] | APIError<unknown> | []
  > {
    const response = await APIService.get(`/users/additional_questions`)
    if (response instanceof APIError) {
      return response
    }
    if (response.data) {
      return SurveyService.parseAPISurveyQuestionSuggestions(response)
    }
    return []
  }

  /**
   * Save additional questions to the user.
   */
  static async saveAdditionalQuestions(
    data: ISurveyQuestion[],
  ): Promise<unknown> {
    const user_custom_questions = { user_custom_questions: data }
    return await APIService.post(
      `/users/save_additional_questions`,
      '',
      user_custom_questions,
    )
  }

  /**
   * Save that survey was viewed, so we can than automatically complete
   * the survey if user provides the valid code in the `/sr/{code} URL.
   */
  static trackInLocalStorage(surveyId: string): void {
    const stored = localStorage.getItem('trackedSurveys')
    try {
      if (stored) {
        const parsedSurveys = JSON.parse(stored)
        if (parsedSurveys.length > 10) {
          parsedSurveys.shift()
        }
        parsedSurveys.push(surveyId)
        const unique = parsedSurveys.filter(
          (v: string, i: number, a: string[]) => a.indexOf(v) === i,
        )
        localStorage.setItem('trackedSurveys', JSON.stringify(unique))
      } else {
        localStorage.setItem('trackedSurveys', JSON.stringify([surveyId]))
      }
    } catch (e) {
      localStorage.removeItem('trackedSurveys')
      console.error('Could not update tracked')
    }
  }

  static getArrayErrorSurvey(
    step: number | string,
    surveyData: Survey,
    steps: string[] = navSteps,
  ): string[] {
    const whitelistedSteps = steps.map(step => step.toLowerCase())
    const checkValidData = pick(
      surveyData.check_valid_step_data,
      whitelistedSteps,
    )

    switch (step) {
      case 'onfinish': {
        const result = Object.keys(checkValidData).filter(key => {
          const surveyStep = steps.indexOf(key.toUpperCase()) + 1
          if (SurveyService.isErrorStepService(surveyStep, surveyData, steps)) {
            return key
          }
        })
        return result
      }
      case 0:
        return []
      default: {
        if (
          !SurveyService.isErrorStepService(<number>step, surveyData, steps)
        ) {
          return []
        } else {
          return [steps[<number>step - 1].toLowerCase()]
        }
      }
    }
  }

  static isErrorStepService(
    step: number,
    surveyData: Survey,
    steps: string[] = navSteps,
  ): boolean {
    const stepKey = steps[step - 1]
    const errorDetail =
      surveyData.check_valid_step_data[stepKey.toLowerCase()] || {}

    const result = []
    Object.values(surveyErrorType).forEach(error => {
      Object.keys(errorDetail[error] || {}).filter(key => {
        if (
          errorDetail[error][key] != surveyErrorDetail.fixed &&
          errorDetail[error][key] != undefined
        ) {
          result.push(key)
        }
      })
    })
    return result.length > 0
  }

  static async deactivateSurvey(
    surveyId: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.post(
      `/surveys/${surveyId}/deactivations`,
      '',
      {},
    )
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }

  static async getSurveyByCode(
    code: string,
  ): Promise<Survey | APIError<unknown> | null> {
    const response = await APIService.get(`/surveys/by_code/${code}`, '', {})
    if (response instanceof APIError) {
      return response
    }
    return SurveyService.parseAPIResponse(response.data)
  }
}

export default SurveyService
