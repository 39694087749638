import { useTranslation } from 'react-i18next'
import { Button } from '../Button'

/**
 * @typedef IInputProps
 * @props {'vertical' | 'horizontal'} [position] - the ID's of input element
 * @props {string} [label] - the label text
 * @props {(val: 'yes' | 'no' | string) => void} onChange - onChange handle
 * @props {'yes' | 'no' | string} value - value for input
 * @props {string} [activeStyles] - the CSS classes for select input
 * @props {string} [labelStyles] - the CSS classes for label
 * @props {string} [inputStyles] - the CSS classes for input
 */

export interface IYesNoInputProps {
  position?: 'vertical' | 'horizontal'
  label?: string
  onChange: (val: 'yes' | 'no' | string) => void
  value: 'yes' | 'no' | string
  labelStyles?: string
  inputStyles?: string
  activeStyles?: string
}

export enum YesInputStyle {
  ACTIVE = 'outline-none text-sm md:text-base font-medium h-12 w-20 border border-blue-600 bg-blue-700 text-blue-500 rounded-md placeholder-gray-50 py-1 px-2',
  INACTIVE = 'outline-none text-sm md:text-base font-medium h-12 w-20 border border-gray-500 bg-gray-700 text-black rounded-md placeholder-gray-50 py-1 px-2',
  LABEL = 'text-xs font-bold text-black hover:text-blue-500 outline-none',
}

/**
 * Renders the Label with YesNoInput component.
 *
 * @param {'vertical' | 'horizontal'} [position] - the position input element
 * @param {string} [label] - the label text
 * @param {string} [labelStyles] - the CSS classes for label
 * @param {string} [inputStyles] - the CSS classes for input
 * @param {string} [activeStyles] - the CSS classes for select input
 * @param {(val: 'yes' | 'no' | string) => void} onChange - onChange handler
 * @param {'yes' | 'no' | string} value - value for input
 *
 * @example
 * <YesNoInput
 *  onChange={value => handleChange(value)}
 *  value={value}
 * />
 */
export const YesNoInput = ({
  position = 'vertical',
  label = '',
  labelStyles = 'text-xs font-bold text-black hover:text-blue-500 outline-none',
  inputStyles = 'outline-none text-sm md:text-base font-medium h-12 w-20 border border-gray-600 text-black rounded-md placeholder-gray-50 py-1 px-2',
  activeStyles = 'outline-none text-sm md:text-base font-medium h-12 w-20 border border-blue-600 bg-blue-700 text-blue-500 rounded-md placeholder-gray-50 py-1 px-2',
  onChange,
  value = '',
}: IYesNoInputProps): JSX.Element => {
  const { t } = useTranslation('app')
  const classNames = require('classnames')
  const inputClass = classNames(inputStyles)
  const marginClass = classNames('flex items-center flex-nowrap', {
    ['my-5']: position === 'vertical',
    ['my-5 md:my-0']: position === 'horizontal',
  })
  const labelClass = classNames(
    'flex',
    {
      ['flex-col']: position === 'vertical',
      ['flex-col md:flex-row md:items-center md:justify-between']:
        position === 'horizontal',
    },
    labelStyles,
  )
  const activeClass = classNames(activeStyles)
  return (
    <div className='mt-5'>
      <label className={labelClass}>
        {label && label}
        <div className={marginClass}>
          <Button
            type='button'
            onClick={() => onChange('yes')}
            className=''
            style={`${inputClass} rounded-r-none ${
              value === 'yes' && activeClass
            }`}
          >
            {t('YES')}
          </Button>
          <Button
            type='button'
            onClick={() => onChange('no')}
            className=''
            style={`${inputClass} rounded-l-none ${
              value === 'no' && activeClass
            }`}
          >
            {t('NO')}
          </Button>
        </div>
      </label>
    </div>
  )
}
