import './Checkbox.css'
/**
 * @typedef ICheckboxProps
 * @props {string} id - the ID's of input element
 * @props {React.ReactChild} [children] - the label text
 * @props {string} [className] - the CSS classes prefix -label and -input
 * @props {(e: React.ChangeEvent<HTMLCheckboxElement>) => void} onChange - onChange handler
 * @props {string} [placeholder] - the placeholder text for input element
 * @props {boolean} value - value for input
 */

export interface ICheckboxProps {
  id: string
  children: React.ReactChild
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: boolean
  labelStyle?: string
  inputStyle?: string
  textStyle?: string
}

/**
 * Renders the Label with Checkbox component.
 *
 * @param {string} id - the ID's of input element
 * @param {React.ReactChild} [children] - the label text
 * @param {string} [className] - the CSS classes prefix -label and -input
 * @param {e => handleChange(e.target.value)} onChange - onChange handler
 * @param {boolean} value - value for input
 *
 * @example
 * <Checkbox
 *  id='username'
 *  onChange={e => handleChange(e.target.value)}
 *  value={value}
 * >
 *  checkbox text or <p></p>
 * </Checkbox>
 */
export const Checkbox = ({
  id = 'checkbox',
  children,
  onChange,
  value = false,
  inputStyle = '',
  labelStyle = 'mb-3',
  textStyle = '',
}: ICheckboxProps): JSX.Element => {
  return (
    <label htmlFor={id} className={'checkbox-cmp ' + labelStyle}>
      <p className={textStyle}>{children}</p>
      <input
        id={id}
        name={id}
        type='checkbox'
        onChange={onChange}
        checked={value}
      />
      <span className={'checkmark ' + inputStyle} />
    </label>
  )
}
