import i18n from '../I18n'

/**
 * API Error interface
 */
export interface IAPIError<T> {
  code?: string | null
  status?: string | null
  message?: string | null
  data?: T | null
}

/**
 * API Error model.
 */
export class APIError<T> {
  code?: string | null
  status?: string | null
  message?: string | null
  data?: T | null

  constructor(props: IAPIError<T>) {
    this.code = props.code
    this.status = props.status
    this.message = props.message
    this.data = props.data
  }

  /**
   * Returns code translated with i18n or not-translated message.
   * If code and message are empty, it returns translated SOMETHING_WENT_WRONG
   */
  getMessage(): string {
    return this.code
      ? i18n.t(`apiErrors:${this.code}`)
      : this.message || i18n.t('apiErrors:SOMETHING_WENT_WRONG')
  }
}
