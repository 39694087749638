import env from './env'

const prod = {
  ACTIVE_SEGMENT: true, // Send requests to the Segment API
}

const stg = {
  ACTIVE_SEGMENT: true, // Send requests to the Segment API
}

const dev = {
  ACTIVE_SEGMENT: false, // Send requests to the Segment API
}

let config = dev
if (env === 'production') {
  config = prod
} else if (env === 'staging') {
  config = stg
}

export const isTrackingEnabled =
  ['production', 'staging'].includes(env) || config.ACTIVE_SEGMENT

export default {
  ...config,
}
