import { useTranslation } from 'react-i18next'
import { useEffect, ChangeEvent, useState } from 'react'

export const CodeInput = (): JSX.Element => {
  const { t } = useTranslation('app')

  const [code, setCode] = useState<string | undefined>('')

  const onCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('newUserSignUpCode', e.target.value)

    if (e.target.value == '') {
      localStorage.removeItem('newUserSignUpCode')
    }
  }

  useEffect(() => {
    setCode(localStorage.getItem('newUserSignUpCode') || '')
  })

  return (
    <div>
      <label>
        <p className='pt-5 mt-3 text-xs font-medium text-black'>
          {t('DO_YOU_HAVE_CODE_EN')}{' '}
        </p>{' '}
        <input
          className='text-sm h-10 w-full border text-black rounded mt-1 mb-3 p-2 block'
          id='code'
          onChange={event => onCodeChange(event)}
          defaultValue={code}
          placeholder='XXX-XXX-XXX'
        ></input>
      </label>
    </div>
  )
}
