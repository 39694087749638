import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const CanIUseSurveySwapQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('can_i_use_surveyswap.question')}</>
}

export const CanIUseSurveySwapAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>{t('can_i_use_surveyswap.para1')}</p>
      <p className={paragraphBaseStyle}>{t('can_i_use_surveyswap.para2')}</p>
    </div>
  )
}
