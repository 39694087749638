import { useTranslation } from 'react-i18next'

export const HowDoIGetRespondentsQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('how_do_i_get_respondents.question')}</>
}

export const HowDoIGetRespondentsAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <ol className='list-decimal pl-5'>
        <li>{t('how_do_i_get_respondents.list1')}</li>
        <li>{t('how_do_i_get_respondents.list2')}</li>
        <li>{t('how_do_i_get_respondents.list3')}</li>
        <li>{t('how_do_i_get_respondents.list4')}</li>
        <li>{t('how_do_i_get_respondents.list5')}</li>
        <li>{t('how_do_i_get_respondents.list6')}</li>
      </ol>
    </div>
  )
}
