import { Survey } from './Survey'
import { IJSONAPIModel, SurveyResult } from './SurveyResult'

export interface ISurveySchema {
  id?: string
  content?: any
  public_link?: string
  responses_count?: number
  listed_survey?: Survey
  survey_results?: SurveyResult[]
}

export interface SurveySchema extends ISurveySchema {}

export class SurveySchema implements IJSONAPIModel {
  constructor(data?: ISurveySchema) {
    this.id = data?.id
    this.content = data?.content || {}
    this.public_link = data?.public_link
    this.responses_count = data?.responses_count || 0
    this.listed_survey = data?.listed_survey && new Survey(data?.listed_survey)
    this.survey_results = data?.survey_results
      ? data?.survey_results.map(
          survey_result => new SurveyResult(survey_result),
        )
      : []
  }

  get title(): string {
    return this.content['title']
  }

  get type() {
    return 'survey_schemas'
  }

  get attributes() {
    return Object.keys(this)
  }

  get isListed() {
    return !!this.listed_survey?.id && this.listed_survey.isPersisted
  }

  get assignNewListedSurvey() {
    this.listed_survey = new Survey({
      platform: 'SurveySwap',
      title: this.title,
      survey_link: this.public_link,
      status: 'unlisted_survey_schema',
      survey_schema_id: this.id,
    })

    this.listed_survey.survey_schema = this

    return this.listed_survey
  }
}
