import whyUsIcon1 from '../../assets/LandingPage/Home/letters.svg'
import whyUsIcon2 from '../../assets/LandingPage/Home/conversations.svg'
import whyUsIcon3 from '../../assets/LandingPage/Home/votes.svg'
import whyUsIcon4 from '../../assets/LandingPage/Home/peace-sign.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface IWhyUs {
  title: string
  description: string
}

export const WhyUs = ({
  elements,
  title,
}: {
  elements: IWhyUs[]
  title?: React.ReactNode
}): JSX.Element => {
  const icons = [whyUsIcon1, whyUsIcon2, whyUsIcon3, whyUsIcon4]
  return (
    <div className='my-12'>
      {title && title}
      <div className='flex items-start flex-wrap pt-4'>
        {elements.length > 0 &&
          elements.map((el: IWhyUs, idx: number) => {
            return (
              <div
                className='w-full sm:w-1/2 my-6 md:my-0 md:w-1/4 px-2'
                key={`why-us-${idx}`}
              >
                <LazyLoadImage src={icons[idx]} alt={`why-us-icon-${idx}`} />
                <h4 className='text-lg font-semibold my-4'>{el.title}</h4>
                <p className='text-sm font-normal'>{el.description}</p>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default WhyUs
