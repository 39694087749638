import moment from 'moment'

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1m',
    mm: '%d minutes',
    h: '1h',
    hh: '%d hours',
    d: '1d',
    dd: '%d days',
    w: 'a week',
    ww: '%d weeks',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

// TODO: check active locale and use the corresponding relative time settings
// moment.updateLocale('nl', {
//   relativeTime: {
//     future: 'over %s',
//     past: '%s geleden',
//     s: 'een paar seconden',
//     ss: '%d seconden',
//     m: '1m',
//     mm: '%d minuten',
//     h: '1u',
//     hh: '%d uur',
//     d: '1d',
//     dd: '%d dagen',
//     w: 'een week',
//     ww: '%d weken',
//     M: 'een maand',
//     MM: '%d maanden',
//     y: 'een jaar',
//     yy: '%d jaar',
//   },
// })

// moment.updateLocale('de', {
//   relativeTime: {
//     future: 'in %s',
//     past: 'vor %s',
//     s: 'ein paar Sekunden',
//     ss: '%d Sekunden',
//     m: '1m',
//     mm: '%d Minute',
//     h: '1h',
//     hh: '%d Studen',
//     d: '1d',
//     dd: '%d Tage',
//     w: 'eine Woche',
//     ww: '%d Wochen',
//     M: 'eine Monat',
//     MM: '%d Monate',
//     y: 'ein Jahr',
//     yy: '%d Jahre',
//   },
// })

/**
 * Get the time difference between two dates in minutes.
 * @param {Date} date1
 * @param {Date} date2
 */
export const getDiffInMinutes = (date1: Date, date2: Date): number => {
  let diff = (date2.getTime() - date1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

/**
 * Get Relative time
 * @param {Date} date
 * @param {string} defaultValue - display value if catch invalid date
 * @return {string} relative time in string format
 */
export const getRelativeTime = (
  date: Date | string,
  defaultValue = '-',
): string => {
  const d = moment(date)
  return d.isValid() ? d.fromNow() : defaultValue
}
