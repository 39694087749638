import { useTranslation } from 'react-i18next'
import academic4 from '../../assets/LandingPage/Home/4.webp'
import academic5 from '../../assets/LandingPage/Home/5.webp'
import academic6 from '../../assets/LandingPage/Home/6.webp'
import academic8 from '../../assets/LandingPage/Home/8.webp'
import nasa from '../../assets/LandingPage/Home/nasa_logo.webp'
import mit from '../../assets/LandingPage/Home/mit_logo.webp'
import abnamro from '../../assets/LandingPage/Home/abnamro_logo.svg'
import febo from '../../assets/LandingPage/Home/febo_logo.webp'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export const Research = (): JSX.Element => {
  const { t } = useTranslation('app')
  const elements = [
    academic4,
    academic5,
    academic6,
    academic8,
    nasa,
    mit,
    abnamro,
    febo,
  ]
  return (
    <div className='flex flex-col justify-center items-center py-24 px-8 lg:px-0'>
      <p className='font-bold text-2xl pb-12'>{t('RESEARCH_MORE')}</p>
      <div className='flex items-center flex-wrap justify-start'>
        {elements.map((el, idx) => {
          return (
            <div
              key={`academic-` + idx}
              className='h-auto w-1/2 sm:w-1/3 md:w-1/4'
            >
              <LazyLoadImage
                className='px-8 my-8 mx-auto'
                src={el}
                alt={`academic-${idx}`}
              />
            </div>
          )
        })}
      </div>
      <p className='opacity-30 mt-8 text-sm w-full md:text-center'>
        {t('AND_MANY_MORE')}
      </p>
    </div>
  )
}

export default Research
