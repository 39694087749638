/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CurrencySelect from './CurrencySelect'
import CreditPlanCard from './CreditPlanCard'
import { BsChevronDown } from 'react-icons/bs'
import RespondentSlider from './RespondentSlider'
import {
  Survey,
  ISurveyWithStats,
  Plan,
  TCurrencies,
  APIError,
} from '../../../models'

import paymentMethodsImage from '../../../assets/Surveys/payment-methods.webp'
import { AiFillLock } from 'react-icons/ai'
import { InputStyle, Input } from '../../UI/Inputs'
import { Button, ButtonStyle } from '../../UI/Button'
import DiscountCodeService from '../../../services/DiscountCodeService'
import { toast } from 'react-toastify'

export const SecurePayments = (): JSX.Element => {
  const { t } = useTranslation('app')

  return (
    <>
      <div className='flex w-full items-center justify-center mb-6'>
        <AiFillLock></AiFillLock>
        {t('SECURE_PAYMENTS')}{' '}
      </div>
      <div className='flex items-center justify-center w-full mb-10'>
        <img
          src={paymentMethodsImage}
          alt='payment methods'
          className='w-2/5'
        />
      </div>
    </>
  )
}

interface ISelectPlans {
  mySurveys: null | ISurveyWithStats[]
  setSelected: (selected: Plan) => void
  currency: TCurrencies
  fetchPlans: (discountCode?: string) => Promise<void> // Add this line
  setCurrency: (currency: TCurrencies) => void
  discountCode: string | null
  setDiscountCode: (discountCode: string) => void
  buyCredits: (plan: Plan) => void
  error?: string | null
  survey: Survey
  plans: Plan[]
}

const SelectPlans = ({
  setSelected,
  setDiscountCode,
  discountCode,
  currency,
  setCurrency,
  buyCredits,
  survey,
  plans,
  fetchPlans,
}: ISelectPlans): JSX.Element => {
  const currencyOptions = ['USD', 'EUR', 'GBP']

  const [showSlider, setShowSlider] = useState(false)
  // Step 1: Add a new useState hook for tempDiscountCode
  const [tempDiscountCode, setTempDiscountCode] = useState('')

  const sliderRef = useRef(null)

  useEffect(() => {
    showSlider && sliderRef.current.scrollIntoView()
  }, [showSlider])

  const validateAndApplyDiscountCode = async () => {
    console.log('Validating discount code:', tempDiscountCode)
    if (!tempDiscountCode) {
      toast.error('Please enter a promo code')
      return
    }

    const validationResult = await DiscountCodeService.validate(
      tempDiscountCode,
    )
    console.log('Validation result:', validationResult)

    if (validationResult instanceof APIError || !validationResult.valid) {
      toast.error('Invalid or expired promo code')
      setDiscountCode(null)
    } else {
      toast.success('Discount succesfully applied')

      setDiscountCode(tempDiscountCode)
      // Call fetchPlans from the parent component with the valid discount code
      fetchPlans(tempDiscountCode)
    }
  }

  return (
    <div id='choose-package'>
      <div className='mx-auto flex flex-col content-center justify-center'>
        <div className='mx-auto flex flex-col md:flex-row md:space-x-8 mb-6'>
          <div className='w-40 flex flex-wrap mb-4 md:mb-0'>
            <span className='font-bold w-full '>Promo code</span>
            <Input
              inputStyles={InputStyle.INPUT + ' md:text-center'}
              id='survey-duration'
              placeholder='Promo code'
              onChange={e => setTempDiscountCode(e.target.value)}
              value={tempDiscountCode}
              typeInput='text'
              className='flex-grow'
            />
            <Button
              type='button'
              style={ButtonStyle.PRIMARY}
              className='px-5 py-1 flex-shrink-0 align-self-end'
              onClick={() => {
                validateAndApplyDiscountCode() // Call the function here
              }}
            >
              Apply
            </Button>
          </div>

          <div className='w-80'></div>
          <div className='w-80 flex md:flex-row flex-col justify-end z-10'>
            <CurrencySelect
              currencyOptions={currencyOptions}
              changeCurrency={setCurrency}
              currency={currency}
            />{' '}
          </div>
        </div>
        <div className='flex flex-col md:flex-row md:space-x-8 justify-center align-center mb-6 xl:mx-60 lg:40 mx-4'>
          {(plans as Plan[]).map((plan, idx) => {
            return (
              <div
                className='mb-6 md:mb-0 text-center flex justify-center align-center'
                key={idx}
              >
                <CreditPlanCard
                  setSelected={setSelected}
                  buyCredits={buyCredits}
                  variant={'normal'}
                  plan={plan}
                  respondents={plan.respondents}
                  currency={currency}
                />
              </div>
            )
          })}
        </div>
        <div
          className='flex justify-center text-blue-400 w-full mb-10 cursor-pointer'
          onClick={() => setShowSlider(!showSlider)}
          ref={sliderRef}
        >
          Select custom amount of respondents
          <BsChevronDown
            style={{ color: 'blue', marginTop: '4px', marginLeft: '3px' }}
          ></BsChevronDown>
        </div>
        <div className='md:mx-20 lg:mx-40 xl:mx-60 flex flex-col content-center justify-center mb-10'>
          {' '}
          {/* Section title */}
          <div className='flex items-center justify-around flex-col md:flex-row'>
            {showSlider ? (
              <RespondentSlider
                currency={currency}
                setSelected={setSelected}
                buyCredits={buyCredits}
                surveyDuration={survey.duration}
                discountCode={discountCode} // Pass the discount code
              />
            ) : null}
          </div>
        </div>

        <SecurePayments />
      </div>
    </div>
  )
}

export default SelectPlans
