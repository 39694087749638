import { Button, ButtonStyle } from '../Button'
import { Container } from '../ContainerComponent'
import { Link, LinkStyle } from '../Link'
import { Logo } from '../Logo'

interface ISurveySchemaNavbar {
  onClick?: () => void
  ctaText?: string
}

export const SurveySchemaNavbar = ({
  onClick,
  ctaText = 'Save Survey',
}: ISurveySchemaNavbar): JSX.Element => {
  return (
    <nav className='fixed flex items-center justify-between w-full bg-white z-50 transition-all p-4 shadow'>
      <Container className='pr-0 pl-0 flex items-center justify-between w-full mx-auto'>
        <div className='flex items-center'>
          <Link href='/' style={LinkStyle.NONE}>
            <Logo
              signetType='primary'
              logotypeClassName='hidden sm:block'
              className='flex items-center sm:w-36 justify-between p-1 sm:p-2 m-1'
            />
          </Link>
        </div>

        <Button style={ButtonStyle.BLUE} id='save-survey-btn' onClick={onClick}>
          <p className='flex font-medium whitespace-nowrap text-sm'>
            {ctaText}
          </p>
        </Button>
      </Container>
    </nav>
  )
}
