export interface ISocialGroup {
  id?: string
  name?: string
  platform?: string
  languages?: string[]
  group_link?: string
  created_at?: Date
  updated_at?: Date
  profile_avatar?: string
  members_count?: number
}

/**
 * The SocialGroup model.
 */
export class SocialGroup {
  id?: string
  name?: string
  platform?: string
  languages?: string[]
  group_link?: string
  created_at?: Date
  updated_at?: Date
  profile_avatar?: string
  members_count?: number

  constructor(props: ISocialGroup) {
    this.assignProps(props)
  }

  assignProps(props: ISocialGroup): void {
    this.id = props.id
    this.name = props.name
    this.platform = props.platform
    this.languages = props.languages
    this.group_link = props.group_link
    this.created_at = props.created_at
    this.updated_at = props.updated_at
    this.profile_avatar = props.profile_avatar
    this.members_count = props.members_count
  }
}
