/* eslint-disable */

const getQueryParam = (url, param) => {
  // Expects a raw URL

  param = param.replace(/[[]/, '[').replace(/[]]/, ']')

  const regexS = '[?&]' + param + '=([^&#]*)'
  const regex = new RegExp(regexS)
  const results: any = regex.exec(url)

  if (
    results === null ||
    (results &&
      typeof results[1] !== 'string' &&
      results[1] &&
      results[1].length)
  ) {
    return ''
  } else {
    return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
  }
}

const campaignParams = (url: string) => {
  var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(
      ' ',
    ),
    kw = '',
    params = {},
    first_params = {}

  var index

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(url, campaign_keywords[index])

    if (kw.length) {
      params[campaign_keywords[index]] = kw
    }
  }

  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(url, campaign_keywords[index])

    if (kw.length) {
      first_params[campaign_keywords[index]] = kw
    }
  }
  return params
}

export default campaignParams
