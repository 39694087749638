import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

export const EDUCATIONS = [
  { value: 'primary', label: 'Primary education' },
  { value: 'secondary', label: 'Secondary education' },
  { value: 'bachelor', label: 'Bachelor' },
  { value: 'master', label: 'Master' },
  { value: 'doctoral_phd', label: 'Doctoral/PhD' },
]

export const getEducationLabel = (value: string, tI18n?: TFunction): string => {
  const t = tI18n || useTranslation('app').t
  const found = EDUCATIONS.find(x => x.value === value)
  return found ? t(`survey.education_options.${found.value}`) : value
}

export const getEducationOptions = (
  tI18n?: TFunction,
): { value: string; label: string }[] => {
  const t = tI18n || useTranslation('app').t
  return EDUCATIONS.map(edu => {
    return {
      value: edu.value,
      label: t(`survey.education_options.${edu.value}`),
    }
  })
}
