import { IIconProps } from '.'

export interface INotificationBellIconProps extends IIconProps {
  redDot?: boolean
  active?: boolean
}

export const NotificationBellLightIcon = ({
  redDot = false,
  active = false,
  width = '21',
  height = '21',
  viewBox = '0 0 24 24',
  className = '',
}: INotificationBellIconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={className}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.054 16.995L19.7715 15.15C19.5964 14.8992 19.5022 14.6009 19.5015 14.295V9C19.5015 7.01088 18.7113 5.10322 17.3048 3.6967C15.8982 2.29018 13.9906 1.5 12.0015 1.5C10.0123 1.5 8.10468 2.29018 6.69816 3.6967C5.29164 5.10322 4.50146 7.01088 4.50146 9V14.295C4.50074 14.6009 4.40653 14.8992 4.23146 15.15L2.94896 16.995C2.75477 17.2102 2.62708 17.477 2.58131 17.7632C2.53555 18.0494 2.57369 18.3427 2.69111 18.6077C2.80853 18.8727 3.0002 19.098 3.24295 19.2563C3.4857 19.4147 3.76913 19.4993 4.05896 19.5H8.32646C8.4986 20.3477 8.95851 21.1099 9.62827 21.6573C10.298 22.2047 11.1364 22.5038 12.0015 22.5038C12.8665 22.5038 13.7049 22.2047 14.3747 21.6573C15.0444 21.1099 15.5043 20.3477 15.6765 19.5H19.944C20.2338 19.4993 20.5172 19.4147 20.76 19.2563C21.0027 19.098 21.1944 18.8727 21.3118 18.6077C21.4292 18.3427 21.4674 18.0494 21.4216 17.7632C21.3758 17.477 21.2481 17.2102 21.054 16.995V16.995ZM12.0015 21C11.5373 20.9987 11.0848 20.8538 10.7062 20.5853C10.3275 20.3168 10.0412 19.9377 9.88646 19.5H14.1165C13.9617 19.9377 13.6754 20.3168 13.2968 20.5853C12.9181 20.8538 12.4657 20.9987 12.0015 21V21ZM4.05896 18C4.09286 17.9685 4.12306 17.9333 4.14896 17.895L5.46146 16.005C5.81159 15.5034 6.00001 14.9067 6.00146 14.295V9C6.00146 7.4087 6.6336 5.88258 7.75882 4.75736C8.88404 3.63214 10.4102 3 12.0015 3C13.5928 3 15.1189 3.63214 16.2441 4.75736C17.3693 5.88258 18.0015 7.4087 18.0015 9V14.295C18.0029 14.9067 18.1913 15.5034 18.5415 16.005L19.854 17.895C19.8799 17.9333 19.9101 17.9685 19.944 18H4.05896Z'
        fill={active ? '#fff' : '#3F4AF0'}
      />
      {redDot && (
        <circle cx='18' cy='4' r='3.5' fill='#FF3232' stroke='#EAEDFC' />
      )}
    </svg>
  )
}
