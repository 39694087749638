import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const HighQualityDataQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('high_quality_data.question')}</>
}

export const HighQualityDataAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>{t('high_quality_data.para1')}</p>
      <p className={paragraphBaseStyle}>{t('high_quality_data.para2')}</p>
      <p className={paragraphBaseStyle}>{t('high_quality_data.para3')}</p>
      <p className={paragraphBaseStyle}>{t('high_quality_data.para4')}</p>
      <ol className='list-decimal pl-5'>
        <li>{t('ease_of_use.list1')}</li>
        <li>{t('ease_of_use.list2')}</li>
        <li>{t('ease_of_use.list3')}</li>
      </ol>
    </div>
  )
}
