import { useTranslation } from 'react-i18next'
import { Link, LinkStyle } from '../../UI/Link'

export const Consent3 = (): JSX.Element => {
  const { t } = useTranslation('auth')
  return (
    <>
      {t('CONSENT_3_1')}{' '}
      <Link
        href='/terms-and-conditions'
        className='text-blue-500 font-semibold ml-1 mr-1'
        style={LinkStyle.NONE}
      >
        {t('CONSENT_3_2')}
      </Link>{' '}
      {t('CONSENT_3_3')}{' '}
      <Link
        href='/privacy-policy'
        className='text-blue-500 font-semibold ml-1 mr-1'
        style={LinkStyle.NONE}
      >
        {t('CONSENT_3_4')}
      </Link>
    </>
  )
}
