/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Link, LinkStyle } from '../UI/Link'
import { Logo } from '../UI/Logo'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

declare global {
  interface Window {
    FeedbackCompanyWidget: any
  }
}

export const Footer = (): JSX.Element => {
  const { t } = useTranslation('app')
  useEffect(() => {
    if (!document.getElementById('feedback')?.hasChildNodes()) {
      setTimeout(function () {
        const el = document.getElementById('feedback')
        const widget = document.getElementsByClassName('__fbcw__widget')
        if (widget[0] && !el?.hasChildNodes()) {
          el?.appendChild(widget[0].cloneNode(true))
        }
      }, 1500)
    }
  }, [])
  return (
    <LazyLoadComponent>
      <footer className='flex flex-col md:flex-row max-w-screen-lg mx-auto py-12 px-8 lg:px-0'>
        <div className='mr-10'>
          <Logo />
        </div>
        <div id='feedback' className='mr-10 mt-5 md:mt-0'></div>
        <div className='flex-1 flex flex-col sm:flex-row'>
          <div className='w-full xs:w-1/2 flex flex-col p-1 sm:p-2 m-1 my-8 md:my-1'>
            <p className='mb-2 font-semibold'>{t('RESOURCES')}</p>
            <Link className={classNames(LinkStyle.NONE, 'mb-2')} href='/about'>
              {t('ABOUT')}
            </Link>
            <Link
              className={classNames(LinkStyle.NONE, 'mb-2')}
              href='https://blog.surveyswap.io/'
              asPrimitiveLink
            >
              {t('BLOG')}
            </Link>
            Email:
            <a href='mailto: hello@surveyswap.io' className='underline'>
              hello@surveyswap.io
            </a>
          </div>
          <div className='w-full xs:w-1/2 flex flex-col p-1 sm:p-2 m-1'>
            <p className='mb-2 font-semibold'>{t('LEGAL')}</p>
            <Link
              className={classNames(LinkStyle.NONE, 'mb-2')}
              href='/terms-and-conditions'
            >
              {t('TERMS')}
            </Link>
            <Link
              className={classNames(LinkStyle.NONE, 'mb-2')}
              href='/disclaimer'
            >
              {t('DISCLAIMER')}
            </Link>
            <Link
              className={classNames(LinkStyle.NONE, 'mb-2')}
              href='/privacy-policy'
            >
              {t('PRIVACY')}
            </Link>
          </div>
          <div className='w-full xs:w-1/2 flex flex-col p-1 sm:p-2 m-1'>
            <p className='mb-2 font-semibold'>{t('SURVEYSWAP_DETAILS')}</p>
            <p className='mb-2'>SurveySwap B.V.</p>
            <p className='mb-2'>Sumatrakade 1217, 1019RJ, Amsterdam</p>
            <p className='mb-2'>{t('CHAMBER_OF_COMMERCE')}: 82091897</p>
            <p className='mb-2'>Bank: NL16INGB0007659628</p>
          </div>
        </div>
      </footer>
    </LazyLoadComponent>
  )
}
