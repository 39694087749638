import { useTranslation } from 'react-i18next'
import { Modal } from '../../UI/Modal'
import { Button, ButtonStyle } from '../../UI/Button'
import finishLater from '../../../assets/Surveys/finish-later.svg'
import { Container } from '../../UI/ContainerComponent'
import closeIcon from '../../../assets/Dashboard/close.svg'

const FinishLaterModal = ({
  modalOpen = false,
  closeModal,
  onFinishLaterModel,
}: {
  modalOpen: boolean
  closeModal: () => void
  onFinishLaterModel: () => void
}): JSX.Element => {
  const { t } = useTranslation('app')

  return (
    <Modal
      modalWithButton={false}
      modalOpen={modalOpen}
      modalHandler={() => closeModal()}
      shouldCloseOnEsc={false}
      shouldContainCloseButton={false}
      shouldCloseOnOverlayClick={false}
    >
      <Container className='flex justify-end w-full border-b border-gray-700 p-6'>
        <Button
          style={ButtonStyle.NOBORDERS}
          className=''
          type='button'
          onClick={() => {
            closeModal()
          }}
        >
          <img src={closeIcon} alt='close' />
        </Button>
      </Container>
      <div className='flex flex-col items-center mt-8 mb-16'>
        <div className='mb-8'>
          <img className='w-25' src={finishLater} alt='resume-icon' />
        </div>
        <p className='font-semibold text-xl'>{t('finish_later.title')}</p>
        <div className='w-4/5 mt-4 mb-10 text-center text-sm text-base'>
          <span>{t('finish_later.explanation')}</span>
        </div>
        <Button
          style={ButtonStyle.PRIMARY}
          className='px-6 py-2.5 mb-6'
          type='button'
          onClick={() => {
            onFinishLaterModel()
          }}
        >
          <p className='font-semibold text-base'>{t('finish_later.yes')}</p>
        </Button>
        <Button
          style={ButtonStyle.NOBORDERS}
          className=''
          type='button'
          onClick={() => {
            closeModal()
          }}
        >
          <p className='font-semibold text-base'>{t('finish_later.no')}</p>
        </Button>
      </div>
    </Modal>
  )
}

export default FinishLaterModal
