import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'
import { Link, LinkStyle } from '../../UI/Link'

export const WhatIsASpecificAudiencePaidQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('what_is_a_specific_audience_paid.question')}</>
}

export const WhatIsASpecificAudiencePaidAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')

  const rowStyle = 'border-b'
  const leftCellStyle = 'p-2 border-r'
  const rightCellStyle = 'p-2'

  return (
    <div>
      <p className={paragraphBaseStyle}>
        {t('what_is_a_specific_audience_paid.para1')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('what_is_a_specific_audience_paid.para2_1')}{' '}
        <Link
          href='/agency'
          className='text-blue-500 font-semibold'
          style={LinkStyle.NONE}
        >
          {t('what_is_a_specific_audience_paid.para2_2')}
        </Link>{' '}
        {t('what_is_a_specific_audience_paid.para2_3')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('what_is_a_specific_audience_paid.para3')}
      </p>
      <table className='border table-fixed mb-4'>
        <thead>
          <tr className='border-b leading-5'>
            <th className='border-r p-2 w-1/2 bg-gray-800'>
              <span className='block font-semibold mb-2'>
                {t('what_is_a_specific_audience_paid.table.easy_header_1')}
              </span>
              <span className='font-normal'>
                {t('what_is_a_specific_audience_paid.table.easy_header_2')}
              </span>
            </th>
            <th className='p-2 w-1/2 bg-gray-800'>
              <span className='block font-semibold mb-2'>
                {t('what_is_a_specific_audience_paid.table.hard_header_1')}
              </span>
              <span className='font-normal'>
                {t('what_is_a_specific_audience_paid.table.hard_header_2')}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={rowStyle}>
            <td className={leftCellStyle}>
              {t('what_is_a_specific_audience_paid.table.easy_lang')}
            </td>
            <td className={rightCellStyle}>
              {t('what_is_a_specific_audience_paid.table.hard_lang')}
            </td>
          </tr>
          <tr className={rowStyle}>
            <td className={leftCellStyle}>
              {t('what_is_a_specific_audience_paid.table.easy_country')}
            </td>
            <td className={rightCellStyle}>
              {t('what_is_a_specific_audience_paid.table.hard_country')}
            </td>
          </tr>
          <tr className={rowStyle}>
            <td className={leftCellStyle}>
              {t('what_is_a_specific_audience_paid.table.easy_parents')}
            </td>
            <td className={rightCellStyle}>
              {t('what_is_a_specific_audience_paid.table.hard_parents')}
            </td>
          </tr>
          <tr className={rowStyle}>
            <td className={leftCellStyle}>
              {t('what_is_a_specific_audience_paid.table.easy_employed')}
            </td>
            <td className={rightCellStyle}>
              {t('what_is_a_specific_audience_paid.table.hard_employed')}
            </td>
          </tr>
          <tr className={rowStyle}>
            <td className={leftCellStyle}>
              {t('what_is_a_specific_audience_paid.table.easy_anyone')}
            </td>
            <td className={rightCellStyle}>
              {t('what_is_a_specific_audience_paid.table.hard_anyone')}
            </td>
          </tr>
        </tbody>
      </table>
      <p className={paragraphBaseStyle}>
        {t('what_is_a_specific_audience_paid.para4')}
      </p>
    </div>
  )
}
