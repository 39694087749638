import { useContext, useEffect } from 'react'
import { Menu } from '@headlessui/react'
import { useState } from 'react'
import { APIError } from '../../../../models'
import NotificationService from '../../../../services/NotificationService'
import { Dropdown } from '../../Dropdown'
import { NotificationBellLightIcon } from '../../Icons/notification-bell'
import NotificationListItem from './NotificationListItem'
import { useTranslation } from 'react-i18next'
import { CurrentUserContext } from '../../../../App'

export const NotificationsContainer = (): JSX.Element => {
  const { t: tApp } = useTranslation('app')
  const [notifications, setNotifications] = useState([])
  const [shouldMarkedAllRead, setShouldMarkedAllRead] = useState(false)
  const [loading, setLoading] = useState(true)
  const { currentUser } = useContext(CurrentUserContext)

  useEffect(() => {
    fetchNotifications()
  }, [])

  useEffect(() => {
    markAllReadNotification()
  }, [shouldMarkedAllRead])

  const fetchNotifications = async () => {
    if (currentUser) {
      setLoading(true)

      const response = await NotificationService.listNotifications()

      if (response instanceof APIError) {
        // do nothing
      } else {
        setNotifications(response)
      }

      setLoading(false)
    }
  }

  const markAllReadNotification = async () => {
    if (currentUser && shouldMarkedAllRead) {
      NotificationService.markAllRead()
    }
  }

  const readNotifications = notifications.filter(
    notification => notification.read_at,
  )

  const unreadNotifications = notifications.filter(
    notification => !notification.read_at,
  )

  return (
    <Dropdown
      menuStyles='origin-top-right absolute right-0 w-64 lg:w-96 rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none z-10 bg-white overflow-hidden'
      menuButtonStyles={({ dropdownOpen }) =>
        `rounded-md border border-gray-600 my-2 mr-4 px-2 py-2 text-sm text-blue-500 border-blue-600 ${
          dropdownOpen ? 'bg-blue-400' : 'bg-blue-700'
        }`
      }
      button={({ dropdownOpen }) => {
        setShouldMarkedAllRead(dropdownOpen === true)

        return (
          <NotificationBellLightIcon
            active={dropdownOpen}
            redDot={!loading && unreadNotifications.length > 0}
          />
        )
      }}
      openMenu
    >
      {unreadNotifications.length > 0 && (
        <>
          <Menu.Item as='div' className='border-t-0'>
            <div className='px-4 pt-4 pb-2 uppercase text-xs font-semibold text-gray-100'>
              {tApp('NEW')}
            </div>
          </Menu.Item>
          <Menu.Item as='div' className='border-t-0'>
            <div className='max-h-80 overflow-y-scroll'>
              {!loading &&
                unreadNotifications.map(notification => {
                  return (
                    <NotificationListItem
                      key={notification.id}
                      notification={notification}
                    />
                  )
                })}
            </div>
          </Menu.Item>
        </>
      )}
      {/* Section label */}
      <Menu.Item as='div' className='border-t-0'>
        <div className='px-3 py-3 uppercase text-xs font-semibold text-gray-100 pt-5'>
          {tApp('ALL')}
        </div>
      </Menu.Item>
      {/* Section item */}
      <Menu.Item as='div' className='border-t-0'>
        <div className='max-h-80 overflow-y-scroll'>
          {!loading &&
            readNotifications.map(notification => {
              return (
                <NotificationListItem
                  key={notification.id}
                  notification={notification}
                />
              )
            })}
        </div>
      </Menu.Item>
    </Dropdown>
  )
}
