export type TNotificationType =
  | 'FirstSurveyResponseNotification'
  | 'SurveyResponseNotification'

export enum NotificationTypeEnum {
  FirstSurveyResponse = 'FirstSurveyResponseNotification',
  SurveyResponse = 'SurveyResponseNotification',
}

/**
 * Noification interface from api response
 */
export interface INotificationAPIResponse {
  id: number
  type: string
  priority: string
  recipient_id: string
  recipient_type: string
  params: { survey_response: string }
  created_at: string
  updated_at: string
  read_at?: string
}

/**
 * Notification's Survey Response JSON
 */
export interface INotificationSurveyResponseJSON {
  id: string
  user_id: string
  used_code: string
  flagged: boolean
  status: unknown
  survey_id: string
  created_at: string
  updated_at: string
}

/**
 * Notification Params interface
 */
export interface INotificationParams {
  survey_response: INotificationSurveyResponseJSON
}

export interface INotification {
  id: number
  type: string
  priority: string
  recipient_id: string
  recipient_type: string
  params: INotificationParams
  created_at: string
  updated_at: string
  read_at?: string
}

/**
 * Notification model
 */
export class Notification implements INotification {
  id: number
  type: string
  priority: string
  recipient_id: string
  recipient_type: string
  params: INotificationParams | null
  created_at: string
  updated_at: string
  read_at?: string

  constructor(props: INotificationAPIResponse) {
    this.id = props.id
    this.type = props.type
    this.priority = props.priority
    this.recipient_id = props.recipient_id
    this.recipient_type = props.recipient_type
    this.created_at = props.created_at
    this.updated_at = props.updated_at
    this.read_at = props.read_at

    try {
      this.params = {
        survey_response: JSON.parse(props.params.survey_response),
      }
    } catch (_error) {
      this.params = null
    }
  }
}
