/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from 'aws-amplify'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import config from '../config/app'
import { APIError } from '../models/APIError'
import NetworkService from './NetworkService'
import { ICreditsBalance } from '../models'
import i18n from '../I18n'
import UserService from './UserService'

import axios from 'axios'
import { toast } from 'react-toastify'
const CancelToken = axios.CancelToken
const source = CancelToken.source()

/**
 * The interface of serialized data from API.
 */
export interface IResourceAPIResponse {
  id: string
  type: string
  attributes: {
    [key: string]:
      | string
      | string[]
      | number
      | null
      | undefined
      | ICreditsBalance
  }
}

/**
 * The interface of not serialized data from API.
 */
export interface IAPIResponse<T> {
  message: string
  code: string
  data: T
}

class APIService {
  static async get(path: any, query = '', options = {}): Promise<any> {
    try {
      const header = await this._getHeaderToken()
      const opts = Object.assign(options, {
        headers: header,
        cancelToken: source.token,
      })
      const response = await NetworkService.get(
        config.API_URL + path,
        query,
        opts,
      )
      return APIService._parseResponse(response)
    } catch (error) {
      return error
    }
  }

  static async post(
    path: any,
    query: any,
    data: any,
    options = {},
  ): Promise<any> {
    try {
      const header = await this._getHeaderToken()
      const opts = Object.assign(options, {
        headers: header,
        cancelToken: source.token,
      })
      const response = await NetworkService.post(
        config.API_URL + path,
        query,
        data,
        opts,
      )
      return APIService._parseResponse(response)
    } catch (error) {
      return error
    }
  }

  static async put(
    path: any,
    query: any,
    data: any,
    options = {},
  ): Promise<any> {
    try {
      const header = await this._getHeaderToken()
      const opts = Object.assign(options, {
        headers: header,
        cancelToken: source.token,
      })
      const response = await NetworkService.put(
        config.API_URL + path,
        query,
        data,
        opts,
      )
      return APIService._parseResponse(response)
    } catch (error) {
      return error
    }
  }

  static async delete(path: any, query = '', options = {}): Promise<any> {
    try {
      const header = await this._getHeaderToken()
      const opts = Object.assign(options, {
        headers: header,
        cancelToken: source.token,
      })
      const response = await NetworkService.delete(
        config.API_URL + path,
        query,
        opts,
      )
      return APIService._parseResponse(response)
    } catch (error) {
      return error
    }
  }

  static async _getHeaderToken(): Promise<{ Authorization: string } | null> {
    try {
      const session = await this._getCurrentSession()
      if (session) {
        const token = session.getAccessToken()
        return {
          Authorization: token.getJwtToken(),
        }
      }
      return null
    } catch (e) {
      return null
    }
  }

  static async _getCurrentSession(): Promise<CognitoUserSession> {
    try {
      await Auth.currentAuthenticatedUser()
      return await Auth.currentSession()
    } catch (error) {
      return error
    }
  }

  static _parseResponse(response: any) {
    const httpCode = response?.response?.status?.toString()
    if (httpCode === '401') {
      source.cancel('Unauthorized')

      const error = i18n.t(`apiErrors:${httpCode}`)
      if (window.confirm(error)) {
        window.location.replace('/sign-in')
        UserService.signOut()
      }
    }

    if (httpCode === '404') {
      return new APIError({ message: i18n.t('apiErrors:404') })
    }

    if (httpCode >= '500') {
      const serverError = i18n.t('apiErrors:SOMETHING_WENT_WRONG')

      toast.error(serverError)

      throw new APIError({ message: serverError, code: httpCode })
    }

    if (response.data.status === 'ok') {
      return 'data' in response ? response.data : response
    } else {
      return new APIError(response.data)
    }
  }
}

export default APIService
