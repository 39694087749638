/**
 * Credits balance interface
 */
export interface ICreditsBalance {
  amount?: number
  amount_needed?: number
  amount_reserved?: number
}

/**
 * Credits Balance model.
 */
export class CreditsBalance {
  amount?: number
  amount_needed?: number
  amount_reserved?: number

  constructor(props: ICreditsBalance) {
    this.amount = props.amount
    this.amount_needed = props.amount_needed
    this.amount_reserved = props.amount_reserved
  }
}
