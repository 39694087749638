import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Button, ButtonStyle } from '../UI/Button'
import i18n from '../../I18n'
import history from '../../history'
import classNames from 'classnames'

/**
 * Renders the Language dropdown component.
 *
 * @example
 * <LanguageDropdown style={'primary'} />
 */

export const LanguageDropdown = ({
  style = 'primary',
}: {
  style?: 'primary' | 'secondary'
}): JSX.Element => {
  const changeLang = (lang: string) => {
    if (lang !== i18n.language) {
      const pathname = window.location.pathname
        .replace('/de/', '/')
        .replace('/nl/', '/')

      if (lang !== 'en') {
        history.replace(`/${lang}${pathname}`)
      } else {
        history.replace(pathname)
      }
    }
  }

  const dropdownStyle = 'relative inline-block text-left'
  const menuStyles =
    'flex flex-col z-30 origin-top-right absolute right-0 mt-2 w-32 px-3 py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'
  const menuButtonStyles = classNames(
    'inline-flex justify-center w-full rounded-md border px-4 py-2 text-sm font-medium focus:outline-none',
    {
      'bg-gray-700 text-black border-gray-600 focus:ring-1 focus:ring-offset-1 focus:ring-offset-white focus:ring-gray-700 hover:bg-gray-50':
        style === 'primary',
      'bg-blue-400 text-white border-blue-500 focus:ring-1 focus:ring-offset-1 focus:ring-offset-blue-500 focus:ring-blue-500 hover:bg-blue-400':
        style === 'secondary',
    },
  )
  const itemStyles =
    'py-2 focus:outline-none font-medium focus:ring-1 focus:ring-offset-1 focus:ring-offset-white focus:ring-gray-700'

  return (
    <Menu as='div' className={dropdownStyle}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className={menuButtonStyles}>
              {i18n.language.toUpperCase()}
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items static className={menuStyles}>
              <Menu.Item>
                <Button
                  style={ButtonStyle.NOBORDERS}
                  className={itemStyles}
                  onClick={() => changeLang('en')}
                >
                  English
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  style={ButtonStyle.NOBORDERS}
                  className={itemStyles}
                  onClick={() => changeLang('de')}
                >
                  German
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button
                  style={ButtonStyle.NOBORDERS}
                  className={itemStyles}
                  onClick={() => changeLang('nl')}
                >
                  Dutch
                </Button>
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
