import { useEffect, useState } from 'react'
import './RadioInput.css'
/**
 * @typedef IRadioInputProps
 * @props {string} id - the ID's of input element
 * @props {React.ReactChild} [children] - the label text
 * @props {string} [value] - the input value
 * @props {e => handleChange(e.target.value)} onChange - onChange handler
 * @props {boolean} [checked] - if ratio is checked
 * @props {string} [labelStyle] - the CSS for label
 * @props {string} [inputStyle] - the CSS for input
 * @props {string} [textStyle] - the CSS for text
 */

export interface IRadioInputProps {
  id: string
  children: React.ReactChild
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  labelStyle?: string
  inputStyle?: string
  textStyle?: string
  checked?: boolean
}

/**
 * Renders the Label with RadioInput component.
 *
 * @param {string} id - the ID's of input element
 * @param {React.ReactChild} [children] - the label text
 * @param {string} [value] - the input value
 * @param {e => handleChange(e.target.value)} onChange - onChange handler
 * @param {boolean} [checked] - if ratio is checked
 * @param {string} [labelStyle] - the CSS for label
 * @param {string} [inputStyle] - the CSS for input
 * @param {string} [textStyle] - the CSS for text
 *
 * @example
 * <RadioInput
 *  id='username'
 *  onChange={e => handleChange(e.target.value)}
 *  value={value}
 * >
 *  radioinput text or <p></p>
 * </RadioInput>
 */
export const RadioInput = ({
  id = 'radioinput',
  children,
  onChange,
  checked = false,
  value,
  inputStyle = '',
  textStyle = '',
}: IRadioInputProps): JSX.Element => {
  const [check, setCheck] = useState(false)
  useEffect(() => {
    setCheck(checked)
  }, [checked])
  return (
    <label htmlFor={id} className='radioinput-cmp'>
      <p className={textStyle}>{children}</p>
      <input
        id={id}
        name={id}
        type='radio'
        checked={check}
        onChange={e => onChange(e)}
        value={value}
      />
      <span className={'radiomark ' + inputStyle}>
        <span className='radiomark-middle' />
      </span>
    </label>
  )
}
