import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle, subtitleStyle } from '../FrequentlyQuestions'
import { Link, LinkStyle } from '../../UI/Link'

export const IsTheDataOfHighQualityQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('is_the_data_of_high_quality.question')}</>
}

export const IsTheDataOfHighQualityAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para1')}
      </p>
      <h3 className={subtitleStyle}>
        {t('is_the_data_of_high_quality.subtitle1')}
      </h3>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para2')}
      </p>
      <h3 className={subtitleStyle}>
        {t('is_the_data_of_high_quality.subtitle2')}
      </h3>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para3')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para4')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para5')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para6')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para7')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('is_the_data_of_high_quality.para8_1')}{' '}
        <Link
          href='/agency'
          className='text-blue-500 font-semibold'
          style={LinkStyle.NONE}
        >
          {t('is_the_data_of_high_quality.para8_2')}
        </Link>
        .
      </p>
    </div>
  )
}
