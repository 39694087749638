/**
 * Add the locale to the given path based on the current window location pathname.
 * @param {string} path
 *
 * @return {string}
 *
 * @example
 * includeCurrentLocaleToPath('/dashboard') // '/de/dashboard'
 */
export const includeCurrentLocaleToPath = (path: string): string => {
  let pathWithLocale = path
  if (
    window &&
    (window.location.pathname.startsWith('/de/') ||
      window.location.pathname === '/de')
  ) {
    pathWithLocale = `/de${path}`
  }
  if (
    window &&
    (window.location.pathname.startsWith('/nl/') ||
      window.location.pathname === '/nl')
  ) {
    pathWithLocale = `/nl${path}`
  }
  return pathWithLocale
}
