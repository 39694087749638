import { Menu } from '@headlessui/react'
import React from 'react'

export interface IDropdownItem {
  children?: React.ReactNode
  className?: string
}

/**
 * Renders the Dropdown menu component.
 *
 * @param {React.ReactNode} [children] - the Dropdown items.
 *
 * @example
 * <Dropdown>
 *  { children }
 * </Dropdown>
 */
export const DropdownItem: React.FunctionComponent<IDropdownItem> = ({
  children,
}: IDropdownItem) => {
  return <Menu.Item>{() => <>{children}</>}</Menu.Item>
}
