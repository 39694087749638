// This module exports one string, telling you which environment we're in:
//   - development, if running locally
//   - test, if running tests
//   - staging, if running the staging environment
//   - production, if running in production
//
// The reason to not simply use NODE_ENV is because create-react-app does not
// allow any values other than `development, test, production`. We want to have
// a `staging` environment, so we have to do things differently.
//
// We use REACT_APP_ENV to set the environment. However, we also fall back
// to the regular NODE_ENV if it is not set. That way if a developer is not aware
// of this setup, they will usually get the thing they want.
//
// When building for staging, you can use `yarn build-stg`. See package.json.

const { NODE_ENV, REACT_APP_ENV } = process.env

let env = REACT_APP_ENV

if (!env) {
  env = NODE_ENV
}

// Exported this way because it's unusual to export a `let` binding.
export default `${env}`
