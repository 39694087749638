/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

class NetworkService {
  static async get(path: any, query: any, optionsParam: { headers: any }) {
    try {
      const options = {
        response: true,
        queryStringParameters: {},
        ...optionsParam,
      }
      const response = await axios({
        url: query ? `${path}?${query}` : path,
        method: 'get',
        ...options,
      })
      return response
    } catch (error) {
      return error
    }
  }

  static async post(
    path: any,
    query: any,
    data: any,
    optionsParam: { headers: any },
  ) {
    try {
      const options = {
        response: true,
        queryStringParameters: {},
        ...optionsParam,
      }
      const response = await axios({
        url: query ? `${path}?${query}` : path,
        data,
        method: 'post',
        ...options,
      })
      return response
    } catch (error) {
      return error
    }
  }

  static async put(
    path: any,
    query: any,
    data: any,
    optionsParam: { headers: any },
  ) {
    try {
      const options = {
        response: true,
        queryStringParameters: {},
        ...optionsParam,
      }
      const response = await axios({
        url: query ? `${path}?${query}` : path,
        data,
        method: 'put',
        ...options,
      })
      return response
    } catch (error) {
      return error
    }
  }

  static async delete(path: any, query: any, options: { headers: any }) {
    try {
      const response = await axios({
        url: query ? `${path}?${query}` : path,
        method: 'delete',
        ...options,
      })
      return response
    } catch (error) {
      return error
    }
  }
}

export default NetworkService
