import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '../UI/Modal'
import { Button, ButtonStyle } from '../UI/Button'
import history from '../../history'
import { APIError, User, IUser } from '../../models'
import { IServiceConfig } from '../../services/ServiceConfigService'
import UserService from '../../services/UserService'
import { Link } from '../UI/Link'
import icon from '../../assets/Auth/Get started-light-1.svg'
import { CurrentUserContext, ServiceConfigContext } from '../../App'
import { includeCurrentLocaleToPath } from '../../helpers/localeHelpers'

// interface IServiceTermsModal {
//   currentUser: User | null
//   setCurrentUser: (user: User | null) => void
//   serviceConfig: IServiceConfig | null
// }
// {
//   currentUser,
//   setCurrentUser,
//   serviceConfig,
// }: IServiceTermsModal

const ServiceTermsModal = (): JSX.Element => {
  const { serviceConfig } = useContext(ServiceConfigContext)
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const { t } = useTranslation(['app', 'serviceTerms'])
  const [open, setOpen] = useState<'open' | 'close' | null>(null)
  const [mode, setMode] = useState<'new' | 'update'>('new')

  const userOnTermsPage = () => {
    const excludedPaths = [
      'terms-and-conditions',
      'service-terms',
      'disclaimer',
      'privacy-policy',
    ]

    let urlIsExcludedPage = false

    excludedPaths.forEach(excludedPath => {
      if (window.location.pathname.includes(excludedPath)) {
        urlIsExcludedPage = true
      }
    })

    return urlIsExcludedPage
  }

  const shouldOpen = (
    currentUser: IUser | null,
    serviceConfig: IServiceConfig | null,
  ) => {
    if (userOnTermsPage()) {
      return false
    }

    if (currentUser && serviceConfig && 'consents_ver' in serviceConfig) {
      if (!currentUser.consents_accepted_at) {
        setMode('new')
        setOpen('open')
      } else if (
        currentUser.consents_accepted_ver !== serviceConfig.consents_ver
      ) {
        setMode('update')
        setOpen('open')
      } else {
        setOpen('close')
      }
      return true
    }
    return false
  }

  const onAccept = async (setCurrentUser: (user: User | null) => void) => {
    const response = await UserService.acceptTerms()
    if (response instanceof APIError) {
      console.error('Could not accept the service terms.')
    } else {
      setCurrentUser(response)
    }
    setOpen('close')
  }

  const onDecline = async () => {
    await UserService.signOut()
    history.push(includeCurrentLocaleToPath('/sign-in'))
    setOpen('close')
  }
  return (
    <Modal
      modalOpen={
        open !== 'close' &&
        (open === 'open' || shouldOpen(currentUser, serviceConfig))
      }
      modalWithButton={false}
      footer
      footerComponent={
        <div className='w-full flex flex-row items-center justify-between px-9'>
          <Button
            style={ButtonStyle.SECONDARY}
            type='button'
            onClick={onDecline}
          >
            {t('actions.decline')}
          </Button>
          <Button type='button' onClick={() => onAccept(setCurrentUser)}>
            {t('actions.accept')}
          </Button>
        </div>
      }
    >
      <>
        <div className='flex flex-col items-center justify-center mx-auto text-center px-9 py-8'>
          <span className='w-48 h-48 flex items-center justify-center py-8'>
            <img src={icon} alt='Hello' />
          </span>
          <h1 className='mt-5 mb-10'>{t('Service terms')}</h1>
          <p className='mb-8'>
            {mode === 'update' ? (
              <>
                {t('serviceTerms:accept_service_terms_for_the_first_time_1')}{' '}
                <Link href='/terms-and-conditions' asPrimitiveLink>
                  {t('serviceTerms:accept_service_terms_for_the_first_time_2')}
                </Link>{' '}
                {t('serviceTerms:accept_service_terms_for_the_first_time_3')}{' '}
                <Link href='/privacy-policy' asPrimitiveLink>
                  {t('serviceTerms:accept_service_terms_for_the_first_time_4')}
                </Link>
              </>
            ) : (
              <>
                {t('serviceTerms:accept_updated_service_terms_1')}{' '}
                <Link href='/terms-and-conditions' asPrimitiveLink>
                  {t('serviceTerms:accept_updated_service_terms_2')}
                </Link>{' '}
                {t('serviceTerms:accept_updated_service_terms_3')}{' '}
                <Link href='/privacy-policy' asPrimitiveLink>
                  {t('serviceTerms:accept_updated_service_terms_4')}
                </Link>{' '}
                {t('serviceTerms:accept_updated_service_terms_5')}
              </>
            )}
          </p>
        </div>
      </>
    </Modal>
  )
}

export default ServiceTermsModal
