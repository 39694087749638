import { ISurveySchema } from './SurveySchema'

export interface IJSONAPIModel {
  type: string
  attributes: string[]
  relationships?: any
}

export interface ISurveyResult {
  id?: string
  content?: any
  survey_schema?: ISurveySchema
}

export interface SurveyResult extends ISurveyResult {}

export class SurveyResult implements IJSONAPIModel {
  constructor(data?: ISurveyResult) {
    this.id = data?.id
    this.content = data?.content
    this.survey_schema = data?.survey_schema
  }

  get type() {
    return 'survey_results'
  }

  get attributes() {
    return Object.keys(this)
  }

  get relationships() {
    return {
      survey_schema: {
        ref: 'id',
      },
    }
  }
}
