import { useTranslation, TFunction } from 'react-i18next'
import {
  countriesISO,
  supportedCountriesISO,
  languagesISO,
} from '../config/languages'

export const getCountry = (code: string, tI18n?: TFunction<'app'>): string => {
  const t = tI18n || useTranslation('app').t
  let countryName = code
  countriesISO.map(country => {
    if (country.code === code) {
      return (countryName = t(country.name))
    }
    return countryName
  })
  return countryName
}

export const getCountriesOptions = (
  // add `((i18nKey?: string) => string)` as a workaround for reusing outside a component
  //   should be refactor/fix the root cause: hook inside helper
  tI18n?: TFunction<'app'> | ((i18nKey?: string) => string),
): { value: string; label: string }[] => {
  const t = tI18n || useTranslation('app').t
  const data: { value: string; label: string }[] = []
  countriesISO.map(country => {
    return data.push({ value: country.code, label: t(country.name) })
  })
  return data
}

export const getSupportedCountriesOptions = (
  // add `((i18nKey?: string) => string)` as a workaround for reusing outside a component
  //   should be refactor/fix the root cause: hook inside helper
  tI18n?: TFunction<'app'> | ((i18nKey?: string) => string),
): { value: string; label: string }[] => {
  const t = tI18n || useTranslation('app').t
  const data: { value: string; label: string }[] = []
  supportedCountriesISO.map(country => {
    return data.push({ value: country.code, label: t(country.name) })
  })
  return data
}

export const getLanguagesOptions = (
  // add `((i18nKey?: string) => string)` as a workaround for reusing outside a component
  //   should be refactor/fix the root cause: hook inside helper
  tI18n?: TFunction<'app'> | ((i18nKey?: string) => string),
): { value: string; label: string }[] => {
  const t = tI18n || useTranslation('app').t
  const data: { value: string; label: string }[] = []
  languagesISO.map(lang => {
    return data.push({ value: lang.code, label: t(lang.name) })
  })
  return data
}

export const getLanguage = (
  code: string,
  tI18n?: TFunction<'app' | string | string[]>,
): string => {
  const t = tI18n || useTranslation('app').t
  let langVal = code
  languagesISO.map(lang => {
    if (lang.code === code) {
      return (langVal = t(lang.name))
    }
    return langVal
  })
  return langVal
}
