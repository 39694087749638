import TagManager from 'react-gtm-module'
import { APIError, User } from '../models'
import UserService from '../services/UserService'

export async function setUserToActiveIfApplicable(): Promise<
  User | APIError<unknown>
> {
  const response = await UserService.getUserData()

  if (response instanceof User && !response.active) {
    sendToGoogleTagManager()
    const response = await UserService.saveUserData({ active: true })
    if (response instanceof APIError) {
      return response
    }
    return response
  }
}

function sendToGoogleTagManager() {
  const tagManagerArgs = {
    dataLayer: {
      event: 'userTurnedActive',
    },
  }

  TagManager.dataLayer(tagManagerArgs)
}
