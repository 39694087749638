import OnlyNavbarLayout from '../../layouts/OnlyNavbarLayout'
import BuyCreditsContainer from '../../containers/BuyCreditsContainer/BuyCreditsContainer'

/**
 * Renders Buy credits page
 */
const BuyCreditsRoute = (): JSX.Element => {
  return (
    <OnlyNavbarLayout>
      <BuyCreditsContainer />
    </OnlyNavbarLayout>
  )
}

export default BuyCreditsRoute
