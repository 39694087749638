import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const app_en = require('./locales/en/app.json')
const apiErrors_en = require('./locales/en/apiErrors.json')
const credits_en = require('./locales/en/credits.json')
const faq_en = require('./locales/en/faq.json')
const page_404_en = require('./locales/en/404.json')
const routes_en = require('./locales/en/routes.json')
const serviceTerms_en = require('./locales/en/serviceTerms.json')
const auth_en = require('./locales/en/auth.json')
const nav_en = require('./locales/en/nav.json')
const metaTags_en = require('./locales/en/metaTags.json')
const variants_en = require('./locales/en/variants.json')
const partner_en = require('./locales/en/partner.json')

const nav_de = require('./locales/de/nav.json')
const faq_de = require('./locales/de/faq.json')
const app_de = require('./locales/de/app.json')
const metaTags_de = require('./locales/de/metaTags.json')
const variants_de = require('./locales/de/variants.json')
const partner_de = require('./locales/de/partner.json')

const nav_nl = require('./locales/nl/nav.json')
const faq_nl = require('./locales/nl/faq.json')
const app_nl = require('./locales/nl/app.json')
const metaTags_nl = require('./locales/nl/metaTags.json')
const variants_nl = require('./locales/nl/variants.json')
const partner_nl = require('./locales/nl/partner.json')

i18n.use(initReactI18next).init({
  debug: false,
  resources: {
    en: {
      app: app_en,
      apiErrors: apiErrors_en,
      credits: credits_en,
      faq: faq_en,
      auth: auth_en,
      page404: page_404_en,
      routes: routes_en,
      nav: nav_en,
      serviceTerms: serviceTerms_en,
      metaTags: metaTags_en,
      variants: variants_en,
      partner: partner_en,
    },
    de: {
      app: app_de,
      apiErrors: apiErrors_en,
      credits: credits_en,
      faq: faq_de,
      auth: auth_en,
      page404: page_404_en,
      routes: routes_en,
      nav: nav_de,
      serviceTerms: serviceTerms_en,
      metaTags: metaTags_de,
      variants: variants_de,
      partner: partner_de,
    },
    nl: {
      app: app_nl,
      apiErrors: apiErrors_en,
      credits: credits_en,
      faq: faq_nl,
      auth: auth_en,
      page404: page_404_en,
      routes: routes_en,
      nav: nav_nl,
      serviceTerms: serviceTerms_en,
      metaTags: metaTags_nl,
      variants: variants_nl,
      partner: partner_nl,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
