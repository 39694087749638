import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'
import { Link, LinkStyle } from '../../UI/Link'

export const DeliverySpeedQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('delivery_speed.question')}</>
}

export const DeliverySpeedAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>{t('delivery_speed.para1')}</p>
      <p className={paragraphBaseStyle}>{t('delivery_speed.para2')}</p>
      <p className={paragraphBaseStyle}>{t('delivery_speed.para3')}</p>
      <p className={paragraphBaseStyle}>
        {t('delivery_speed.para4_1')}{' '}
        <Link
          href='/agency'
          className='text-blue-500 font-semibold'
          style={LinkStyle.NONE}
        >
          {t('delivery_speed.para4_2')}
        </Link>
        .
      </p>
    </div>
  )
}
