import classNames from 'classnames'

/**
 * @typedef IContainerProps
 * @props {React.ReactNode} children - the container children.
 * @props {string} [className] - the CSS classes.
 */

export interface IContainerProps {
  children: React.ReactNode
  className?: string
}

/**
 * Renders the container component.
 *
 * @param {React.ReactNode} children - the container children.
 * @param {string} [className] - the CSS classes.
 *
 * @example
 * <Container>
 { children }
 </Container>
 */

export const Container: React.FunctionComponent<IContainerProps> = ({
  className = 'w-full mx-auto max-screen-xl',
  children,
}: IContainerProps) => {
  const baseStyle = 'px-5'
  const containerStyle = classNames(baseStyle, { [className]: className })
  return <div className={containerStyle}>{children}</div>
}
