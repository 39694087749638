import { useCallback } from 'react'
import { Model } from 'survey-core'
import { Survey } from 'survey-react-ui'

import 'survey-core/defaultV2.css'
import './profile_survey.css'

import MyProfileSurveyConfig from './my-profile/my-profile-survey'
import { IProfileData } from '../../containers/MyProfileContainer/MyProfileContainer'
import UserService from '../../services/UserService'
import { APIError, IUser } from '../../models'
import { SurveySwapTheme } from 'src/styles/surveyBuilder/surveyswapTheme'

export const saveProfile = async (
  data: IProfileData,
  onSuccess?: () => void,
  onError?: (any) => void,
) => {
  try {
    const response = await UserService.saveUserData(data as IUser)
    if (response instanceof APIError) {
      onError && onError(response)

      console.error(response)

      throw new Error(response.message)
    }

    onSuccess && onSuccess()
  } catch (error) {
    console.error(error)

    throw new Error(error.message)
  }
}

const MyProfileSurveyRoute = (): JSX.Element => {
  const surveyComplete = useCallback((sender, options) => {
    const onSuccess = () => {
      window.localStorage.removeItem('survey_answers_my-profile')

      options.showSaveSuccess('Your profile is updated.')
    }

    options.showSaveInProgress()

    // In some case it still applies height of the survey to ending message
    //   hence user report not seeing survey code
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    window.scrollTo({ top: 0, behavior: 'instant' })

    saveProfile(sender.data, onSuccess)
  }, [])

  const saveSurveyToLocalStorage = survey => {
    const data = survey.data
    data.pageNo = survey.currentPageNo
    window.localStorage.setItem(
      'survey_answers_my-profile',
      JSON.stringify(data),
    )
  }

  const survey = new Model(MyProfileSurveyConfig)
  survey.applyTheme(SurveySwapTheme)

  survey.onComplete.add(surveyComplete)
  survey.onValueChanged.add(saveSurveyToLocalStorage)
  survey.onCurrentPageChanged.add(saveSurveyToLocalStorage)

  return (
    <div className='flex justify-center'>
      <Survey model={survey} />
    </div>
  )
}

export default MyProfileSurveyRoute
