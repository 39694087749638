/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardStyle } from '../../UI/Card'
import classNames from 'classnames'

import './StepCard.css'

export interface IStepCardProps {
  step: number
  currentStep: number
  message: string
  onClick?: () => void
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
}

const StepCard: React.FC<IStepCardProps> = ({
  step,
  currentStep,
  message,
  Icon,
  onClick,
}) => {
  const { t } = useTranslation('app')

  const isCurrent = currentStep === step
  const isActive = step < currentStep
  const isDisabled = step > currentStep

  const cardStyles = classNames(CardStyle.GRAY, 'cursor-pointer', {
    ['pointer-events-none']: isDisabled,
    ['bg-green-700 border border-green-400 text-sm text-green-400']: isCurrent,
    ['bg-blue-700 border border-blue-500 text-sm text-blue-500']: isActive,
    ['text-black']: isDisabled,
  })

  return (
    <Card
      className='rounded-md p-4 flex items-center min-w-32 sm:w-auto md:w-80'
      styles={cardStyles}
      onClick={onClick}
    >
      <Icon
        width={64}
        height={64}
        className={classNames('fill-current', {
          ['opacity-10']: isDisabled,
        })}
      />
      <div className='flex-1 font-medium px-2'>
        <p
          className={classNames('text-2xs leading-4', {
            ['opacity-40']: isDisabled,
          })}
        >
          {t('STEP')} {step}
        </p>
        <p
          className={classNames('font-medium text-base leading-6', {
            ['opacity-40']: isDisabled,
          })}
        >
          {message}
        </p>
      </div>
      <div
        className={classNames('font-sans text-5xl font-bold text-center', {
          ['opacity-10']: isDisabled,
        })}
      >
        {step}
      </div>
    </Card>
  )
}
export default StepCard
