import qualtrics from '../assets/Surveys/qualtrics.svg'
// import typeform from '../assets/Surveys/typeform.svg'
// import surveymonkey from '../assets/Surveys/surveymonkey.svg'
import googleforms from '../assets/Surveys/googleforms.webp'
// import survio from '../assets/Surveys/survio.png'
import screen from '../assets/Surveys/screen.webp'
import googleFormsSetupGif from '../assets/Surveys/google-forms-setup.gif'
import { supportedCountriesISO } from './languages'

// platforms with logos
export const platforms = [
  // TOD Add Back later
  {
    name: 'qualtrics',
    logo: qualtrics,
    srcImg: screen,
    // srcVideo: 'https://www.youtube.com/embed/3qbOv6sGVeU',
    // srcType: 'image',
    videoProps: { allowFullScreen: true },
  },
  // { name: 'Qualtrics', logo: qualtrics, scrImg: screen },
  // { name: 'Typeform', logo: typeform, srcImg: screen },
  // { name: 'SurveyMonkey', logo: surveymonkey, srcImg: screen },
  { name: 'Google Forms', logo: googleforms, srcImg: googleFormsSetupGif },
  // { name: 'Survio', logo: survio, srcImg: screen },
  { name: 'Other', logo: null, srcImg: screen },
  { name: "I don't have a survey", logo: null, srcImg: screen },
]

// respondents values
export const respondents = [{ value: 20 }, { value: 100 }, { value: 200 }]

// steps in add survey nav
// Changing key name affects fixing survey flag as it depends in backend!!!
//   and FE use step.underscore() to render error
export const navSteps = [
  'LINK_YOUR_SURVEY',
  'ADD_DESCRIPTION',
  'SELECT_AUDIENCE',
  'CUSTOM_REQUIREMENTS',
  'SET_UP_TRACKING',
]

export const listingSurveySteps = [
  'ADD_DESCRIPTION',
  'SELECT_AUDIENCE',
  'CUSTOM_REQUIREMENTS',
]

// define error type
export const surveyErrorType = {
  missing_data: 'missing_data',
  error_survey_flag: 'error_survey_flag',
}
export const surveyErrorDetail = {
  missing_data: 'missing_data',
  missing_code: 'missing_code',
  has_not_change: 'has_not_change',
  fixed: 'fixed',
}

export const surveyStepToFixReason = {
  link_your_survey: ['other', 'low_quality', 'offline'],
  add_description: ['took_longer'],
  select_audience: ['wrong_language'],
  set_up_tracking: ['missing_code'],
}

export const customRequirements = [
  { field: 'age', type: 'range' },
  {
    field: 'country',
    type: 'multiselect',
    options: supportedCountriesISO,
    isSearchable: true,
  },
  { field: 'gender', type: 'multiselect', options: ['male', 'female'] },
  {
    field: 'occupation',
    type: 'multiselect',
    options: ['full_time', 'part_time', 'freelancer', 'student', 'unemployed'],
  },
  {
    field: 'education',
    type: 'multiselect',
    options: ['primary', 'secondary', 'bachelor', 'master', 'doctoral_phd'],
  },
  {
    field: 'additional_requirements',
    type: 'additional',
    options: ['DO_YOU_HAVE_ANY_SIBLINGS'],
  },
]

export const B2B_LINK = '/agency'
export const B2B_ESTIMATE_LINK = '/agency'

/**
 * Cache the /surveys for a given time.
 * To disable, set value to 'false' or '0'.
 * Unit: minutes, ie. 120 = 120 min = 2 h.
 */
export const CACHE_SUGGESTED_SURVEYS = 0

// required fields to move on next step
export const requiredFields = [
  [{ field: 'platform', default: '' }],
  [{ field: 'survey_link', default: '' }],
  [
    { field: 'title', default: null },
    { field: 'duration', default: null },
  ],
  [
    { field: 'respondents', default: 0 },
    { field: 'languages', default: [] },
  ],
  [{ field: 'custom_requirements_type', default: '' }],
  [{ field: 'none', default: '' }],
  [{ field: 'none', default: '' }],
]

// Survey Additional Questions config:
export const EARN_CREDITS_FOR_FILLING_QUESTIONS = 5
// additional question time between api call (minutes)
export const PROFILE_QUESTIONS_SYNC = 360
export const MAX_SURVEY_CUSTOM_QUESTIONS = 3
export const FIRST_ADDED_SURVEY_PRIZE = 5
