/* eslint-disable @typescript-eslint/no-empty-function */

declare global {
  interface Window {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    _cio: any
  }
}

export class CustomerIOClient {
  track(name: string, props: any = null): void {
    window._cio?.track(name, props)
  }

  identify(user: any, props: any): void {
    const { ...newProps } = props
    newProps.id = user.id

    if (user.created_at) {
      const secondsSinceEpoch = Math.floor(user.created_at.getTime() / 1000)
      newProps.created_at = secondsSinceEpoch
    }

    window._cio?.identify(newProps)
  }

  // https://customer.io/docs/sdk/web/events/#whats-the-difference-between-track-and-page-events
  // customerio `page` events are called automatically
  page(): void {}

  reset(): void {
    window._cio?.reset()
  }
}
