import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const GuarnteeToGetRespondentsQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('guarantee_to_get_respondents.question')}</>
}

export const GuarnteeToGetRespondentsAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>
        {t('guarantee_to_get_respondents.para1')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('guarantee_to_get_respondents.para2')}
      </p>
    </div>
  )
}
