import React from 'react'
import { Logo } from '../UI/Logo'
import { Loader } from '../UI/Loader'

/**
 * Renders the AppLoader component.
 *
 * @example
 * <AppLoader />
 */

export const AppLoader: React.FunctionComponent = () => {
  return (
    <div className='w-scren h-screen flex flex-col items-center justify-center'>
      <div className='mb-4' data-testid='AppLoader-logo'>
        <Logo />
      </div>
      <Loader />
    </div>
  )
}
