import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const IsItFreeQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('is_it_free.question')}</>
}

export const IsItFreeAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>{t('is_it_free.para1')}</p>
      <p className={paragraphBaseStyle}>{t('is_it_free.para2')}</p>
    </div>
  )
}
