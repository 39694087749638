import starsInIcon from '../../../assets/Surveys/star.svg'
import starsDeIcon from '../../../assets/Surveys/star-outline.svg'
import { Button } from '../Button'

/**
 * @typedef IRateInputProps
 * @props {string} [label] - the label text
 * @props {string} [className] - the CSS classes prefix -label and -input
 * @props {(val: number) => void} onChange - onChange handler
 * @props {string} value - value for input
 * @props {string} [labelStyles] - the CSS classes for label
 * @props {string} [inputStyles] - the CSS classes for input
 * @props {boolean} [required] - if field is required
 * @props {number} [max] - max value of number input
 * @props {number} [min] - min value of number input
 */

export interface IRateInputProps {
  label?: string
  className?: string
  onChange: (val: number) => void
  value: number
  labelStyles?: string
  inputStyles?: string
  required?: boolean
  min?: number
  max?: number
}

export enum RateInputStyle {
  INPUT = 'outline-none h-12 w-full border border-gray-600 rounded-md mt-3 mb-3 py-5 px-4 bg-gray-700',
  LABEL = 'text-xs font-bold text-black outline-none text-center',
}

/**
 * Renders the Label with RateInput component.
 *
 * @param {string} [label] - the label text
 * @param {string} [labelStyles] - the CSS classes for label
 * @param {string} [inputStyles] - the CSS classes for input
 * @param {(val: number) => void} onChange - onChange handler
 * @param {string} value - value for input
 * @param {boolean} [required] - if field is required
 * @param {number} [max] - max value of number input
 * @param {number} [min] - min value of number input
 *
 * @example
 * <RateInput
 *  onChange={(val: number) => void}
 *  value={value}
 * />
 */
export const RateInput = ({
  label = '',
  labelStyles = 'text-xs font-bold text-black outline-none text-center',
  inputStyles = 'outline-none h-12 flex items-center justify-between border border-gray-600 rounded-md mt-3 mb-3 py-5 px-4 bg-gray-700',
  onChange,
  value = 0,
  min = 1,
  max = 10,
  required = false,
}: IRateInputProps): JSX.Element => {
  const classNames = require('classnames')
  const LabelClass = classNames(labelStyles)
  const RateInputClass = classNames(inputStyles)
  function renderRateStars() {
    const renders: JSX.Element[] = []
    for (let index = min; index <= max; index++) {
      if (index <= value) {
        renders.push(
          <Button
            key={index}
            className='w-6 outline-none'
            style=''
            onClick={() => onChange(index)}
          >
            <img src={starsInIcon} alt='stars+' />
          </Button>,
        )
      } else {
        renders.push(
          <Button
            key={index}
            className='w-6 outline-none'
            style=''
            onClick={() => onChange(index)}
          >
            <img src={starsDeIcon} alt='stars-' />
          </Button>,
        )
      }
    }
    return renders
  }
  return (
    <label className={LabelClass}>
      {label && (
        <p className='pt-5'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
      )}
      <div className={RateInputClass}>{renderRateStars()}</div>
    </label>
  )
}
