import { APIError, IOrder, Order, Plan, TCurrencies } from '../models'
import APIService, { IResourceAPIResponse } from './APIService'
import { getGA4MeasurementParams } from 'src/helpers/googleAnalyticsHelpers'

interface IOrderWithCheckoutURL {
  order: Order
  checkoutUrl: string
}

class OrdersService {
  /**
   * Creates order and returns the order with a link to the payment.
   * @param {ISelectedPlan} plan
   */
  static async buy(
    plan: Plan,
    currency: TCurrencies,
  ): Promise<IOrderWithCheckoutURL | APIError<unknown> | null> {
    const orderData = {
      plan_name: plan.plan_name,
      price: plan.getPriceInCurrency(currency),
      credits: plan.credits,
      currency,
      discount_rate: plan.discount_rate,
      discount_amount: plan.getDiscountInCurrency(currency),
      total: plan.getTotalInCurrency(currency),
    }
    // For passing GA4 client_id to backend for measurement protocol
    const extraPurchasingParams = getGA4MeasurementParams()

    console.log(extraPurchasingParams)
    const response = await APIService.post('/orders', '', {
      order: { ...orderData, extra_purchasing_params: extraPurchasingParams },
    })

    if (response instanceof APIError) {
      return response
    }
    return {
      order: new Order(
        OrdersService.parseAPIResponse(response.data.order.data),
      ),
      checkoutUrl: response.data.checkout_url,
    }
  }

  /**
   * Get the order.
   * @param {string} orderId
   */
  static async getOrder(
    orderId: string,
  ): Promise<IOrder | APIError<unknown> | null> {
    const response = await APIService.get(`/orders/${orderId}`, '')
    if (response instanceof APIError) {
      return response
    }
    return new Order(OrdersService.parseAPIResponse(response.data.data))
  }

  /**
   * Get orders.
   */
  static async getOrders(): Promise<Order[] | APIError<unknown> | null> {
    const response = await APIService.get('/orders', '')
    if (response instanceof APIError) {
      return response
    }
    return OrdersService.parseAPIResponseArray<IOrder>(response.data)
  }

  /**
   * Calculate order based on credits or price.
   * @param {IOrderCalculations} params
   */
  static async calculatePlan({
    credits,
    price,
    currency,
    discountCode,
  }: {
    credits: number | null
    price: number | null
    currency: string
    discountCode?: string
  }): Promise<Plan | APIError<unknown> | null> {
    const response = await APIService.post('/orders/calculate', '', {
      credits,
      price,
      currency,
      discount_code: discountCode, // Add the discount code to the request
    })
    if (response instanceof APIError) {
      return response
    }
    return OrdersService.parseCalculateResponse(response.data)
  }

  static parseAPIResponse(response: IResourceAPIResponse): IOrder {
    return response.attributes
  }

  static parseAPIResponseArray<T>(response: IResourceAPIResponse[]): T[] {
    return (response.map(r => new Order(r.attributes)) as unknown) as T[]
  }

  static parseCalculateResponse(response: IResourceAPIResponse): Plan {
    return new Plan((response as unknown) as Plan)
  }
}

export default OrdersService
