/**
 * Credits Transaction - available operation types
 */
export type TCreditsOperations =
  | 'survey'
  | 'share'
  | 'invitation'
  | 'admin'
  | 'profile_questions'
  | 'bought'
  | 'share_prize_joined'
  | 'share_prize_shared'

/**
 * Credits Transaction interface
 */
export interface ICreditsTransaction {
  amount?: number
  creditable_name?: string | null
  creditable_type?: string | null
  description?: string
  operation_type?: TCreditsOperations
  to_id?: string
  created_at?: Date | string
}

/**
 * Credits Transaction model.
 */
export class CreditsTransaction {
  amount?: number
  creditable_name?: string | null
  creditable_type?: string | null
  description?: string
  operation_type?: TCreditsOperations
  to_id?: string
  created_at?: string

  constructor(props: ICreditsTransaction) {
    this.amount = props.amount
    this.creditable_name = props.creditable_name
    this.creditable_type = props.creditable_type
    this.description = props.description
    this.operation_type = props.operation_type
    this.to_id = props.to_id
    if (props.created_at) {
      if (props.created_at instanceof Date) {
        this.created_at = props.created_at.toLocaleDateString()
      } else {
        this.created_at = new Date(
          props.created_at as string,
        ).toLocaleDateString()
      }
    }
  }
}
