import { TCurrencies } from '../models'

/**
 * Format the price by adding the currency symbol before/after the price.
 * @param {string | number} price
 * @param {string} currency
 *
 * @return {string}
 *
 * @example
 * priceWithCurrency(10, 'USD') // $10
 * priceWithCurrency(10, 'EUR') // 10€
 */
export const priceWithCurrency = (
  price: string | number,
  currency: TCurrencies,
): string => {
  switch (currency) {
    case 'EUR':
      return `€${price}`
    case 'USD':
      return `$${price}`
    case 'GBP':
      return `£${price}`
    default:
      return `${price} ${currency}`
  }
}

/**
 * Convert price from cents to float value.
 * If price is undefined, returns 0.
 * @param {number} [price]
 *
 * @return {string}
 *
 * @example
 * convertFromCents(1000) // 10
 */
export const convertFromCents = (price?: number): number => {
  return price ? price / 100 : 0
}

/**
 * Adds decimal places. Inserts "." for EUR, and "," for GBP and USD.
 * @param {number} [price] - the price in cents
 * @param {TCurrencies} [currency] - the currency
 *
 * @return {string}
 *
 * @example
 * humanizePrice(2960, 'USD') // 29.60
 * humanizePrice(2960, 'EUR') // 29,60
 * humanizePrice(2900, 'EUR') // 29
 */
export const humanizePrice = (
  price: number,
  currency: TCurrencies = 'EUR',
): string => {
  if (price % 100 > 0) {
    const decimals = price % 100
    const basePrice = (price - decimals) / 100
    const formattedPrice = `${basePrice}`
    const separator = currency === 'EUR' ? '.' : ','
    return `${formattedPrice}${separator}${decimals}`
  } else {
    return `${price / 100}`
  }
}
