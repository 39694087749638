import { createConsumer } from '@rails/actioncable'
import config from '../config/app'

// TODO: make it accept dynamic query params
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const generateConsumer = (userId: string) => {
  return createConsumer(`${config.WEBSOCKET_URL}?user_id=${userId}`)
}

export default generateConsumer
