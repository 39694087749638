import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, LinkStyle } from '../Link'

/**
 * @typedef IBreadcrumbsProps
 * @props {string} [pathname] - the URL pathname.
 */

export interface IBreadcrumbsProps {
  pathname?: string
}

/**
 * Renders the breadcrumps component.
 *
 * @param {string} [pathname] - the URL pathname.
 *
 * @example
 * <Breadcrumbs />
 */

export const Breadcrumbs = ({
  pathname = '',
}: IBreadcrumbsProps): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = useState<string[]>([])
  const [active, setActive] = useState('')
  useEffect(() => {
    const tempPath = pathname ? pathname : window.location.pathname
    const path = tempPath
      .replace(/\/$/, '')
      .replace('/de/', '/')
      .replace('/nl/', '/')
      .replace('/lcdhq/terminated', '/surveys')
      .replace('/lcdhq/completed', '/surveys')
      .split('/')

    const indexToDelete: number[] = []
    path.map((el, idx) => {
      switch (el) {
        case '':
          return indexToDelete.push(idx)
        default:
          return path
      }
    })
    indexToDelete.sort(function (a, b) {
      return b - a
    })
    indexToDelete.map(id => {
      return path.splice(id, 1)
    })
    setBreadcrumbs(path)
    setActive(path[path.length - 1])
  }, [])
  const { t } = useTranslation('routes')
  const activeStyle = classNames('font-bold')
  return (
    <div className='items-center hidden md:flex'>
      {breadcrumbs.length > 0 && <span className='mr-2'>{` > `}</span>}
      {breadcrumbs.map((pathname, i) => (
        <span key={pathname}>
          {pathname === active ? (
            <p className={activeStyle}>{t(`${pathname.toUpperCase()}`)}</p>
          ) : (
            <Link style={LinkStyle.NONE} href={`/${pathname}`}>
              {t(`${pathname.toUpperCase()}`)}
            </Link>
          )}

          {i !== breadcrumbs.length - 1 && (
            <span className='mr-2'>{` > `}</span>
          )}
        </span>
      ))}
    </div>
  )
}
