/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { ChevronDoubleUpIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { INotificationSurveyResponseJSON } from 'src/models'

function HighPriorityNotificationModal({
  onClose,
  notification,
}: {
  onClose?: () => void
  notification: INotificationSurveyResponseJSON
}): JSX.Element {
  const { t } = useTranslation('app')
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (!show) {
      setTimeout(() => setShow(true), 500)
    }
  }, [show])

  const getMessages = surveyResponseStatus => {
    switch (surveyResponseStatus) {
      case 'started':
        return {
          title: t('notification.survey_response.first_started_response.title'),
          body: '',
        }
      default: {
        // credits_earned statuses
        return {
          title: t('notification.survey_response.first_response.title'),
          body: t('notification.survey_response.first_response.content'),
        }
      }
    }
  }

  const message = getMessages(notification.status)

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live='assertive'
        className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start top-16 z-50'
      >
        <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden'>
              <div className='p-4'>
                <div className='flex items-start'>
                  <div className='flex-shrink-0'>
                    <ChevronDoubleUpIcon
                      className='h-6 w-6 text-green-400'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                    <p className='text-sm font-medium text-gray-900'>
                      {message.title}
                    </p>
                    <p className='mt-1 text-sm text-gray-100'>{message.body}</p>
                  </div>
                  <div className='ml-4 flex-shrink-0 flex'>
                    <button
                      type='button'
                      className='bg-white rounded-md inline-flex text-gray-200 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      onClick={() => {
                        onClose && onClose()
                        setShow(false)
                      }}
                    >
                      <span className='sr-only'>Close</span>
                      <XIcon className='h-5 w-5' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

export default HighPriorityNotificationModal
