import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'

export const GuaranteedResponsesQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('guaranteed_responses.question')}</>
}

export const GuaranteedResponsesAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>{t('guaranteed_responses.para1')}</p>
      <p className={paragraphBaseStyle}>{t('guaranteed_responses.para2')}</p>
    </div>
  )
}
