import { TCurrencies } from './Plan'

/**
 * Payment statuses
 */
export type TPaymentStatus =
  | 'open'
  | 'processing'
  | 'paid'
  | 'expired'
  | 'failed'
  | 'canceled'
  | 'authorized'

/**
 * Order calculations
 */
// export interface IOrderCalculations {
//   credits?: number
//   price?: number
//   currency?: TCurrencies
//   discount_amount?: number
//   discount_rate?: number
//   total?: number
// }

/**
 * Order interface
 */
export interface IOrder {
  id?: string
  plan_name?: string
  credits?: number
  status?: string
  price?: number
  currency?: TCurrencies
  discount_amount?: number
  discount_rate?: number
  total?: number
  totalPriceFromCents?: number
  created_at?: Date | string
  updated_at?: Date | string
}

/**
 * Order model.
 */
export class Order {
  id?: string
  plan_name?: string
  credits?: number
  status?: string
  price?: number
  currency?: TCurrencies
  discount_amount?: number
  discount_rate?: number
  total?: number
  created_at?: Date | string
  updated_at?: Date | string
  totalPriceFromCents?: number

  constructor(props: IOrder) {
    this.id = props.id
    this.plan_name = props.plan_name
    this.credits = props.credits
    this.status = props.status
    this.price = props.price
    this.currency = props.currency
    this.discount_amount = props.discount_amount
    this.discount_rate = props.discount_rate
    this.total = props.total
    this.totalPriceFromCents = this.total / 100

    if (props.created_at) {
      if (props.created_at instanceof Date) {
        this.created_at = props.created_at.toLocaleDateString()
      } else {
        this.created_at = new Date(
          props.created_at as string,
        ).toLocaleDateString()
      }
    }
    if (props.updated_at) {
      if (props.updated_at instanceof Date) {
        this.updated_at = props.updated_at.toLocaleDateString()
      } else {
        this.updated_at = new Date(
          props.updated_at as string,
        ).toLocaleDateString()
      }
    }
  }
}
