import soft1 from '../../assets/Surveys/qualtrics.svg'
import soft2 from '../../assets/Surveys/typeform.svg'
import soft3 from '../../assets/Surveys/surveymonkey.svg'
import soft4 from '../../assets/Surveys/googleforms.webp'
import soft5 from '../../assets/Surveys/msforms.svg'
import { useTranslation } from 'react-i18next'

export const OtherSoftwarePaid = ({
  imgStyle = 'w-auto h-7 my-4 md:my-0 mx-4 md:mx-0',
  imgCmpStyle = 'flex justify-start md:justify-between flex-wrap mt-12 mx-2',
}: {
  imgStyle?: string
  imgCmpStyle?: string
}): JSX.Element => {
  const { t } = useTranslation('app')
  return (
    <div className='py-24'>
      <p className='text-2xl font-bold mb-6'>{t('USE_OTHER_SOFTWARE_PAID')}</p>
      <p className='font-normal'>{t('PLACE_A_LINK_PAID')}</p>
      <div className={imgCmpStyle}>
        <img className={imgStyle} src={soft1} alt='soft-1' />
        <img className={imgStyle} src={soft2} alt='soft-2' />
        <img className={imgStyle} src={soft3} alt='soft-3' />
        <img className={imgStyle} src={soft4} alt='soft-4' />
        <img className={imgStyle} src={soft5} alt='soft-5' />
      </div>
    </div>
  )
}

export default OtherSoftwarePaid
