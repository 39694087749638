import React from 'react'
import classNames from 'classnames'
import ReactSelect, { OptionsType } from 'react-select'
import ReactSelectCreatable from 'react-select/creatable'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { InputStyle } from './Input'
import './Select.css'
/**
 * @typedef ISelectProps
 * @props {string} id - the ID's of input element
 * @props {string} [label] - the label text
 * @props {(selected: string[]) => void} [onMultiChange] - onChange handler
 * @props {(selected: string[]) => void} [onChange] - onChange handler
 * @props {ISelectOption} [options] - options of select
 * @props {string} [placeholder] - the placeholder text for input element
 * @props {string} [value] - value for input
 * @props {string} [values] - values for multi input
 * @props {boolean} [isMulti] - multiselect option
 * @props {boolean} [isSearchable] - searchable options
 * @props {string} [labelStyles] - the CSS classes for label
 * @props {boolean} [required] - if field is required
 */

export type TSelectOption = ISelectOption | OptionsType<ISelectOption> | null

export interface ISelectProps {
  id: string
  name?: string
  label?: string | React.ReactNode
  onChange?: (selected: TSelectOption) => void
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void
  onMultiChange?: (selected: ISelectOption[]) => void
  placeholder?: string
  value?: ISelectOption
  values?: ISelectOption[]
  defaultValue?: ISelectOption
  labelStyles?: string
  isMulti?: boolean
  defaultOptions?: boolean
  menuPlacement?: 'auto' | 'bottom' | 'top'
  options?: ISelectOption[]
  required?: boolean
  isSearchable?: boolean
  invalid?: boolean
  error?: string
  loadOptions?: (
    inputValue: string,
  ) => Promise<readonly ISelectOption[]> | undefined
  noOptionsMessage?: string
  isDisabled?: boolean
  isClearable?: boolean
}

export interface ISelectOption {
  value: string
  label: string
  id?: string
  menuPlacement?: string
  field_type?: string
}
/**
 * Renders the Label with Select component.
 *
 * @param {string} id - the ID's of input element
 * @param {string} [label] - the label text
 * @param {(selected: string[]) => void} [onMultiChange] - onChange handler
 * @param {(selected: string[]) => void} [onChange] - onChange handler
 * @param {ISelectOption} [options] - options of select
 * @param {string} [placeholder] - the placeholder text for input element
 * @param {string} [value] - value for input
 * @param {string} [values] - values for multi input
 * @param {boolean} [isMulti] - multiselect option
 * @param {boolean} [isSearchable] - searchable options
 * @param {string} [labelStyles] - the CSS classes for label
 * @param {boolean} [required] - if field is required
 * @param {boolean} [isDisabled]
 *
 * @example
 * <Select
 *  id='username'
 *  onChange={(value: ISelectOption | OptionsType<ISelectOption> | null) => void}
 *  placeholder='user'
 *  label='username'
 *  value={value}
 *  typeInput='text'
 * />
 */
export const Select = ({
  id,
  name,
  label = '',
  labelStyles,
  isMulti = false,
  options = [],
  menuPlacement = 'auto',
  value,
  placeholder,
  values,
  onBlur,
  onChange,
  onMultiChange,
  isSearchable = false,
  required = false,
  invalid,
  error,
  isDisabled = false,
  isClearable = false,
}: ISelectProps): React.ReactElement => {
  const LabelClass = classNames(labelStyles, {
    [InputStyle.LABEL]: !labelStyles,
  })
  const baseStyle = classNames({
    'react-select--invalid': invalid,
  })
  const errorStyle = 'font-normal text-red-500 mt-2'

  const onSelect = (selected: TSelectOption) => {
    if (!isMulti && onChange) {
      onChange(selected)
    } else {
      const multiValue: ISelectOption[] = []
      if (Array.isArray(selected) && onMultiChange) {
        selected.map(val => multiValue.push(val as ISelectOption))
        onMultiChange(multiValue)
      }
    }
  }

  return (
    <label htmlFor={id} className={LabelClass}>
      {label && (
        <p className='pt-5 mt-3'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
      )}
      <ReactSelect
        id={id}
        name={name}
        className={baseStyle}
        classNamePrefix='select'
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        value={isMulti ? values : value}
        placeholder={placeholder}
        options={options}
        onBlur={onBlur}
        menuPlacement={menuPlacement} // Set the menuPlacement prop to "top"
        onChange={selected => onSelect(selected)}
        isDisabled={isDisabled}
      />
      {invalid && error && <p className={errorStyle}>{error}</p>}
    </label>
  )
}

export const Creatable = ({
  id,
  label = '',
  labelStyles,
  isMulti = false,
  options = [],
  value,
  values,
  onChange,
  onMultiChange,
  isSearchable = false,
  required = false,
  invalid,
  error,
}: ISelectProps): React.ReactElement => {
  const LabelClass = classNames(labelStyles, {
    [InputStyle.LABEL]: !labelStyles,
  })
  const errorStyle = 'font-normal text-red-500 mt-2'
  const onSelect = (selected: TSelectOption) => {
    if (!isMulti && onChange) {
      onChange(selected)
    } else {
      const multiValue: ISelectOption[] = []
      if (Array.isArray(selected) && onMultiChange) {
        selected.map(val => multiValue.push(val))
        onMultiChange(multiValue)
      }
    }
  }

  return (
    <label htmlFor={id} className={LabelClass}>
      {label && (
        <p className='pt-5 mt-3'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
      )}
      <ReactSelectCreatable
        classNamePrefix='select'
        isClearable={false}
        isMulti={isMulti}
        isSearchable={isSearchable}
        value={isMulti ? values : value}
        options={options}
        onChange={selected => onSelect(selected)}
      />
      {invalid && error && <p className={errorStyle}>{error}</p>}
    </label>
  )
}

export const AsyncCreatable = ({
  id,
  label = '',
  labelStyles,
  isMulti = false,
  loadOptions,
  value,
  values,
  placeholder,
  onChange,
  onMultiChange,
  isSearchable = false,
  required = false,
  invalid,
  error,
  defaultOptions = false,
  defaultValue,
  noOptionsMessage = 'Start typing...',
}: ISelectProps): React.ReactElement => {
  const LabelClass = classNames(labelStyles, {
    [InputStyle.LABEL]: !labelStyles,
  })
  const errorStyle = 'font-normal text-red-500 mt-2'
  const onSelect = (selected: TSelectOption) => {
    if (!isMulti && onChange) {
      onChange(selected)
    } else {
      const multiValue: ISelectOption[] = []
      if (Array.isArray(selected) && onMultiChange) {
        selected.map(val => multiValue.push(val.value))
        onMultiChange(multiValue)
      }
    }
  }

  return (
    <label htmlFor={id} className={LabelClass}>
      {label && (
        <p className='pt-5 mt-3'>
          {label}
          {required && <span className='text-blue-500'>*</span>}
        </p>
      )}
      <AsyncCreatableSelect
        cacheOptions
        classNamePrefix='select'
        isClearable={false}
        isMulti={isMulti}
        placeholder={placeholder}
        isSearchable={isSearchable}
        defaultOptions={defaultOptions}
        value={isMulti ? values : value}
        loadOptions={loadOptions}
        onChange={selected => onSelect(selected)}
        defaultValue={defaultValue}
        noOptionsMessage={() => noOptionsMessage}
        createOptionPosition='first'
      />
      {invalid && error && <p className={errorStyle}>{error}</p>}
    </label>
  )
}
