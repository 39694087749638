import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import classNames from 'classnames'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'
/**
 * @typedef ILinkProps
 * @props {React.ReactNode} children - the link's text.
 * @props {'_blank' | '_parent' | '_self' | '_top'} [target] - The target attribute specifies where to open the linked document
 * @props {string} [className] - the CSS classes.
 * @props {string} href - the href attribute of the link.
 * @props {LinkStyle | string} [style] - the CSS enum style
 * @props {boolean} [asPrimitiveLink] - render as plain <a/> element. Otherwise, render as ReactRouterDom Link.
 */

export interface ILinkProps {
  children: React.ReactNode
  className?: string
  target?: '_blank' | '_parent' | '_self' | '_top'
  href: string
  style?: LinkStyle | string
  asPrimitiveLink?: boolean
  supportLocale?: boolean
}

/** @enum {string} LinkStyle */
export enum LinkStyle {
  DEFAULT = 'underline',
  NONE = 'hover:no-underline no-underline',
  PRIMARY = 'hover:no-underline no-underline text-blue-500 hover:text-black font-medium',
}

/**
 * Renders the Link component.
 *
 * @param {React.ReactNode} children - the link's text.
 * @param {string} [className] - the CSS classes.
 * @param {'_blank' | '_parent' | '_self' | '_top'} [target] - The target attribute specifies where to open the linked document
 * @param {string} href - the href attribute of the link.
 * @param {LinkStyle | string} [style] - the CSS enum style
 * @param {boolean} [asPrimitiveLink = false] - render as plain <a/> element. Otherwise, render as ReactRouterDom Link.
 *
 * @example
 * <Link
 *  href={"https://xyz.com"}
 *  style={LinkStyle.DEFAULT}
 * >
 *  My Link
 * </Link
 */

export const Link: React.FunctionComponent<ILinkProps> = ({
  children,
  className = '',
  style = 'underline',
  target,
  href,
  asPrimitiveLink = false,
  supportLocale = true,
}: ILinkProps) => {
  const linkStyle = classNames(style, {
    [className]: className,
  })
  if (asPrimitiveLink) {
    return (
      <a target={target} href={href} className={linkStyle}>
        {children}
      </a>
    )
  }
  let linkhref = href
  if (supportLocale) {
    linkhref = includeCurrentLocaleToPath(href)
  }
  return (
    <ReactLink target={target} to={linkhref} className={linkStyle}>
      {children}
    </ReactLink>
  )
}
