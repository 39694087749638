import LandingLayout from 'src/layouts/LandingLayout'

const DataQualityStatement = () => {
  return (
    <LandingLayout>
      <div className='py-8 border-b border-gray-700'>
        <div className='max-w-screen-lg mx-auto px-8 xl:px-0'>
          <h1 className='text-3xl font-bold mb-6'>
            SurveySwap &amp; Data Quality
          </h1>
          <div className='space-y-4'>
            <p>
              At SurveySwap, we are dedicated to delivering authentic and
              reliable survey responses. Our platform is supported by a team of
              experienced researchers who play a crucial role in overseeing our
              clients&apos; surveys. These researchers offer valuable insights
              and guidance on best practices for optimizing data collection
              processes, ensuring that our clients receive high-quality and
              actionable data.
            </p>
            <p>
              Participants in our panel join voluntarily and provide
              comprehensive information about themselves, enabling us to match
              them accurately with relevant surveys. They actively opt-in to
              participate and undergo rigorous language and behavior checks,
              conducted in collaboration with our partners like SENTRY
              (CloudResearch). These stringent measures are designed to uphold
              the integrity of the data collected, ensuring that responses are
              genuine and meet our rigorous standards.
            </p>
            <p>
              For more detailed information on our data collection and
              validation processes, please reach out to us. We are committed to
              providing trustworthy data that supports informed decision-making
              for businesses, academics, and researchers alike.
            </p>
          </div>
        </div>
      </div>
    </LandingLayout>
  )
}

export default DataQualityStatement
