import { useTranslation } from 'react-i18next'
import { paragraphBaseStyle } from '../FrequentlyQuestions'
import { Link, LinkStyle } from '../../UI/Link'

export const WhenIReceiveRespondentsPaidQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('when_i_receive_respondents_paid.question')}</>
}

export const WhenIReceiveRespondentsPaidAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <p className={paragraphBaseStyle}>
        {t('when_i_receive_respondents_paid.para1')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('when_i_receive_respondents_paid.para2')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('when_i_receive_respondents_paid.para3')}
      </p>
      <p className={paragraphBaseStyle}>
        {t('when_i_receive_respondents_paid.para4_1')}{' '}
        <Link
          href='/agency'
          className='text-blue-500 font-semibold'
          style={LinkStyle.NONE}
        >
          {t('when_i_receive_respondents_paid.para4_2')}
        </Link>
        .
      </p>
    </div>
  )
}
