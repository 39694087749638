import env from './env'

const prod = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_7JxTn9vJt',
    identityPoolId: 'eu-central-1:443e63cf-9c70-4ab1-9db1-ed38bc280090', // Not used
    userPoolWebClientId: '39orrp29g705gt4c26kkkmqq8o',
    facebookProviderAppId: '445224680103665',
    googleProviderAppId:
      '308591541864-p5u6j2le2lsr9tfbnhu89u7v0ahckp8c.apps.googleusercontent.com',
    oauthDomain: 'auth.surveyswap.io',
  },
}

const stg = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_ZlAbxWyTz',
    identityPoolId: 'eu-central-1:443e63cf-9c70-4ab1-9db1-ed38bc280090',
    userPoolWebClientId: '3p8hlcakkvi769n4kfir73b9i2',
    facebookProviderAppId: '445224680103665',
    googleProviderAppId:
      '308591541864-p5u6j2le2lsr9tfbnhu89u7v0ahckp8c.apps.googleusercontent.com',
    oauthDomain: 'surveyswapdev.auth.eu-central-1.amazoncognito.com',
  },
}

const dev = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_ZlAbxWyTz',
    identityPoolId: 'eu-central-1:22778820-d20f-4a68-bd8e-58908a382d80',
    userPoolWebClientId: '3p8hlcakkvi769n4kfir73b9i2',
    facebookProviderAppId: '445224680103665',
    googleProviderAppId:
      '308591541864-p5u6j2le2lsr9tfbnhu89u7v0ahckp8c.apps.googleusercontent.com',
    oauthDomain: 'surveyswapdev.auth.eu-central-1.amazoncognito.com',
  },
}

let config = dev
if (env === 'production') {
  config = prod
} else if (env === 'staging') {
  config = stg
}

export default {
  ...config,
}
