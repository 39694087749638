import { useTranslation } from 'react-i18next'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import {
  Input,
  Datepicker,
  Textarea,
  Select,
  ISelectOption,
} from '../UI/Inputs'

import { Button } from '../UI/Button'
// import { useState } from 'react'

export interface IB2BForm {
  name?: string
  email?: string
  company_name?: string
  phone?: number
  customer_type?: string
  budget?: string
  survey_link?: string
  deadline?: Date | null
  respondents?: number
  target_audience?: string
  other_message?: string
  completion_time?: string
  gclid?: string
}

export const B2BForm = ({
  onApi,
}: {
  onApi: (val: IB2BForm, onSuccess: () => void) => void
}): JSX.Element => {
  const { t } = useTranslation('app')
  //   const [customerType, setCustomerType] = useState(null)

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<IB2BForm>({})

  const defaultValues = {
    name: '',
    email: '',
    company_name: '',
    phone: undefined,
    customer_type: '',
    budget: '',
    survey_link: '',
    deadline: undefined,
    respondents: undefined,
    target_audience: undefined,
    other_message: '',
    completion_time: '',
    gclid: '',
  }

  const onSuccess = () => {
    reset(defaultValues)
  }
  const submit: SubmitHandler<IB2BForm> = async (d: IB2BForm) => {
    const {
      name,
      email,
      company_name,
      customer_type,
      budget,
      survey_link,
      deadline,
      respondents,
      other_message,
      completion_time,
      gclid,
    } = d
    if (isDirty) {
      onApi(
        {
          name,
          email,
          company_name,
          customer_type,
          budget,
          survey_link,
          deadline,
          respondents,
          other_message,
          completion_time,
          gclid,
        },
        onSuccess,
      )
    }
  }

  return (
    <div>
      <form id='b2b-form' onSubmit={handleSubmit(submit)}>
        <Input
          id='name'
          placeholder=''
          label={t('b2b_form.name')}
          typeInput='text'
          required={true}
          invalid={!!errors.name}
          error={t('Cannot be blank')}
          {...register('name', { required: true })}
        />
        <Input
          id='company_name'
          placeholder=''
          label={t('b2b_form.company')}
          typeInput='text'
          required={true}
          invalid={!!errors.company_name}
          error={t('Cannot be blank')}
          {...register('company_name', { required: true })}
        />
        <Input
          id='email'
          placeholder=''
          label={t('b2b_form.email')}
          typeInput='email'
          required={true}
          invalid={!!errors.email}
          error={t('Cannot be blank')}
          {...register('email', { required: true })}
        />

        <Controller
          name='customer_type'
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            const value = field.value
              ? {
                  value: field.value,
                  label: t(`b2b_form.customer_type_options.${field.value}`),
                }
              : undefined
            return (
              <Select
                id='customer_type'
                label={t('b2b_form.customer_type_label')}
                required={true}
                onChange={val =>
                  field.onChange(val ? (val as ISelectOption).value : null)
                }
                value={value}
                options={[
                  {
                    value: 'market',
                    label: t('b2b_form.customer_type_options.market'),
                  },
                  {
                    value: 'academic',
                    label: t('b2b_form.customer_type_options.academic'),
                  },
                  {
                    value: 'student',
                    label: t('b2b_form.customer_type_options.student'),
                  },
                ]}
                invalid={!!errors.customer_type}
                error={t('Cannot be blank')}
              />
            )
          }}
        />
        <Controller
          name='budget'
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            const value = field.value
              ? {
                  value: field.value,
                  label: t(`b2b_form.budget_options.${field.value}`),
                }
              : undefined
            return (
              <Select
                id='budget'
                label={t('b2b_form.budget_label')}
                required={true}
                onChange={val =>
                  field.onChange(val ? (val as ISelectOption).value : null)
                }
                value={value}
                options={[
                  {
                    value: 'low',
                    label: t('b2b_form.budget_options.low'),
                  },
                  {
                    value: 'mid',
                    label: t('b2b_form.budget_options.mid'),
                  },
                  {
                    value: 'high',
                    label: t('b2b_form.budget_options.high'),
                  },
                  {
                    value: 'highest',
                    label: t('b2b_form.budget_options.highest'),
                  },
                ]}
                invalid={!!errors.budget}
                error={t('Cannot be blank')}
              />
            )
          }}
        />
        <Input
          id='survey_link'
          placeholder=''
          label={t('b2b_form.survey_link')}
          typeInput='text'
          required={false}
          {...register('survey_link', { required: true })}
        />
        <span className='text-xs text-gray-100 leading-none'>
          {t('b2b_form.survey_link_info')}
        </span>
        <Input
          id='completion_time'
          placeholder={t('b2b_form.completion_time_placeholder')}
          label={t('b2b_form.completion_time')}
          typeInput='text'
          required={false}
          {...register('completion_time', { required: true })}
        />

        <div className='flex items-start'>
          <div className='w-1/2 md:mr-8'>
            <Controller
              name='deadline'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Datepicker
                    id='deadline'
                    label={t('b2b_form.deadline')}
                    required={true}
                    onChange={field.onChange}
                    value={field.value}
                    invalid={!!errors.deadline}
                    error={t('Cannot be blank')}
                  />
                )
              }}
            />
          </div>
        </div>
        <Input
          id='respondents'
          label={t('b2b_form.respondents')}
          typeInput='number'
          placeholder={t(`b2b_form.respondents_placeholder`)}
          required={true}
          min={200}
          invalid={!!errors.respondents}
          error={t('Cannot be blank')}
          {...register('respondents', { required: true, min: 200 })}
        />

        <Controller
          name='other_message'
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Textarea
                id='other_message'
                label={t('b2b_form.other')}
                required={true}
                placeholder={t(`b2b_form.describe_placeholder`)}
                onChange={field.onChange}
                value={field.value}
                invalid={!!errors.other_message}
                error={t('Cannot be blank')}
              />
            )
          }}
        />
        <div className='my-8'>
          <Button type='submit'>{t('GET_A_QUOTE')}</Button>
        </div>
      </form>
    </div>
  )
}
