import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Logo } from '../../UI/Logo'
import AuthImage from '../../../assets/Auth/Get started-light-1.svg'

export const AuthHeader = ({
  title = 'Welcome',
}: {
  title?: string
}): JSX.Element => {
  const { t } = useTranslation('auth')
  return (
    <div>
      <h1 className='hidden'>{t(title)}</h1>
      <div className='flex items-center justify-center md:justify-start font-semibold'>
        {t('WELCOME')}{' '}
        <Link to='/'>
          <Logo />
        </Link>
      </div>
      <div className='flex flex-grow items-center justify-center md:hidden'>
        <img className='mx-auto w-56 my-6' src={AuthImage} alt='Welcome' />
      </div>
    </div>
  )
}
