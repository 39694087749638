import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { includeCurrentLocaleToPath } from '../../../helpers/localeHelpers'
/**
 * @typedef IButtonProps
 *
 * @props {React.ReactNode} [children] - the button's text.
 * @props {'button' | 'submit'} [type] - the html type for button 'button' | 'submit'
 * @props {(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void} [onclick] - onClick handler.
 * @props {string} [className] - the CSS classes.
 * @props {ButtonStyle | string} [style] - the CSS default style 'primary' | 'secondary'
 * @props {string} [href] - the href attribute of the link.
 * @props {boolean} [asReactLink=true] - renders the link as a react-router-dom's Link
 * @props {'_blank' | '_parent' | '_self' | '_top'} [target] - The target attribute specifies where to open the linked document
 * @props {'left' | 'right'} [iconPosition] - render the icon on the left or right side
 * @props {string} [icon]  - the icon by default rendered on the left side
 * @props {boolean} [disabled]  - the button disabled prop
 */

export interface IButtonProps {
  children?: React.ReactNode
  type?: 'button' | 'submit'
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void
  style?: ButtonStyle | string
  iconPosition?: 'left' | 'right'
  icon?: string
  target?: '_blank' | '_parent' | '_self' | '_top'
  href?: string
  disabled?: boolean
  asReactLink?: boolean
  supportLocale?: boolean
  id?: string
}

export enum ButtonStyle {
  PRIMARY = 'flex items-center justify-center bg-black text-white rounded-md hover:shadow-md hover:bg-blue-500 border-1 border-black hover:border-blue-500',
  SECONDARY = 'flex items-center justify-center bg-gray-700 text-black rounded-md border border-gray-600 hover:bg-blue-700 hover:border-blue-600 hover:text-blue-500',
  TERTIARY = 'font-normal flex items-center justify-center bg-gray-700 text-black rounded-md border border-black hover:bg-black hover:text-white',
  WHITE = 'flex items-center justify-center border-1 border-black bg-white text-black rounded-md hover:bg-black hover:text-white',
  NOBORDERS = 'flex items-center justify-center border-0 bg-white text-black opacity-70 hover:opacity-100 hover:text-blue-500',
  BLUE = 'flex items-center justify-center bg-blue-500 hover:bg-blue-400 text-white rounded-md',
  ORANGE = 'flex items-center justify-center bg-orange-500 hover:bg-orange-400 text-white rounded-md',
  DISABLED = 'cursor-not-allowed text-gray-100 flex items-center justify-center bg-gray-300 rounded-md ',
  YELLOW = 'flex items-center justify-center bg-yellow-500 hover:bg-yellow-600 text-black rounded-md',
}

/**
 * Renders the Button component.
 *
 * @param {React.ReactNode} children - the button's text.
 * @param {string} [className] - the CSS classes.
 * @param {(e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void} [onClick] - onClick handler.
 * @param {ButtonStyle | string} [style] - the CSS default style 'primary' | 'secondary'
 * @param {'button' | 'submit'} [type] - the html type for button 'button' | 'submit'
 * @param {string} [href] - the href attribute of the link.
 * @param {boolean} [asReactLink=true] - renders the link as a react-router-dom's Link
 * @param {'_blank' | '_parent' | '_self' | '_top'} [target] - The target attribute specifies where to open the linked document
 * @param {'left' | 'right'} [iconPosition] - render the icon on the left or right side
 * @param {string} [icon]  - the icon by default rendered on the left side
 * @param {boolean} [disabled]  - the button disabled prop
 *
 * @example
 * <Button
 *  onClick={e => e}
 *  className=''
 *  style={ButtonStyle.PRIMARY}
 *  type='button'
 * >
 *  Click me
 * </Button
 */

export const Button: React.FunctionComponent<IButtonProps> = ({
  type = 'button',
  className = 'max-w-max p-3 sm:px-6 sm:py-3',
  onClick,
  style = 'flex items-center justify-center bg-black text-white rounded-md hover:shadow-md hover:bg-blue-500 border-2 border-black hover:border-blue-500',
  iconPosition = 'left',
  icon,
  target,
  id,
  href,
  asReactLink = true,
  children,
  disabled = false,
  supportLocale = true,
}: IButtonProps) => {
  const transitionStyle = 'transition duration-200 ease-in-out'
  const buttonStyle = classNames(
    { [ButtonStyle.DISABLED]: disabled },
    { [style]: !disabled },
    'font-semibold',
    transitionStyle,
    {
      ['flex-row-reverse']: iconPosition === 'right',
      [className]: className,
    },
  )
  const imageStyle = classNames('min-w-5 w-5 h-5', {
    ['md:ml-3']: children && iconPosition === 'right',
    ['md:mr-3']: children && iconPosition === 'left',
  })
  if (href) {
    if (asReactLink) {
      let linkhref = href
      if (supportLocale) {
        linkhref = includeCurrentLocaleToPath(href)
      }
      if (linkhref.includes('#')) {
        return (
          <HashLink
            smooth={true}
            id={id}
            className={buttonStyle}
            to={linkhref}
            target={target}
          >
            {icon && <img className={imageStyle} src={icon} alt={icon} />}
            {children && children}
          </HashLink>
        )
      }
      return (
        <Link id={id} className={buttonStyle} to={linkhref} target={target}>
          {icon && <img className={imageStyle} src={icon} alt={icon} />}
          {children && children}
        </Link>
      )
    }
    return (
      <a id={id} className={buttonStyle} href={href} target={target}>
        {icon && <img className={imageStyle} src={icon} alt={icon} />}
        {children && children}
      </a>
    )
  }
  return (
    <button
      onClick={e => onClick && onClick(e)}
      className={buttonStyle}
      type={type}
      disabled={disabled}
      id={id}
    >
      {icon && <img className={imageStyle} src={icon} alt={icon} />}
      {children && children}
    </button>
  )
}
