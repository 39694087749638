import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { CurrentUserContext } from '../../App'
import BuyCreditsComponent from '../../components/BuyCredits/BuyCreditsComponent'
import { APIError, Plan, TCurrencies } from '../../models'
import SurveyService from '../../services/SurveyService'
import { useTranslation } from 'react-i18next'
import OrdersService from '../../services/OrdersService'
import AnalyticsSegmentService from '../../services/AnalyticsSegmentService'
import TagManager from 'react-gtm-module'
import { AppLoader } from '../../components/AppLoader'

export const emptyPlan = new Plan({
  plan_name: 'other',
  credits: 0,
  price_eur: 0,
  price_usd: 0,
  price_gbp: 0,
  discount_rate: 0,
  discount_eur: 0,
  discount_usd: 0,
  discount_gbp: 0,
  total_usd: 0,
  total_eur: 0,
  total_gbp: 0,
})

const BuyCreditsContainer = (): JSX.Element => {
  const userContext = useContext(CurrentUserContext)
  const [mySurveys, setMySurveys] = useState(null)
  const [discountCode, setDiscountCode] = useState(null)
  const [currency, setCurrency] = useState<TCurrencies>('EUR')
  const [selectedPlan, setSelected] = useState<Plan | null>(emptyPlan)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const { t } = useTranslation('app')

  useEffect(() => {
    if (userContext.currentUser) {
      getMySurveys()
    }
  }, [])

  useEffect(() => {
    console.log('discountCode', discountCode)
  }, [discountCode])

  const onBuy = async plan => {
    // Track with the Segement an attempt of buying credits
    AnalyticsSegmentService.track('Buying Credits', {
      credits: plan.credits,
      currency: currency,
      plan: plan.plan_name,
    })

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })

    const response = await OrdersService.buy(plan, currency)
    if (response instanceof APIError) {
      setError(response.code as string)
      toast.error(
        'Something went wrong, please contact customer support from the widget on the bottom right',
      )
      return
    }
    if (response && response.checkoutUrl) {
      // TODO: allow user to go back to previous page
      window.location.replace(response.checkoutUrl)
    }
  }

  const getMySurveys = async () => {
    if (!userContext || !userContext.currentUser) {
      return
    }

    setLoading(true)

    const response = await SurveyService.getMySurveys()
    if (response instanceof APIError) {
      toast.error(t('errors.something_went_wrong'))
      return
    }
    setLoading(false)

    setMySurveys(response)
  }

  if (loading) return <AppLoader />

  return (
    <div className=''>
      <BuyCreditsComponent
        mySurveys={mySurveys}
        buyCredits={onBuy}
        selected={selectedPlan}
        setSelected={val => setSelected(val)}
        discountCode={discountCode}
        setDiscountCode={val => setDiscountCode(val)}
        currency={currency}
        setCurrency={setCurrency}
        error={error}
      />
    </div>
  )
}

export default BuyCreditsContainer
