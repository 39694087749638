import { useTranslation } from 'react-i18next'
import howItImg1 from '../../assets/LandingPage/Home/b2b-works-1.svg'
import howItImg2 from '../../assets/LandingPage/Home/b2b-works-2.svg'
import howItImg3 from '../../assets/LandingPage/Home/b2b-works-3.svg'
import howItImg4 from '../../assets/LandingPage/Home/b2b-works-4.svg'
import howItImg5 from '../../assets/LandingPage/Home/b2b-works-5.svg'
import rightWorksImg from '../../assets/LandingPage/Home/right-works.webp'

/**
 * Renders the how it works section for b2b.
 */
export const B2BHowItWorks = (): JSX.Element => {
  const { t } = useTranslation('app')
  const getDivideList = () => (
    <div className='mt-2 h-10 w-1 ml-6 md:ml-0 md:mt-6 md:h-1 md:w-full bg-blue-700' />
  )
  return (
    <div className='flex md:block'>
      <ul className='md:w-full md:flex items-start flex-nowrap'>
        <li className='w-full md:w-1/6 flex md:flex-col items-center md:text-center md:justify-center font-normal text-sm mx-1'>
          <img className='w-12' src={howItImg1} alt='how-it-works-1' />
          {t('HOW_WORKS_1_B2B')}
        </li>
        <li className='w-1/6'>{getDivideList()}</li>
        <li className='w-full md:w-1/6 flex md:flex-col items-center md:text-center md:justify-center font-normal text-sm mx-1'>
          <img className='w-12' src={howItImg2} alt='how-it-works-2' />
          {t('HOW_WORKS_2_B2B')}
        </li>
        <li className='w-1/6'>{getDivideList()}</li>
        <li className='w-full md:w-1/6 flex md:flex-col items-center md:text-center md:justify-center font-normal text-sm mx-1'>
          <img className='w-12' src={howItImg3} alt='how-it-works-3' />
          {t('HOW_WORKS_3_B2B')}
        </li>
        <li className='w-1/6'>{getDivideList()}</li>
        <li className='w-full md:w-1/6 flex md:flex-col items-center md:text-center md:justify-center font-normal text-sm mx-1'>
          <img className='w-12' src={howItImg4} alt='how-it-works-4' />
          {t('HOW_WORKS_4_B2B')}
        </li>
        <li className='w-1/6'>{getDivideList()}</li>
        <li className='w-full md:w-1/6 flex md:flex-col items-center md:text-center md:justify-center font-normal text-sm mx-1'>
          <img className='w-12' src={howItImg5} alt='how-it-works-5' />
          {t('HOW_WORKS_5_B2B')}
        </li>
      </ul>
      <img
        className='p-6 h-72 mx-auto hidden sm:block md:hidden'
        src={rightWorksImg}
        alt='right-works'
      />
    </div>
  )
}

export default B2BHowItWorks
