import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Logo } from '../../UI/Logo'

export const SetNewPasswordHeader = ({
  title = 'HELLO',
}: {
  title?: string
}): JSX.Element => {
  const { t } = useTranslation('auth')
  return (
    <div>
      <div className='flex mb-1'>
        <Link to='/'>
          <Logo />
        </Link>
      </div>
      <h1 className='mb-3'>{t(title)}</h1>
    </div>
  )
}
