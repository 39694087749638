import { useTranslation } from 'react-i18next'

export const EaseOfUseQuestion = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return <>{t('ease_of_use.question')}</>
}

export const EaseOfUseAnswer = (): JSX.Element => {
  const { t } = useTranslation('faq')
  return (
    <div>
      <ol className='list-decimal pl-5'>
        <li>{t('ease_of_use.list1')}</li>
        <li>{t('ease_of_use.list2')}</li>
        <li>{t('ease_of_use.list3')}</li>
        <li>{t('ease_of_use.list4')}</li>
        <li>{t('ease_of_use.list5')}</li>
        <li>{t('ease_of_use.list6')}</li>
      </ol>
    </div>
  )
}
