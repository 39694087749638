import React from 'react'
import classNames from 'classnames'
/**
 * @typedef IBadgeProps
 *
 * @props {React.ReactNode} children - the bagde children.
 * @props {string} [style] - the badge style
 * @props {string} [className] - the CSS styles
 */

export interface IBadgeProps {
  children: React.ReactNode
  style?: string
  className?: string
}

export enum BadgeStyle {
  GRAY = 'bg-gray-700 border border-gray-600 text-black',
  GREEN = 'bg-green-700 border border-green-600 text-green-400',
  BLUE = 'bg-blue-700 border border-blue-600 text-blue-500',
  ORANGE = 'bg-orange-700 border border-orange-600 text-orange-500',
  OUTLINE_GRAY = 'bg-transparent border border-gray-600 text-gray-600',
  OUTLINE_GREEN = 'bg-transparent border border-green-600 text-green-400',
  OUTLINE_BLUE = 'bg-transparent border border-blue-600 text-blue-500',
  OUTLINE_ORANGE = 'bg-transparent border border-orange-600 text-orange-500',
  INDIGO = 'bg-indigo-100 border border-indigo-600 text-indigo-500',
}

/**
 * Renders the Badge component.
 *
 * @param {React.ReactNode} children - the bagde children.
 * @param {string} [style] - the badge style
 * @param {string} [className] - the CSS styles
 *
 * @example
 * <Badge>Something ...</Badge>
 */

export const Badge: React.FunctionComponent<IBadgeProps> = ({
  className = 'max-w-max ml-0 sm:ml-3 px-4 py-2 sm:py-2 rounded-md text-sm whitespace-nowrap',
  style,
  children,
}: IBadgeProps) => {
  const badgeStyle = classNames(className, style, 'font-semibold', {
    [BadgeStyle.GREEN]: !style,
  })
  return <div className={badgeStyle}>{children}</div>
}
