/**
 * Predefined Survey Question interface
 */
export interface IPredefinedSurveyQuestion {
  id?: string
  name?: string
  cost?: number
  field_type?: string
}

/**
 * The Survey Question predefined by the admin.
 */
export class PredefinedSurveyQuestion {
  id?: string
  name?: string
  cost?: number
  field_type?: string

  constructor(props: IPredefinedSurveyQuestion) {
    this.id = props.id
    this.name = props.name
    this.cost = props.cost
    this.field_type = props.field_type
  }
}
