import { IConsent } from '@altalogy/a6y-react-auth/lib/components/Consents/Consents'
import { Auth } from 'aws-amplify'
import { ICurrentUserContext } from '../App'
import { TSegmentTypes } from '../models'
import { APIError, IUser, User } from '../models'
import AnalyticsSegmentService from './AnalyticsSegmentService'
import APIService, { IAPIResponse, IResourceAPIResponse } from './APIService'

class UserService {
  static async getAuthCurrentUser(): Promise<unknown> {
    try {
      return await Auth.currentUserInfo()
    } catch (e) {
      return null
    }
  }

  static signOut(): void {
    // Reset HelpScout Beacon
    if (window && window.Beacon) {
      window.Beacon('logout')
    }
    AnalyticsSegmentService.reset()
    localStorage.removeItem('suggested_surveys')
    localStorage.removeItem('custom_questions_default_load')
    localStorage.removeItem('suggested_surveys_at')
    localStorage.removeItem('profile_questions')
    localStorage.removeItem('a6y_provider')
    localStorage.removeItem('a6y_token')
    localStorage.removeItem('a6y_token_exp')
    localStorage.removeItem('onboarding_tos')
    localStorage.removeItem('onboarding_tos_segment')
    localStorage.removeItem('sharLinkPr')
    localStorage.removeItem('skip_onboarding')
    localStorage.removeItem('newUserSignUpCode')
    localStorage.removeItem('after_login')
    Auth.signOut()
  }

  static async refetchUserWithContext(
    currentUserContext: ICurrentUserContext,
  ): Promise<boolean> {
    const response = await UserService.getUserData()
    if (response instanceof APIError) {
      console.error('Could not refetch the User data')
      return false
    }
    if (response && currentUserContext) {
      currentUserContext.setCurrentUser(response)
    }
    return true
  }

  static async getUserData(): Promise<User | APIError<unknown> | null> {
    const response = await APIService.get('/users/me')
    if (response instanceof APIError) {
      return response
    }
    const user = new User(UserService.parseAPIResponse(response.data))

    // NOW DONE FROM THE SERVER - Send Segment's identity action -- AnalyticsSegmentService.identity(user)
    AnalyticsSegmentService.identify(user)

    return user
  }

  static async saveUserData(
    data: IUser,
  ): Promise<User | APIError<unknown> | null> {
    const body = {
      user: { ...data },
    }
    const response = await APIService.put('/users', '', body)
    if (response instanceof APIError) {
      return response
    }
    return new User(UserService.parseAPIResponse(response.data))
  }

  static async acceptTerms(): Promise<User | APIError<unknown> | null> {
    const response = await APIService.post('/users/accept_terms', '', {})
    if (response instanceof APIError) {
      return response
    }
    return new User(UserService.parseAPIResponse(response.data))
  }

  static parseAPIResponse(response: IResourceAPIResponse): IUser {
    return response.attributes
  }

  static async setUserToActive(): Promise<User | APIError<unknown> | null> {
    const body = {
      user: { active: true },
    }
    const response = await APIService.put('/users', '', body)
    if (response instanceof APIError) {
      return response
    }
    return new User(UserService.parseAPIResponse(response.data))
  }

  static async deleteAccount(): Promise<User | APIError<unknown> | null> {
    return await APIService.delete('/users')
  }

  static async saveOnboardingVisited(): Promise<
    User | APIError<unknown> | null
  > {
    const body = {
      user: { onboarding_visited: true },
    }
    const response = await APIService.put('/users', '', body)
    if (response instanceof APIError) {
      return response
    }
    localStorage.removeItem('onboarding_tos')
    return new User(UserService.parseAPIResponse(response.data))
  }

  static async saveSegment(
    segment: TSegmentTypes,
  ): Promise<User | APIError<unknown> | null> {
    const body = {
      user: { segment_type: segment },
    }
    const response = await APIService.put('/users', '', body)
    if (response instanceof APIError) {
      return response
    }
    localStorage.removeItem('onboarding_tos_segment')
    return new User(UserService.parseAPIResponse(response.data))
  }

  /**
   * Mark user's last access to the web app in the DB.
   */
  static async updateLastAccess(): Promise<
    IAPIResponse<null> | APIError<unknown> | null
  > {
    return await APIService.post(`/users/last_access`, '', {})
  }

  /**
   * Check if user is allow to fill in additional questions.
   */
  static async checkAdditionalQuestionsAvailability(): Promise<{
    status: string
    data: unknown
    message: string
    code: string
  }> {
    return await APIService.get(
      `/users/check_additional_questions_availability`,
    )
  }

  /**
   * Check if user was migrated:
   */
  static async checkIfMigratedUser(
    email: string,
    silent: boolean,
  ): Promise<IResourceAPIResponse | APIError<unknown> | null> {
    return await APIService.post('/users/check_migration', '', {
      email: email,
      silent: silent,
    })
  }

  /**
   * Confirm the migration.
   */
  static async confirmMigration(data: {
    code: string
    email: string
    password: string
    consents: IConsent[]
  }): Promise<IResourceAPIResponse | APIError<unknown> | null> {
    return await APIService.post('/users/confirm_migration', '', data)
  }
}

export default UserService
