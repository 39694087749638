export const paidGoogleAdVariants = ['paid-1']
export const freeGoogleAdVariants = ['students-1']

export const googleAdVariants = [
  ...paidGoogleAdVariants,
  ...freeGoogleAdVariants,
]

export const isAGoogleAdVariant = (pathname: string): boolean => {
  const finalUrl = pathname.split('/').pop()

  return googleAdVariants.includes(finalUrl)
}
